import React from "react";
import { Link } from "react-router-dom";
import AddPriceFilter from "./AddPriceFilter";


class PackageAddPage extends React.Component {
  state = {};

  componentDidMount() {
    if (this.props.match.params.filter_id !== undefined 
       && this.props.match.params.filter_id !== null
       && this.props.match.params.filter_id !== 0 
       && this.props.match.params.filter_id !== '') {
      this.setState({ filter_id: this.props.match.params.filter_id })
      console.log(this.props.match.params.filter_id)
    }
  }

  render() {
    return (
      <div className="main-body">
        <div className="page-wrapper">
        <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>{this.props.match.params.filter_id ? "Edit " : "Add "}Price Filter</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="page-header-breadcrumb">
                    <ul className="breadcrumb-title">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="feather icon-home"></i>{" "}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/addPrice-filter">{this.props.match.params.filter_id ? "Edit " : "Add "}{" "} Price Filter</Link>
                      </li>
                      <li className="breadcrumb-item active">Package Master</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          <div className="page-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-border-default">
                  <div className="card-block">
                   
                    <div className="tab-content tabs">
                      <div className="tab-pane  active">
                        {
                           this.props.match.params.filter_id
                            ?
                            <AddPriceFilter
                              goBack={this.props.history.goBack}
                              filter_id={ this.props.match.params.filter_id} />
                            :
                            <AddPriceFilter
                              goBack={this.props.history.goBack} />
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    );

  }
}

export default PackageAddPage;
