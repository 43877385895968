import React, { Component } from 'react'
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {ContactUsAdd, ContactUsGet } from "../../actions/homeAction";

export class ContactUsAddEdit extends Component {
    
    state = {
        message:'',
        email:'',
        phone1:'',
        phone2:'',
        insta:'',
        facebook:'',
        youtube:'',
        snapchat:'',
        tiktok:'',
        whatsapp:"",
        linkedin:''
    }

    componentDidMount(){
        this.props.ContactUsGet()
    }

    componentWillReceiveProps(nextProps){
        this.setState({ 
            contactUs: nextProps.home.contactUs,
            message:nextProps.home.contactUs[0].message,
            email: nextProps.home.contactUs[0].email,
            phone1:nextProps.home.contactUs[0].phone1,
            phone2:nextProps.home.contactUs[0].phone2,
            insta:nextProps.home.contactUs[0].insta,
            facebook:nextProps.home.contactUs[0].facebook,
            youtube:nextProps.home.contactUs[0].youtube,
            snapchat:nextProps.home.contactUs[0]?.snapchat,
            tiktok:nextProps.home?.contactUs[0]?.tiktok,
            whatsapp:nextProps.home?.contactUs[0]?.whatsapp,
            linkedin:nextProps.home?.contactUs[0]?.linkedin
        });
        console.log( nextProps.home.contactUs[0].email)

    }

    handleChange = (e) => {
        this.setState({ [e.target.name]:e.target.value });
      };


    onSubmit=()=>{
        var data = {
            message:this.state.message,
            email:this.state.email,
            phone1:this.state.phone1,
            phone2:this.state.phone2,
            insta:this.state.insta,
            facebook:this.state.facebook,
            youtube:this.state.youtube,
            snapchat:this.state.snapchat,
            tiktok:this.state.tiktok,
            whatsapp:this.state.whatsapp,
            linkedin:this.state.linkedin
        }
        this.props.ContactUsAdd(data)
    }
    
  render() {
      console.log('state',this.state);
    return (
        <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Contact Us</h4>
                    </div>
                  </div>
                  
                  <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link>

                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='email' className="col-sm-2 col-form-label FONT_WEIGHT2">Email</label>
                                <div className="col-sm-10">
                                    <input 
                                    type="text"
                                    name='email'
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.email}
                                    placeholder='Company Email'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='phone1' className="col-sm-2 col-form-label FONT_WEIGHT2">Phone 1</label>
                                <div className="col-sm-10">
                                <input 
                                    className="form-control"
                                    type="text"
                                    name='phone1'
                                    onChange={this.handleChange}
                                    value={this.state.phone1}
                                    placeholder='Company Phone 1'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='message' className="col-sm-2 col-form-label FONT_WEIGHT2">Whatsapp</label>
                                <div className="col-sm-10">
                                <input 
                                    rows={4}
                                    name='whatsapp'
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.whatsapp}
                                    placeholder='whatsapp '
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='insta' className="col-sm-2 col-form-label FONT_WEIGHT2">Instagram</label>
                                <div className="col-sm-10">
                                <input 
                                    type="text"
                                    name='insta'
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.insta}
                                    placeholder='Company Instagram Handle'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='facebook' className="col-sm-2 col-form-label FONT_WEIGHT2">Facebook</label>
                                <div className="col-sm-10">
                                <input 
                                    type="text"
                                    name='facebook'
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.facebook}
                                    placeholder='Company Facebook Handle'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='youtube' className="col-sm-2 col-form-label FONT_WEIGHT2">Youtube</label>
                                <div className="col-sm-10">
                                <input 
                                    type="text"
                                    name='youtube'
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.youtube}
                                    placeholder='Company Youtube Handle'
                                    />
                                </div>
                            </div>
                        </div>
                        { this.state.snapchat !== null && this.state.snapchat !==""
                        ?<div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='message' className="col-sm-2 col-form-label FONT_WEIGHT2">Snapchat</label>
                                <div className="col-sm-10">
                                <input 
                                    rows={4}
                                    name='snapchat'
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.snapchat}
                                    placeholder='snapchat Id'
                                    />
                                </div>
                            </div>
                        </div>
                        :null}
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='message' className="col-sm-2 col-form-label FONT_WEIGHT2">tiktok</label>
                                <div className="col-sm-10">
                                <input 
                                    rows={4}
                                    name='tiktok'
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.tiktok}
                                    placeholder='tiktok'
                                    />
                                </div>
                            </div>
                        </div>
                        { this.state.linkedin!==null && this.state.linkedin !== "" 
                        ?
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='message' className="col-sm-2 col-form-label FONT_WEIGHT2">Linkedin</label>
                                <div className="col-sm-10">
                                <input 
                                    rows={4}
                                    name='linkedin'
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.linkedin}
                                    placeholder='linkedin Id'
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        null}
                        
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='message' className="col-sm-2 col-form-label FONT_WEIGHT2">Contact Us Text</label>
                                <div className="col-sm-10">
                                <textarea 
                                    rows={4}
                                    name='message'
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.message}
                                    placeholder='Contact Us Text '
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 DATA_SAVE">
                            <div>
                                <button className="btn btn-grd-disabled mr-2"
                                    onClick={this.onSubmit}
                                >
                                    <i className="icofont icofont-save"></i> Save</button>
                                
                                <Link to={"/"} className="btn btn-outline-dark">
                                    Cancel
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


ContactUsAddEdit.propsTypes = {
    ContactUsAdd:PropTypes.func.isRequired,
    ContactUsGet:PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
  };
  
  var mapStateToProps = (state) => ({
      home: state.home,
  });
  
export default connect(mapStateToProps, {ContactUsAdd,ContactUsGet  })(ContactUsAddEdit);