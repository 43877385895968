import React from "react";
import "react-toggle/style.css"; // for ES6 modules
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getUsers ,deleteUser } from "../../../actions/homeAction";
// import {USER_DUMMY_DATA} from './DummyData'

class UserList extends React.Component {
  state = {
    language_data: [],
  };
  componentWillMount() {
    this.props.getUsers();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ users: nextProps.home.users });
    console.log('USERS LIST',nextProps.home.users)
  }

  removeUser = (id) => {
    var data={
      userId:id
    }
    this.props.deleteUser(data)
  };

  render() {
    
    const columns = [
      // {
      //   name: "Medium",
      //   label: "User image",
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (PostMedia  , tableMeta) => {
      //       return (
      //         <div>
      //           {console.log(PostMedia)}
      //           { 
      //             <img
      //               // src={  
      //               //   PostMedia !== undefined && PostMedia !== null && PostMedia !== ""
      //               //     ?PostMedia.map( (PostMedia)=>
      //               //        `https://tenhil.auction/${PostMedia.url} `)
      //               //     : "./assets/images/tenhil.png" 
      //               // }
      //               src="/assets/img/cyber.jpg"
      //               alt=""
      //               className="img-40"
      //               onError={this.imgLoadError}
      //             />
      //           }
      //         </div>
      //       );
            
      //     },
      //   },
      // },
      {
        name: "firstName",
        label: "First Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "mobileNumber",
        label: "Mobile Number",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "email",
        label: "E-mail",
        options: {
          filter: true,
          sort: true,
        },
      },
     
      {
        name: "gender",
        label: "Gender",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "registrationType",
        label: "User Type",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "bids",
        label: "Bids",
        options: {
          filter: true,
          sort: true,
        },
      },
      // {
      //   name: "status",
      //   label: "Status",
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (status, tableMeta) => {
      //       return (
      //         <div>
      //           {status == 'Active'?(<label className="badge bg-success text-uppercase">
      //             {status}
      //           </label>):(<label className="badge bg-danger text-uppercase">
      //             {status}
      //           </label>)}
      //         </div>
      //       );
      //     },
      //   },
      // },
      {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
                <Link
                  to={"/user/view/" + id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  // data-original-title="Edit"
                >
                  <i className="f-20 icofont icofont-eye text-custom"></i>
                </Link>
                <span
                  onClick={this.removeUser.bind(this, id)}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                >
                  <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                </span>
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Customers Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>User List</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"User List"}
                        className="table-responsive"
                        data={this.state.users}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserList.propsTypes = {
  getUsers: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getUsers ,deleteUser })(UserList);
