import React from "react";
import { Link } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPost,deletePost,updatePostStatus } from "../../actions/homeAction";
import Moment from "react-moment";
import Toggle from "react-toggle";
import "react-toggle/style.css"; 
import {POSTS_DUMMY_DATA} from '../UserMaster/Customer/DummyData'

class VariantsList extends React.Component {
  state = {
    postList:[],
    variantsArray:[],
    media:[],
    title:''
  };

  componentWillMount() {
    var data={
      bids:'', 
      price:'', 
      type:'Fixed', 
      status:'', 
      startRange:'', 
      recordLimit:'',
    }
    this.props.getPost(data);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ postList: nextProps.home.postList });
    this.getSinglePost(nextProps.home.postList)
  }

  getSinglePost =(postList)=>{
    if(this.props.match.params.postId&&postList){
      var pID = this.props.match.params.postId
      var FilteredPost = postList?.filter(post=>post?.id == pID)
      var POST = FilteredPost[0]

      console.log('FilteredPost',POST);
      this.setState({variantsArray:POST?.Variants,media:POST?.Media,title:POST?.title})
     }
  }

  deletePost = (id) => {
    var data={
       PostId:id
    }
    var status="Live"
    this.props.deletePost(data,status)
  };
  

  imgLoadError = (event) => {
    event.target.src = "./assets/images/icon.png";
  };
  handleStatusChange= (status,id) => {
    console.log(status,id)
     var change
     if(status)
     {change=false}
     else
     {change=true}
    var data={
      PostId:id,
      status:change
     }
     //console.log(data)
     this.props.updatePostStatus(data)
     
  }
  render() {

    console.log('variantsArray',this.state.variantsArray);

    const columns = [
        {
            name: "Media",
            label: "Post Image",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (Media  , tableMeta) => {
                return (
                  <div>
                    {this.state.media.length>0?this.state.media.map((media,key)=>(
                      key <2?
                      <img className="img-40" src={`http://tenfils.infoware.xyz/api/${media.url}`} alt="" />
                      :null
                    ))
                    :
                    
                      <img
                        src={`/assets/img/xiomi.jpg`}
                        alt=""
                        className="img-40"
                       
                      />
                    }
                  </div>
                );
                
              },
            },
          },
      {
        name: "title",
        label: "Post Title",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (title)=> {
            return(<>
              <b>{this.state.title}</b>
            </>)
          }
        },
      },
      {
        name: "VariantAttributes",
        label: "Attributes",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (VariantAttributes)=> {
            return(<div>
             {VariantAttributes.length>0?VariantAttributes.map((attri,idx)=>(
                <p>{idx+1}. <b>{attri?.Attribute?.name} : </b>{attri?.AttributeOption?.value}</p>
             )):'No Attributes Yet !'}
            </div>)
          }
        },
      },
      {
        name: "createdAt",
        label: "Created Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (createdAt)=>{
            return(<>
              <span>
                  {new Date(createdAt).getDate()}/
                  {new Date(createdAt).getMonth()+1}/
                  {new Date(createdAt).getFullYear()}
              </span>
            </>)
          }
        },
      },
      
      {
        name: "price",
        label: "Price",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (price)=> {
            return(<>
              <b>{price} KWD</b>
            </>)
          }
        },
      },
    
      {
        name: "quantity",
        label: "Quantity",
        options: {
          filter: true,
          sort: true,
          
        },
      },
     
      {
        name: "active",
        label: "Activity Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (active)=> {
            return(<>
              
              {active?<b style={{color:'green'}}>Active</b>:<b style={{color:'red'}}>Inactive</b>}
            </>)
          }
        },
      },
    ];


    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Upcoming Posts Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };


    
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>All Variants</h4>
                    </div>
                  </div>
                  
                  {/* <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link> */}
                  <Link
                    to={"/variantsAdd/"+this.props.match.params.postId}
                    className="col-2 btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Variants{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card ">
                   
                    <div className="card-block">
                   
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          className="table-responsive"
                          data={this.state.variantsArray}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VariantsList.propsTypes = {
  getPost: PropTypes.func.isRequired,
  deletePost:PropTypes.func.isRequired,
  updatePostStatus:PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getPost,deletePost,updatePostStatus })(VariantsList);
