import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import { Link } from "react-router-dom";
import { celebrityShedule } from "../../actions/homeAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Calendar extends Component {
state={
  schedule: []
}
  componentDidMount() {
    this.props.celebrityShedule();
  };

  componentWillReceiveProps(nextProps) {
    if(nextProps.home.schedule.length>0)
    {
      this.setState({
        schedule: nextProps.home.schedule.map((item=>({
          id:item.id,
          title: item.slotTime,
          start: item.celebrityBookingDate
        })))
      })
    }
    console.log(nextProps.home.schedule)
  };
  render() {
    return (
      <div> 
        <div className="row m-0">
          <div class="col p-1">
            <div class="card shadow-sm box-border-top p-3">
              <FullCalendar
                defaultView="dayGridMonth"
                plugins={[dayGridPlugin]}
                events={this.state.schedule}
                eventClick={this.viewEvent}
              />
            </div>
          </div>
          <div className="col-4 p-1">
            <div class="card shadow-sm box-border-top">
              <div class="box-title d-flex justify-content-between border-bottom">
                <div className="">
                  <h5 className="m-0">
                    <i class="fa fa-signin"></i> To Do List
                    </h5>
                </div>
                <button className="btn-sm px-1 py-0 btn-dark">
                  <i className="fa fa-plus"></i>
                </button>
              </div>
              <div className="box-body"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Calendar.propsTypes = {
  celebrityShedule: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { celebrityShedule })(Calendar);
