import axios from "axios";
import Constant from "../Constant";
import {
  
  GET_ATTRIBUTES,
  GET_ATTRIBUTES_ONE,
} from "./types";
import Swal from "sweetalert2";

var config = {
  headers: {
    Authorization: `${localStorage.getItem("caviar_ad_auth")}`,
  },
};

var paramConfig = {
  Authorization: `${localStorage.getItem("caviar_ad_auth")}`,
};


export const getAttributes = (data) => (dispatch) => {
  
  
    axios
      .post(Constant.getAPI() + "config/getAttributes" ,{languageCode:"en"},config)
      .then((res) => {
          if(res.status == 200){
              if(res.data.status == true){
        dispatch({
          type: GET_ATTRIBUTES,
          payload: res.data.data,
        })
      }
    }
 })
      .catch((err) => console.log(err));
};

export const getSingleAttribute =(flag,data)=>(dispatch)=>{
    if(flag){
        dispatch({
            type:GET_ATTRIBUTES_ONE,
            payload:data
          })
    }else{
    axios
    .post(Constant.getAPI() + "config/getAttributes" ,data,config)
    .then((res) => {
        if(res.status == 200){
            if(res.data.status == true){
                dispatch({
                    type:GET_ATTRIBUTES_ONE,
                    payload:res.data?.data[0]?.AttributeOptions
                  })
    }
  }
})
    .catch((err) => console.log(err));
}
}