import React from "react";
import { Link } from "react-router-dom";

class SideNavBar extends React.Component {
  render() {
    return (
      <nav className="pcoded-navbar noprint" id="admin_menu" >
        <div className="pcoded-inner-navbar main-menu">
          <ul className="pcoded-item pcoded-left-item" >
            <li className=""  >
              <Link to="/">
                <span className="pcoded-micon">
                  <i className="feather icon-home dark-icons" style={{color:"#264040"}} ></i>
                </span>
                <span className="pcoded-mtext"  >Dashboard</span>
              </Link>
            </li>
            <li className="">
              <Link to="/users">
                <span className="pcoded-micon">
                  <i className="icofont icofont-users-alt-3" style={{color:"#264040"}}></i>
                </span>
                <span className="pcoded-mtext">Users</span>
              </Link>
            </li>
            <li className="">
              <Link to="/push-notification">
                <span className="pcoded-micon">
                  <i className="icofont icofont-bell-alt" style={{color:"#264040"}}></i>
                </span>
                <span className="pcoded-mtext">Push Notification</span>
              </Link>
            </li>
            {/* <li className="">
              <Link to="/order-list">
                <span className="pcoded-micon">
                  <i className="feather icon-list"></i>
                </span>
                <span className="pcoded-mtext">Order List</span>
              </Link>
            </li> */}
                <li className="">
                  <Link to="/orders">
                    <span className="pcoded-micon">
                    <i className="feather icon-box" style={{color:"#264040"}}></i>
                    </span>
                    <span className="pcoded-mtext">Orders</span>
                  </Link>
                </li>

                <li className="">
                  <Link to="/bookings">
                    <span className="pcoded-micon">
                    <i className="feather icon-box" style={{color:"#264040"}}></i>
                    </span>
                    <span className="pcoded-mtext">Bookings</span>
                  </Link>
                </li>

                <li className="">
                  <Link to="/delivery">
                    <span className="pcoded-micon">
                    <i className="feather icon-box" style={{color:"#264040"}}></i>
                    </span>
                    <span className="pcoded-mtext">Delivery</span>
                  </Link>
                </li>

                <li className="">
                  <Link to="/coupons">
                    <span className="pcoded-micon">
                    <i className="feather icon-tag" style={{color:"#264040"}}></i>
                    </span>
                    <span className="pcoded-mtext">COUPONS</span>
                  </Link>
                </li>
              

            {/* <li className=" ">
              <Link to="/orders">
                <span className="pcoded-micon">
                  <i className="icofont icofont-cart-alt"></i>
                </span>
                <span className="pcoded-mtext">Orders</span>
              </Link>
            </li> */}

            <li className=" pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon">
                  <i className="feather icon-image" style={{color:"#264040"}}></i>
                </span>
                <span className="pcoded-mtext">Products</span>
              </a>
              <ul className="pcoded-submenu">
                <li className=" ">
                  <Link to="/posts">
                    <span className="pcoded-mtext">All Products</span>
                  </Link>
                </li>
                <li className=" ">
                  <Link to="/top-posts">
                    <span className="pcoded-mtext">Homepage Products</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li className=" pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon">
                  <i className="feather icon-grid" style={{color:"#264040"}}></i>
                </span>
                <span className="pcoded-mtext">Auction</span>
              </a>
              <ul className="pcoded-submenu">
                <li className=" ">
                  <Link to="/bidding-posts">
                    <span className="pcoded-mtext">Bidding Posts</span>
                  </Link>
                </li>
                <li className=" ">
                  <Link to="/ongoing-posts">
                    <span className="pcoded-mtext">Live</span>
                  </Link>
                </li>
                <li className=" ">
                  <Link to="/upcoming-posts">
                    <span className="pcoded-mtext">Upcoming Posts</span>
                  </Link>
                </li>
                <li className=" ">
                  <Link to="/completed-auctions">
                    <span className="pcoded-mtext">Completed Auctions</span>
                  </Link>
                </li>
              </ul>
            </li> */}
                <li className="">
                  <Link to="/ongoing-posts">
                    <span className="pcoded-micon">
                    <i className="feather icon-grid" style={{color:"#264040"}}></i>
                    </span>
                    <span className="pcoded-mtext">Auction</span>
                  </Link>
                </li>
          
          </ul>
          <div className="pcoded-navigatio-lavel" ><b style={{color:"#437070"}} >Master</b></div>
          <ul className="pcoded-item pcoded-left-item">
            {/* <li className=" ">
              <Link to="/category">
                <span className="pcoded-micon">
                  <i className="feather icon-jfi-view-grid"></i>
                </span>
                <span className="pcoded-mtext">Category Master</span>
              </Link>
            </li> */}

            {/* <li className=" ">
              <Link to="/package">
                <span className="pcoded-micon">
                  <i className="feather icon-jfi-view-grid"></i>
                </span>
                <span className="pcoded-mtext">Package Master</span>
              </Link>
            </li> */}

            {/* <li className=" ">
              <Link to="/banner">
                <span className="pcoded-micon">
                  <i className="feather icon-image"></i>
                </span>
                <span className="pcoded-mtext">Banner Master </span>
              </Link>
            </li> */}

            {/* <li className=" ">
              <Link to="/timeslot">
                <span className="pcoded-micon">
                  <i className="feather icon-jfi-view-grid"></i>
                </span>
                <span className="pcoded-mtext">Time slot Master </span>
              </Link>
            </li> */}
            {/* <li className=" ">
              <Link to="/payment_type">
                <span className="pcoded-micon">
                  <i className="feather icon-jfi-view-grid"></i>
                </span>
                <span className="pcoded-mtext">Payment Type Master </span>
              </Link>
            </li> */}

            {/* <li className=" ">
              <Link to="/dateblock">
                <span className="pcoded-micon">
                  <i className="feather icon-jfi-view-grid"></i>
                </span>
                <span className="pcoded-mtext">Date Block Master </span>
              </Link>
            </li> */}
            <li className=" pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon">
                  <i className="feather icon-jfi-view-grid" style={{color:"#264040"}}></i>
                </span>
                <span className="pcoded-mtext">Package</span>
              </a>
              <ul className="pcoded-submenu">
                <li className=" ">
                  <Link to="/price-filter">
                    <span className="pcoded-mtext">Price FIlter</span>
                  </Link>
                </li>
              </ul>
            </li>
             <li className=" pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon">
                  <i className="feather icon-jfi-view-grid" style={{color:"#264040"}}></i>
                </span>
                <span className="pcoded-mtext">Attribute</span>
              </a>
              <ul className="pcoded-submenu">
                <li className=" ">
                  <Link to="/attribute-add">
                    <span className="pcoded-mtext">Add Attribute</span>
                  </Link>
                </li>
                <li className=" ">
                  <Link to="/attribute-list">
                    <span className="pcoded-mtext">Attribute List</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className=" pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon">
                  <i className="feather icon-jfi-view-grid" style={{color:"#264040"}}></i>
                </span>
                <span className="pcoded-mtext">Location</span>
              </a>
              <ul className="pcoded-submenu">
                <li className=" ">
                  <Link to="/country">
                    <span className="pcoded-mtext">Country</span>
                  </Link>
                </li>
                <li className=" ">
                  <Link to="/governorate">
                    <span className="pcoded-mtext">Governorate</span>
                  </Link>
                </li>
                <li className=" ">
                  <Link to="/area">
                    <span className="pcoded-mtext">Area</span>
                  </Link>
                </li>
              </ul>
            </li>

              <li className="">
                  <Link to="/gifts">
                    <span className="pcoded-micon">
                    <i className="feather icon-jfi-view-grid" style={{color:"#264040"}}></i>
                    </span>
                    <span className="pcoded-mtext">Gifts</span>
                  </Link>
                </li>

                <li className="">
                  <Link to="/banner">
                    <span className="pcoded-micon">
                    <i className="feather icon-image" style={{color:"#264040"}}></i>
                    </span>
                    <span className="pcoded-mtext">Banners</span>
                  </Link>
                </li>
          
          </ul>
          {/* </li>
          </ul> */}
          <div className="pcoded-navigatio-lavel"><b style={{color:"#437070"}} >Settings</b></div>
          <ul className="pcoded-item pcoded-left-item">
            <li className="">
              <Link to="/about">
                <span className="pcoded-micon">
                  <i className="icofont icofont-law-document" style={{color:"#264040"}}></i>
                </span>
                <span className="pcoded-mtext">About Us</span>
              </Link>
            </li>
            <li className="">
              <Link to="/terms">
                <span className="pcoded-micon">
                  <i className="icofont icofont-law-document" style={{color:"#264040"}}></i>
                </span>
                <span className="pcoded-mtext">Terms & Conditions</span>
              </Link>
            </li>
            <li className="">
              <Link to="/contact-us">
                <span className="pcoded-micon">
                  <i className="feather icon-phone" style={{color:"#264040"}}></i>
                </span>
                <span className="pcoded-mtext">Contact Us</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default SideNavBar;
