import React, { Component } from 'react'
import { Link } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {MonthlyGiftList,deleteGifts } from "../../actions/homeAction";
import Moment from "react-moment";
import Toggle from "react-toggle";
import "react-toggle/style.css"; 
import Constant from "../../Constant"
// import {GIFT_DUMMY_DATA} from '../UserMaster/Customer/DummyData'

export class MonthlyGiftsList extends Component {
    state = {
      monthlyGift:[],
      changeProduct:'weekly'
    };

    componentWillMount() {
      var data ={
        isAdmin:true
      }
      this.props.MonthlyGiftList(data);
    }
  
    componentWillReceiveProps(nextProps) {
      this.setState({ monthlyGift: nextProps.home.monthlyGift });
      console.log( nextProps.home.monthlyGift)
    }
  
    deletePost = (id) => {
      var data={
        giftId: id
      }
      var status="Live"       
      this.props.deleteGifts(data,status)
    };
    
    onToggleChange = (e) =>{
      console.log("DATA" , e.target.value);
      this.setState({
        changeProduct:e.target.value
      })
    }
  
    handleStatusChange= (status,id) => {
      console.log(status,id)
       var change
       if(status)
       {change=false}
       else
       {change=true}
      var data={
        PostId:id,
        status:change
       }
       //console.log(data)
       this.props.updatePostStatus(data)
       
    }
    render() {
      console.log("GIFT" , this.state.changeProduct);
        const columns = [
          {
            name: "Post",
            label: "Post Image",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (Post  , tableMeta) => {
                var Media = Post.Media
                return (
                  <div>
                    {Media.length>0?Media.map((media,key)=>(
                      key <2?
                      <img className="img-40" src={`${Constant.getAPI()}${media.url}`} alt="" />
                      :null
                    ))
                    :
                    
                      <img
                        src={`/assets/img/xiomi.jpg`}
                        alt=""
                        className="img-40"
                        onError={this.imgLoadError}
                      />
                    }
                  </div>
                );
                
              },
            },
          },
          {
            name: "Post",
            label: "Gift Item Name",
            options: {
              filter: true,
              sort: true,
              customBodyRender:(Post)=>{
                return <span>{Post.title.length>20?Post.title.slice(0,20)+" ...":Post.title}</span>
              }
            },
          },
          {
            name: "Post",
            label: "Gift Price",
            options: {
              filter: true,
              sort: true,
              customBodyRender:(Post)=>{
                return <span>{Post.price} KWD</span>
              }
            },
          },
          {
            name: "name",
            label: "Gift Title",
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            name: "duration",
            label: "Gift Duration",
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            name: "startDate",
            label: "Start Date",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (startDate)=>{
                return(<>
                  <span>
                      {new Date(startDate).getDate()}/
                      {new Date(startDate).getMonth()+1}/
                      {new Date(startDate).getFullYear()}
                  </span>
                </>)
              }
            },
          },
          // {
          //   name: "status",
          //   label: "Approval",
          //   options: {
          //     filter: true,
          //     sort: true,
          //     customBodyRender: (status, tableMeta) => {
          //       return (
          //         <Toggle
          //         id={"status" + tableMeta.rowData[2]}
          //         checked={status === true ? true : false}
          //         value={status}
          //         onChange={this.handleStatusChange.bind(this,status,tableMeta.rowData[6])}
          //       />
          //       )
          //     }
          //   },
          // },
          {
            name: "id",
            label: "Leaderboard",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (id, tableMeta) => {
                return (
                  <div>
                   <Link
                      to={"/gifts/add/" + id}
                      className="m-r-15 text-muted"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Edit"
                    >
                    <i className="f-20 icofont icofont-ui-edit text-custom"></i>
                   </Link>
                   <Link
                      to={"/gift-winners/"+ id}
                      className="m-r-15 text-muted"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Edit"
                    > 
                      <i class="f-20 icofont icofont-eye text-custom"></i>
                    </Link>
                    <span
                      onClick={this.deletePost.bind(this, id)}
                      className="m-r-15 text-muted"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Delete"
                    >
                      <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                    </span>
                  </div>
                );
              },
            },
          },
        ]; 
        const options = {
          filterType: "dropdown",
          viewColumns: false,
          print: false,
          download: false,
          selectableRows: "none",
          textLabels: {
            body: {
              noMatch: this.state.isSaving
                ? "Loading data..!"
                : "Sorry, No Upcoming Posts Found",
              toolTip: "Sort",
              columnHeaderTooltip: (column) => `Sort for ${column.label}`,
            },
          },
        };
        
        return (
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="row align-items-end">
                    <div className="col">
                      <div className="page-header-title">
                        <div className="d-inline">
                          <h4>Gifts</h4>
                        </div>
                      </div>
                      
                      <Link
                        to="/"
                        className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                        data-modal="modal-13"
                      >
                        {" "}
                        <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                      </Link>
                      <Link
                        to="/gifts/add"
                        className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                        data-modal="modal-13"
                      >
                        {" "}
                        <i className="icofont icofont-plus m-r-5"></i> Add Gift{" "}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                          <select className="col-sm-2" onChange={(e)=>this.onToggleChange(e)} >
                             <option value="weekly">Weekly Gifts</option>
                             <option value="monthly">Monthly Gifts</option>
                          </select>
                      <div className="card mt-2">
                        <div className="card-block">
                          {this?.state?.changeProduct==="weekly"?
                          <div className="dt-responsive table-responsive">
                            <MUIDataTable
                              title={"Weekly Gifts"}
                              className="table-responsive"
                              data={this?.state?.monthlyGift?.filter((item)=>item?.duration==="weekly")}
                              columns={columns}
                              options={options}
                            />
                          </div>
                          :(this?.state?.changeProduct==="monthly")?
                          <div className="dt-responsive table-responsive">
                            <MUIDataTable
                              title={"Monthly Gifts"}
                              className="table-responsive"
                              data={this?.state?.monthlyGift?.filter((item)=>item?.duration==="monthly")}
                              columns={columns}
                              options={options}
                            />
                          </div>
                          :[]
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
}

MonthlyGiftsList.propsTypes = {
    MonthlyGiftList:PropTypes.func.isRequired,
    deleteGifts:PropTypes.func.isRequired,
    // getPost:PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
  };
  
  var mapStateToProps = (state) => ({
      home: state.home,
  });
  
export default connect(mapStateToProps, {MonthlyGiftList, deleteGifts })(MonthlyGiftsList);
