import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {getOrdersList } from "../../actions/homeAction";
import MUIDataTable from "mui-datatables";
import {ORDERLIST_DUMMY_DATA} from '../UserMaster/Customer/DummyData'

class OrderList extends React.Component {
  state = {
    orders_list: [],
  };
  componentDidMount() {
    var data ={
      // startRange:1,
      // count:20,
    }
    this.props.getOrdersList(data);
  }
  componentWillReceiveProps(nextProps)
  {
    if(nextProps.home.orderList)
    {
      this.setState({
        orders_list:nextProps.home.orderList
      })
    }
  }
  
  render() {
    console.log('Orders',this.state.orders_list);
    const columns = [
      {
        name: "invoice_number",
        label: "Invoice Number",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (invoice_number)=>{
            return(
              <div>
                <label style={{fontWeight:'bold',textTransform:'uppercase',fontSize:'1rem'}}>{invoice_number}</label>
              </div>
            )
          }
        },
      },
      // {
      //   name: "user_name",
      //   label: "User Name",
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (user_name)=>{
      //       return(
      //         <div>
      //           <label>Sultan Rehmani</label>
      //         </div>
      //       )
      //     }
      //   },
      // },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (createdAt, tableMeta) => {
            var order_date = new Date(createdAt);
            var order_day, order_month, order_year;
            if (order_date.getDate() > 9) {
              order_day = order_date.getDate();
            } else {
              order_day = "0" + order_date.getDate();
            }
            if (order_date.getMonth() + 1 > 9) {
              order_month = order_date.getMonth() + 1;
            } else {
              order_month = "0" + (order_date.getMonth() + 1);
            }
            order_year = order_date.getFullYear();

            var created_at = order_day + "-" + order_month + "-" + order_year;

            return <div>{created_at}</div>;
          },
        },
      },
      {
        name: "grandTotal",
        label: "Amount",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (grandTotal, tableMeta) => {
            return (
              <span>{grandTotal} KWD</span>
            );
          },
        },
      },
      {
        name: "totalItems",
        label: "Total Items",
        options: {
          filter: true,
          sort: true,
        },
      },
      // {
      //   name: "order_type",
      //   label: "Order Type",
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (order_type, tableMeta) => {
      //       return (
      //         <div>
      //           <label style={{fontWeight:'bold'}}>{order_type}</label>
      //         </div>
      //       );
      //     },
      //   },
      // },
      {
        name: "paymentStatus",
        label: "Payment Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (paymentStatus, tableMeta) => {
            return (
              <div>
              {paymentStatus == 'Success'?(<label style={{textAlign:'center',width:'80%',padding:'0.1rem 0.6rem',background:'#00bbf0',color:'#fff',fontWeight:'500',borderRadius:'3px'}}>
                {paymentStatus}
              </label>):null}
              {paymentStatus=='Failed'?(<label style={{textAlign:'center',width:'80%',padding:'0.2rem 1rem',background:'#f95959',color:'#fff',fontWeight:'500',borderRadius:'3px'}}>
                {paymentStatus}
              </label>):null}
              {paymentStatus=='Pending'?(<label style={{textAlign:'center',width:'80%',padding:'0.2rem 1rem',background:'#facf5a',color:'#fff',fontWeight:'500',borderRadius:'3px'}}>
                {paymentStatus}
              </label>):null}
            </div>
            );
          },
        },
      },
      {
        name: "orderStatus",
        label: "Order Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (orderStatus, tableMeta) => {
            return (
              <div>
                {/* {complete === true ? ( */}
                {orderStatus == 'Placed'?(<label className="badge bg-success text-uppercase">
                  Placed
                </label>):null}
               {orderStatus == 'process'?(<label className="badge bg-warning text-uppercase">
                  In Process
                </label>):null}
                {orderStatus == 'canceled'?(<label className="badge bg-danger text-uppercase">
                  Canceled
                </label>):null}
                {orderStatus == "booked"?(<label className="badge bg-primary text-uppercase">
                  Booked
                </label>):null}
              </div>
            );
          },
        },
      },
      // {
      //   name: "id",
      //   label: "Action",
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (id, tableMeta) => {
      //       return (
      //         <div>
      //          <button>Cancel</button>
      //         </div>
      //       );
      //     },
      //   },
      // },
      {
        name: "id",
        label: "View",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div style={{textAlign:'center'}}>
                <Link
                  to={"/orders/view/" + id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  // data-original-title="View"
                 
                >
                  <i className="f-22 icofont icofont-eye text-dark"  ></i>
                </Link>
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Orders Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Order List</h4>
                    </div>
                  </div>
                  <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link>
                </div>
                <div className="col-lg-4">
                  <div className="page-header-breadcrumb">
                    <ul className="breadcrumb-title">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="feather icon-home"></i>{" "}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Order List</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          title={"Order List"}
                          className="table-responsive"
                          data={this.state.orders_list}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrderList.propsTypes = {
  getOrdersList: PropTypes.func.isRequired,
  
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, {getOrdersList  })(OrderList);
