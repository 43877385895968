import React, { Component } from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import Login from "./common/Login.js";
import Home from "./common/Home.js";
import Dashboard from "./common/Dashboard.js";
import LanguageList from "./components/LanguageMaster/LanguageList.js";
import LanguageAdd from "./components/LanguageMaster/LanguageAdd.js";

import CategoryList from "./components/CategoryMaster/CategoryList.js";
import CategoryAddTab from "./components/CategoryMaster/CategoryAddTab";
import ChildCategoryList from "./components/CategoryMaster/ChildCategoryList";
import ChildCategoryAddTab from "./components/CategoryMaster/ChildCategoryAddTab";
import CountryList from "./components/LocationMaster/CountryMaster/CountryList.js";

import GovernorateList from "./components/LocationMaster/GovernorateMaster/GovernorateList.js";
import GovernorateAddTab from "./components/LocationMaster/GovernorateMaster/GovernorateAddTab.js";

import AreaList from "./components/LocationMaster/AreaMaster/AreaList.js";
import AreaAddTab from "./components/LocationMaster/AreaMaster/AreaAddTab.js";

// import PostAdd from "./components/PostMaster/PostAdd.js";
import AddPOST from "./components/PostMaster/AddPOST.js";
import AllPostList from "./components/PostMaster/AllPostList.js";
import UpcomingPostList from "./components/PostMaster/UpcomingPostList.js";
import OnGoingPostList from "./components/PostMaster/OnGoingPostList.js";
import CompletePostList from "./components/PostMaster/CompletePostList.js";
import BidsList from "./components/PostMaster/Bidding/BidsList"

import PostAddTab from "./components/PostMaster/PostAddTab.js";

import OrderList from "./components/OrdersMaster/OrderList.js";
import BookedOrders from "./components/OrdersMaster/BookedOrders.js";
import DeleveryOrders from "./components/OrdersMaster/DeleveryOrders.js";
import ViewOrder from "./components/OrdersMaster/ViewOrder.js";

import OrderView from "./components/OrdersMaster/OrderView.js";

import BuyerPolicyTab from "./components/BuyerPolicy/BuyerPolicyTab.js";
import TermsAddTab from "./components/Terms/TermsAddTab.js";
import AboutUsAddTab from "./components/AboutUs/AboutUsAddTab.js";
import ContactUsList from "./components/ContactUs/ContactUsList.js";

import BannerList from "./components/BannerMaster/BannerList.js";
import BannerAddTab from "./components/BannerMaster/BannerAddTab.js";
import GeneralSettingAddTab from "./components/GeneralSettingMaster/GeneralSettingAddTab.js";

import UserList from "./components/UserMaster/Customer/UserList.js";
import UserEDIT from "./components/UserMaster/Customer/UserAddTab.js";

import UserArticle from "./components/UserMaster/Customer/UserArticle.js";
import Wishlist from "./components/UserMaster/Customer/Wishlist.js";
import CustomerAddressList from "./components/UserMaster/Customer/CustomerAddressList.js";

import AttributeList from "./components/AttributeMaster/AttributeList.js";
import AttributeAddTab from "./components/AttributeMaster/AttributeAddTab.js";

import AttributeValueList from "./components/AttributeValueMaster/AttributeValueList.js";
import AttributeValueAddTab from "./components/AttributeValueMaster/AttributeValueAddTab.js";


import BlogList from "./components/BlogMaster/BlogList.js";
import BlogAddTab from "./components/BlogMaster/BlogAddTab.js";
import BlogComments from "./components/BlogMaster/BlogComments.js";

import CustomizedList from "./components/CustomizedProductMaster/CustomizedList.js";
import CustomizedAddTab from "./components/CustomizedProductMaster/CustomizedAddTab.js";
import CountryAddTab from "./components/LocationMaster/CountryMaster/CountryAddTab.js";
import RefundPolicyAddTab from "./components/RefundPolicy/RefundPolicyAddTab.js";
import PrivacyPolicyAddTab from "./components/PrivacyPolicy/PrivacyPolicyAddTab.js";
import CancellationPolicyAddTab from "./components/CancellationPolicy/CancellationPolicyAddTab.js";
import CelebrityList from "./components/CelebrityMaster/CelebrityList.js";
import CelebrityAddTab from "./components/CelebrityMaster/CelebrityAddTab.js";
import Calender from "./components/CelebrityMaster/Calender";

import TimeslotList from "./components/TimeSlot/TimeslotList";
import TimeslotAddTab from "./components/TimeSlot/TimeslotAddTab";
import DateAddTab from "./components/DateBlock/DateAddTab";
import DateList from "./components/DateBlock/DateList";
import Payment_typeList from "./components/PaymentMaster/Payment_typeList"
import Payment_typeAddTab from "./components/PaymentMaster/Payment_typeAddTab";
import SubCategoryList from "./components/CategoryMaster/SubCategoryList.js";
import SubCategoryAddTab from "./components/CategoryMaster/SubcategoryAddTab";
import UpComingPostDetail from "./components/PostMaster/detailsPost/UpComingPostDetail.js";
import CompletePostDetail from "./components/PostMaster/detailsPost/CompletePostDetail";
import OnGoingPostDetail from "./components/PostMaster/detailsPost/OngoingPostDetail"

import MonthlyGiftsList from './components/MonthlyGiftsMaster/MonthlyGiftsList'
import MonthlyGiftWinners from './components/MonthlyGiftsMaster/MonthlyGiftWinners'
import MonthlyGiftAdd from './components/MonthlyGiftsMaster/MonthlyGiftAdd'
import AddAttribute from "./components/AttributeMaster/AddAttribute.js";
import TopPosts from "./components/PostMaster/TopPosts.js";
import PriceFilter from "./components/PackageMaster/PriceFilter.js";
import AddPriceFilter from "./components/PackageMaster/AddPriceFilter.js";
import PackageAddPage from "./components/PackageMaster/PackageAddPage.js";
import BiddingList from "./components/BiddingMaster/BiddingList.jsx";
import BiddingADD from "./components/BiddingMaster/BiddingADD.jsx";
import UpcomingPOSTS from "./components/BiddingMaster/UpcomingPOSTS.jsx";
import OngoingPOSTS from "./components/BiddingMaster/OngoingPOSTS.jsx";
import AboutUsAddEdit from "./components/AboutUs/AboutUsAddEdit.jsx";
import TermsAddEdit from "./components/Terms/TermsAddEdit.jsx";
import ContactUsAddEdit from "./components/ContactUs/ContactUsAddEdit.jsx";
import CouponsList from "./components/CouponMaster/CouponsList.js";
import CouponAddEdit from "./components/CouponMaster/CouponAddEdit.js";
import VariantsList from "./components/PostMaster/VariantsList.js";
import AddVariants from "./components/PostMaster/AddVariants.js";
import CompeletedAuctions from "./components/BiddingMaster/CompeletedAuctions.js";
import AuctionWinner from "./components/BiddingMaster/AuctionWinner.js";
import PushNotification from "./components/UserMaster/Customer/PushNotification.js";





class Routes extends Component {
  render() {
    return (
      <Router baseName={"/"}>
          {localStorage.getItem("tenhil_ad")!== "true" ? (
          <Route exact path={"/"} component={Login} />
        ) : (
          <Home>
            <Route exact path={"/"} component={Dashboard} />
            <Route exact path={"/languages"} component={LanguageList} />
            <Route
              exact
              path={"/languages/add/:language_id?"}
              component={LanguageAdd}
            />
             <Route exact path={"/payment_type"} component={Payment_typeList} />
              <Route
              exact
              path={"/payment_type/add/:payment_type_id?"}
              component={Payment_typeAddTab}
            />

            <Route exact path={"/users"} component={UserList} />
            <Route exact path={"/push-notification"} component={PushNotification} />
            <Route exact path={"/calender"} component={Calender} />

            <Route exact path={"/user/view/:user_id?"} component={UserEDIT} />
            <Route exact path={"/celebrity"} component={CelebrityList} />
            <Route
              exact
              path={"/celebrity/add"}
              component={CelebrityAddTab}
            />
            <Route
              exact
              path={"/users/:customer_id/address"}
              component={CustomerAddressList}
            />
            <Route exact path={"/users/article"} component={UserArticle} />
            <Route
              exact
              path={"/users/wishlist/:customer_id"}
              component={Wishlist}
            />

            <Route exact path={"/category"} component={CategoryList} />
            <Route
              exact
              path={"/category/add/:category_id?"}
              component={CategoryAddTab}
            />
            <Route exact path={"/childcategory/:category_id?"} component={ChildCategoryList} />
            <Route
              exact
              path={"/childCategory/add/:parent_id/:category_id"}
              component={ChildCategoryAddTab}
            />
            <Route exact path={"/subcategory/:child_id?"} component={SubCategoryList} />
            <Route
              exact
              path={"/subCategory/add/:child_id?/:category_id?"}
              component={SubCategoryAddTab}
            />
           
            <Route exact path={"/price-filter"} component={PriceFilter} />
            <Route exact path={"/addPrice-filter/:filter_id?"} component={PackageAddPage} />

            <Route exact path={"/timeslot"} component={TimeslotList} />
            <Route
              exact
              path={"/timeslot/add/:timeslot_id?"}
              component={TimeslotAddTab}
            />
            <Route exact path={"/dateblock"} component={DateList} />
            <Route
              exact
              path={"/dateblock/add/:dateblock_id?"}
              component={DateAddTab}
            />

            <Route exact path={"/blog"} component={BlogList} />
            <Route exact path={"/blog/add/:blog_id?"} component={BlogAddTab} />
            <Route exact path={"/blog-comments"} component={BlogComments} />

            <Route exact path={"/banner"} component={BannerList} />
            <Route
              exact
              path={"/banner/add/:banner_id?"}
              component={BannerAddTab}
            />
        
            <Route exact path={"/post/add/:postId?"} component={AddPOST} />
            <Route exact path={"/posts"} component={AllPostList} />
            <Route exact path={"/top-posts"} component={TopPosts} />

            <Route exact path={"/variants/:postId?"} component={VariantsList} />
            <Route exact path={"/variantsAdd/:postId?"} component={AddVariants} />


            <Route exact path={"/bidding-posts"} component={BiddingList} />
            <Route exact path={"/completed-auctions"} component={CompeletedAuctions} />
            <Route exact path={"/auction-winners/:id"} component={AuctionWinner} />
            <Route exact path={"/bidding-posts/add/:postId?"} component={BiddingADD} />
            <Route exact path={"/upcoming-posts"} component={UpcomingPOSTS} />
            <Route exact path={"/ongoing-posts"} component={OngoingPOSTS} />
            <Route exact path={"/complete-posts"} component={CompletePostList} />
            <Route exact path={"/bids/:post_id?"} component={BidsList} />

            <Route exact path={"/UpComingPostDetail/:post_id?"} component={UpComingPostDetail} />
            <Route exact path={"/CompletePostDetail/:post_id?"} component={CompletePostDetail} />
            <Route exact path={"/OngoingPostDetail/:post_id?"} component={OnGoingPostDetail} />



            <Route exact path={"/gifts/add/:giftId?"} component={MonthlyGiftAdd} />
            <Route exact path={"/gifts"} component={MonthlyGiftsList} />
            <Route exact path={"/gift-winners/:giftId?"} component={MonthlyGiftWinners} />

            <Route exact path={"/coupons"} component={CouponsList} />
            <Route exact path={"/coupons/add/:couponId?"} component={CouponAddEdit} />


            <Route exact path={"/customized"} component={CustomizedList} />
            <Route
              exact
              path={"/customized/add/:customized_id?"}
              component={CustomizedAddTab}
            />

            <Route exact path={"/attribute-add/:attribute_id?"} component={AddAttribute} />
            <Route exact path={"/attribute-list"} component={AttributeList} />
            <Route
              exact
              path={"/attributes/add/:attribute_id?"}
              component={AttributeAddTab}
            />

            <Route
              exact
              path={"/attribute-values/:attribute_id"}
              component={AttributeValueList}
            />
            <Route
              exact
              path={"/attribute-values/:attribute_id/add/:attribute_value_id?"}
              component={AttributeValueAddTab}
            />

            <Route exact path={"/orders"} component={OrderList} />
            <Route exact path={"/bookings"} component={BookedOrders} />
            <Route exact path={"/delivery"} component={DeleveryOrders} />
            <Route
              exact
              path={"/orders/view/:order_id"}
              component={ViewOrder}
            />

            <Route exact path={"/country"} component={CountryList} />
            <Route
              exact
              path={"/country/add/:country_id?"}
              component={CountryAddTab}
            />

            <Route exact path={"/governorate"} component={GovernorateList} />
            <Route
              exact
              path={"/governorate/add/:governorate_id?"}
              component={GovernorateAddTab}
            />

            <Route exact path={"/area"} component={AreaList} />
            <Route exact path={"/area/add/:area_id?"} component={AreaAddTab} />

            <Route
              exact
              path={"/refund-policy"}
              component={RefundPolicyAddTab}
            />
            <Route
              exact
              path={"/privacy-policy"}
              component={PrivacyPolicyAddTab}
            />
            <Route
              exact
              path={"/cancellation-policy"}
              component={CancellationPolicyAddTab}
            />
            <Route exact path={"/terms"} component={TermsAddEdit} />
            <Route exact path={"/about"} component={AboutUsAddEdit} />
            <Route exact path={"/contact-us"} component={ContactUsAddEdit} />
            <Route
              exact
              path={"/contact-us/settings"}
              component={GeneralSettingAddTab}

            />
          </Home>
        )}
      </Router>
    );
  }
}

export default Routes;
