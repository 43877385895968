import axios from "axios";
import Constant from "../Constant";
import {
  SET_LOADING,
  LOGOUT,
  GET_USERS,
  GET_CELEBRITY,
  GET_CATEGORY,
  GET_SUB_CATEGORY,
  GET_BANNER,
  GET_COUNTRY,
  GET_GOVERNORATE,
  GET_AREA,
  GET_POST,
  GET_LANGUAGE_LIST,
  GET_SCHEDULE,
  GET_PACKAGE,
  GET_TIME_SLOT,
  GET_DATE_BLOCK,
  GET_BIDSLIST,
  GET_PAYMENT_TYPE,
  GET_PURCHASE_LIST,
  GET_ADDRESS_LIST,
  GET_TERMS,
  GET_MONTHLY_GIFT_WINNERS,
  GET_ATTRIBUTES,
  GET_MONTHLY_GIFTS,
  GET_ABOUT_US,
  GET_TNC_DATA,
  GET_CONTACT_US,
  GET_ALL_COUPONS,
  GET_AUCTION_WINNER_LIST,
  GET_DASHBOARD,
} from "./types";
import Swal from "sweetalert2";
import { data } from "jquery";

var config = {
  headers: {
    Authorization: `${localStorage.getItem("caviar_ad_auth")}`,
  },
};

var paramConfig = {
  Authorization: `${localStorage.getItem("caviar_ad_auth")}`,
};

// SET LOADING
export const setLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: data,
  });
};

// LOGIN
export const loginUser = (data) => async (dispatch) => {
  try {
    const res = await axios.post(Constant.getAPI() + "admin/login", data);
    if (res.data.status) {
      Swal.fire("Login Successfully", "", "success");

      localStorage.setItem("caviar_ad", true);
      localStorage.setItem("tenhil_ad", true);
      localStorage.setItem("caviar_ad_auth", res.data.auth);
      localStorage.setItem("caviar_ad_name", res.data.data.userName);

      window.location.reload();
    } else {
      Swal.fire("Incorrect Credentials", "", "error");
    }
  } catch (err) {
    console.log(err);
    Swal.fire("Incorrect Credentials", "", "error");
  }
};

// LOGOUT
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
    payload: {},
  });
  localStorage.clear();
  window.location.href = "#/";
  window.location.reload();
};

// LANGUAGE LIST
// export const getLanguageList = (data) => (dispatch) => {
//   axios
//     .post(Constant.getAPI() + "/language")
//     .then((res) => {
//       dispatch({
//         type: GET_LANGUAGE_LIST,
//         payload: res.data.result,
//       });
//       console.log(res);
//       localStorage.setItem("wlo-language", res.data.data[0].id);
//     })
//     .catch((err) => console.log(err));
// };

// GET DASHBOARD

export const getDashBoard = (data) => (dispatch) =>{
  dispatch(setLoading(true));

  axios
  .post(Constant.getAPI() + `admin/dashboard`,data , config)
  .then((res) => {
    if(res?.data?.status){
      dispatch({
        type: GET_DASHBOARD,
        payload: res?.data?.data
      })
    }else {
      dispatch({
        type: GET_DASHBOARD,
        payload:[]
      })
    }
  })
  .catch((err) => console.log(err));
}

// USERS LIST
export const getUsers = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/user/list", data, config)
    .then((res) => {
      var users=res.data.data;
      // let j = 0;
      // var userarr=[];
      // for(let i=0;i<users.length;i++){
      //   if(users[i].userType === "normal" )
      //   {
      //     userarr[j] = users[i];
      //     j++;
      //   }
      // }
      dispatch({
        type: GET_USERS,
        payload:users,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// UPDATE USER DETAILS BY ADMIN
export const updateUserDetails = (data) => (dispatch) => {
  dispatch(setLoading(true))

  axios
  .post(Constant.getAPI() +  `user/updateProfileByAdmin` , data , config)
  .then((res) => {
    if(res?.data.status){
      dispatch(getUsers())
      Swal.fire({
        title: "Updated",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        // imageHeight: 100,
        text: "User Updated SuccessFully",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = ("#/users")
    }
  })
  .catch((err) => console.log(err));
}

//celebrity add
export const registerUser = (data) => async (dispatch) => {

    console.log(data.payload)
    debugger
    const res = await axios.post(Constant.getAPI() + "/user/register",data.payload);
    console.log(res);
    Swal.fire({

      title: "Register Successfully",
      icon: "",
      // imageUrl: "./assets/images/caviar.png",
      imageHeight: 100,
      text: "",
      type: "success",
      confirmButtonColor: "#3ab1f7",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    });
     //window.location.href = "#/celebrity" 
     
  
};

export const deleteUser = (data) => (dispatch) => {
  //console.log(data)
  
  Swal.fire({
   title: "Are you sure?",
   text: "You will not be able to recover this !",
   type: "warning",
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "No, keep it",
 }) .then((isConfirm)=>{
   if(isConfirm.value){
   axios
     .post(Constant.getAPI() + "user/delete", data, config)
     .then((res) => {
       Swal.fire({
         title: "Deleted!",
         text: "You will not be able to recover this !",
        //  imageUrl: "./assets/images/caviar.png",
         imageHeight: 100,
         type: "warning",
        
       })
       window.location.reload();
       dispatch( getCategory() );
     })
     .catch((err) => console.log(err));}
 })};
// CELEBRITY LIST
export const getCelebrity = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/user/list", data, config)
    .then((res) => {
      console.log(res.data.data)
      var celeb=res.data.data;
      let j = 0;
      var celebarr=[];
      for(let i=0;i<celeb.length;i++){
        if(celeb[i].userType === "celebrity")
        {
          celebarr[j] = celeb[i];
          j++;
        }
      }
      dispatch({

        type: GET_CELEBRITY,
        payload: celebarr,
      });
      console.log(res.data.data);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
//CELEBRITY SHEDULE
export const celebrityShedule = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/celebrityschedule/get"  )
    .then((res) => {
      console.log(res.data.data)
      dispatch({
        type: GET_SCHEDULE,
        payload:res.data.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// CATEGORY LIST
export const getCategory = (data) => (dispatch) => {
  dispatch(setLoading(true));
  console.log(config)
  axios
    .post(Constant.getAPI() + "/category/get", data, config)
    .then((res) => {
      console.log(res.data.data)
      dispatch({
       
        type: GET_CATEGORY,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
//SUB CATEGORY LIST
export const getSubCategory = (id) => (dispatch) => {
  dispatch(setLoading(true));
  console.log(id)
  axios
    .post(Constant.getAPI() + "/category/get",{}, config)
    .then((res) => {
      console.log(res.data.data[0].id)
      var category=res.data.data;
      var j=0;
      var catarr=[];
      for(let i=0; i<category.length; i++){
        if(category[i].id == id){
          catarr[j]=category[i];
           
        }
      }
      dispatch({
        type: GET_SUB_CATEGORY,
        payload:catarr,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
// ADD CATEGORY
export const addCategory = (data) => (dispatch) => {
  dispatch(setLoading(true));
   console.log(data)
    axios
    .post(Constant.getAPI() + "/category/add", data, config)
    .then((res) => {
      console.log(res)
      Swal.fire({
        title: "Category added",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = "#/category";
      dispatch(getCategory());
    })
    .catch((err) => console.log(err));
};

//DELETE CATEGORY
export const deleteCategory = (data) => (dispatch) => {
 //console.log(data)
 
 Swal.fire({
  title: "Are you sure?",
  text: "You will not be able to recover this !",
  type: "warning",
  showCancelButton: true,
  confirmButtonText: "Yes, delete it!",
  cancelButtonText: "No, keep it",
}) .then((isConfirm)=>{
  if(isConfirm.value){
  axios
    .post(Constant.getAPI() + "/category/delete", data, config)
    .then((res) => {
      Swal.fire({
        title: "Deleted!",
        text: "You will not be able to recover this !",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        type: "warning",
       
      })
      window.location.reload();
      dispatch( getCategory() );
    })
    .catch((err) => console.log(err));}
})};
//SubCategory Add
export const addSubCategory = (data) => (dispatch) => {
  dispatch(setLoading(true));
   console.log(data)
    axios
    .post(Constant.getAPI() + "/category/add", data, config)
    .then((res) => {
      console.log(res)
      Swal.fire({
        title: "Added",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      // window.location.href = "#/category";
      dispatch(getSubCategory());
    })
    .catch((err) => console.log(err));
};
//update subcategory
export const updateSubCategory = (data) => (dispatch) => {
  console.log(data)
  axios

    .post(Constant.getAPI() + `/category/edit`, data, config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Updated",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getSubCategory());
      window.location.href = "#/category" 
    })
    .catch((err) => console.log(err));
};
//update category
export const updateCategory = (data) => (dispatch) => {
  console.log(data)
  axios

    .post(Constant.getAPI() + `/category/edit`, data, config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Category Updated",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCategory());
      window.location.href = "#/category" 
    })
    .catch((err) => console.log(err));
};

export const addAttribute = (data) => (dispatch) => {
  dispatch(setLoading(true));
   console.log(data)
    axios
    .post(Constant.getAPI() + "config/setAttributes", data, config)
    .then((res) => {
      console.log(res)
      if(res.data.success){
        Swal.fire({
          title: "Attribute added",
          icon: "success",
          imageHeight: 100,
          text: "",
          type: "success",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then(function(){
          window.location.reload();
        });
      }else{
        Swal.fire({
          title: "Error Occured",
          icon: "error",
          imageHeight: 100,
          text: "",
          type: "error",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      }
     
      // dispatch(getCategory());
    })
    .catch((err) => console.log(err));
};

export const updateAttribute = (data) => (dispatch) => {
  dispatch(setLoading(true));
   console.log(data)
    axios
    .post(Constant.getAPI() + "config/editAttributes", data, config)
    .then((res) => {
      console.log(res)
      if(res.data.success){
        Swal.fire({
          title: "Attribute Updated",
          icon: "success",
          imageHeight: 100,
          text: "",
          type: "success",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then(function(){
          window.location.reload();
        });
      }else{
        Swal.fire({
          title: "Error Occured",
          icon: "error",
          imageHeight: 100,
          text: "",
          type: "error",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      }
     
      // dispatch(getCategory());
    })
    .catch((err) => console.log(err));
};

// BANNERS LIST
export const getBanner = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/banner/list", data, config)
    .then((res) => {
      dispatch({
        type: GET_BANNER,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
// ADD BANNER
export const addBanner = (data) => (dispatch) => {
  dispatch(setLoading(true));
   console.log(data)
    axios
    .post(Constant.getAPI() + "/banner/add", data, config)
    .then((res) => {
      console.log(res)
      Swal.fire({
        title: "Banner added",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        // imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getBanner());
      window.location.href = "#/banner";
    })
    .catch((err) => console.log(err));
};
//DELETE BANNER
export const deleteBanner = (data) => (dispatch) => {
 console.log(data)
 
 Swal.fire({
  title: "Are you sure?",
  text: "You will not be able to recover this !",
  type: "warning",
  showCancelButton: true,
  confirmButtonText: "Yes, delete it!",
  cancelButtonText: "No, keep it",
  
}).then((isConfirm)=>{
  if(isConfirm.value){
  axios
    .post(Constant.getAPI() + "/banner/delete", data, config)
    .then((res) => {
      Swal.fire({
        title: "Banner Deleted",
        text: "You will not be able to recover this !",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        type: "warning",
        // showCancelButton: true,
        // confirmButtonText: "Yes, delete it!",
        // cancelButtonText: "No, keep it",
      })
      dispatch( getBanner() );
    })
    .catch((err) => console.log(err));}
})};
// UPDATE BANNER
export const updateBanner = (data) => (dispatch) => {
  console.log(data)
  axios

    .post(Constant.getAPI() + "/banner/edit", data, config)
    .then((res) => {
      console.log(res.data)
      if(res.data.status){
        Swal.fire({
          title: "Banner Updated",
          icon: "",
          // imageUrl: "./assets/images/caviar.png",
          imageHeight: 100,
          text: "",
          type: "success",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        dispatch(getBanner());
        window.location.href = "#/banner" 
      }
    })
    .catch((err) => console.log(err));
};

// COUNTRY LIST
export const getCountry = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/country/get" ,data,config)
    .then((res) => {
      dispatch({
        type: GET_COUNTRY,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
//ADD COUNTRY
export const addCountry = (data) => (dispatch) => {
  console.log(config)
  axios
    .post(Constant.getAPI() + `/country/add`, data.payload, config)
    .then((res) => {
      console.log(res)
      Swal.fire({
        title: "Country added",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCountry());
      window.location.href = "#/country" 
    })
    .catch((err) => console.log(err));
};
//UPDATE COUNTRY
export const updateCountry = (data) => (dispatch) => {
  console.log(data)
  axios

    .post(Constant.getAPI() + `/country/edit`, data, config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Country Updated",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCountry());
      window.location.href = "#/country" 
    })
    .catch((err) => console.log(err));
};

//COUNTRY DELETE
export const deleteCountry = (data) => (dispatch) => {
  //console.log(data)
  
  Swal.fire({
   title: "Are you sure?",
   text: "You will not be able to recover this !",
  //  imageUrl: "./assets/images/caviar.png",
   imageHeight: 100,
   type: "warning",
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "No, keep it",
 }) .then((isConfirm)=>{
   if(isConfirm.value){
   axios
     .post(Constant.getAPI() + "/country/delete", data, config)
     .then((res) => {
       Swal.fire({
         title: "Country Deleted",
         text: "You will not be able to recover this !",
         type: "warning",
        //  imageUrl: "./assets/images/caviar.png",
         imageHeight: 100,
         // showCancelButton: true,
         // confirmButtonText: "Yes, delete it!",
         // cancelButtonText: "No, keep it",
       })
       dispatch( getCountry() );
      window.location.href = "#/country" 

     })
     .catch((err) => console.log(err));
    }
 })};


// GOVERNORATE LIST
export const getGovernorate = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/governorate/get", data, config)
    .then((res) => {
      dispatch({
        type: GET_GOVERNORATE,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
// DELETE GOVERNORATE
export const deleteGovernorate = (data) => (dispatch) => {
  console.log(data)
  
  Swal.fire({
   title: "Are you sure?",
   text: "You will not be able to recover this !",
  //  imageUrl: "./assets/images/caviar.png",
   imageHeight: 100,
   type: "warning",
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "No, keep it",
 }) .then((Confirm)=>{
   if(Confirm.value){
   axios
     .post(Constant.getAPI() + "/governorate/delete", data, config)
     .then((res) => {
       Swal.fire({
         title: "Governorate Deleted",
        //  imageUrl: "./assets/images/caviar.png",
         imageHeight: 100,
         text: "You will not be able to recover this !",
        //  imageUrl: "./assets/images/caviar.png",
         imageHeight: 100,
         type: "warning",
        
       })
       dispatch(getGovernorate() );
      window.location.href = "#/governorate" 

     })
     .catch((err) => console.log(err));}
 })};
 //ADD GOVERNORATE
 export const addGovernorate = (data) => (dispatch) => {
  axios
    .post(Constant.getAPI() +"/governorate/add",data, config)
    .then((res) => {
      console.log(res)
      Swal.fire({
        title: "Governorate added",
        type: "success",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getGovernorate());
      window.location.href = "#/governorate" 
    })
    .catch((err) => console.log(err));
};
//UPDATE GOVERNORATE
export const updateGovernorate = (data) => (dispatch) => {
  console.log(data)
  axios
    .post(Constant.getAPI() +"/governorate/edit", data, config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Governorate Updated",
        type: "success",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch( getGovernorate() );
      window.location.href = "#/governorate" 
    })
    .catch((err) => console.log(err));
};

// AREA LIST
export const getArea = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/area/get", data, config)
    .then((res) => {
      dispatch({
        type: GET_AREA,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
//DELETE AREA
export const deleteArea = (data) => (dispatch) => {
  console.log(data)
  
  Swal.fire({
   title: "Are you sure?",
   text: "You will not be able to recover this !",
   type: "warning",
  //  imageUrl: "./assets/images/caviar.png",
   imageHeight: 100,
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "No, keep it",
 }) .then((Confirm)=>{
   if(Confirm.value){
   axios
     .post(Constant.getAPI() + "/area/delete", data, config)
     .then((res) => {
       Swal.fire({
         title: "Area Deleted",
         text: "You will not be able to recover this !",
         type: "warning",
        //  imageUrl: "./assets/images/caviar.png",
         imageHeight: 100,
        
       })
       dispatch(getArea() );
      window.location.href = "#/area" 

     })
     .catch((err) => console.log(err));}
 })};
 //UPDATE AREA
 export const updateArea = (data) => (dispatch) => {
  console.log(data)
  axios
    .post(Constant.getAPI() +"/area/edit", data, config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Area Updated",
        type: "success",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch( getArea() );
      window.location.href = "#area" 
    })
    .catch((err) => console.log(err));
};

 //ADD AREA
 export const addArea = (data) => (dispatch) => {
   console.log(data)
  axios
    .post(Constant.getAPI() +"/area/add",data, config)
    .then((res) => {
      console.log(res)
      Swal.fire({
        title: "Area added",
        type: "success",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getArea());
      window.location.href = "#/area" 
    })
    .catch((err) => console.log(err));
};
//UPDATE POST STATUS
export const updatePostStatus = (data) => (dispatch) => {
  //console.log(data)
  axios.post(Constant.getAPI() + "/post/status", data, config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Status changed",
        type: "Success",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",

      });
      var data={
        requestType:"upcoming"
      }
      dispatch(getPost(data))
    })
    .catch((err) => console.log(err));
};
//ALL POSTS
export const getPost = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "post/list", data, config)
    .then((res) => {
      console.log(res.data.data)
      
      dispatch({
        type: GET_POST,
        payload:res?.data?.data,
      });
      console.log(res);
      // dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// Change status
export const updatePostActive = (data) => (dispatch) => {
  //console.log(data)
  axios.post(Constant.getAPI() + "/post/active", data, config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Status changed",
        type: "Success",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",

      });
      
      // dispatch(getPost(data))
    })
    .catch((err) => console.log(err));
};


export const getTopPost = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "post/top", data, config)
    .then((res) => {
      console.log(res.data.data)
      
      dispatch({
        type: GET_POST,
        payload:res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const addPOST = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "post/add", data, config)
    .then((res) => {
      if(res.data.status){
        Swal.fire({
          title: "Post Added Successfully",
          type: "Success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
  
        });
        window.location.href='#/posts'
      }
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const addVariants = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "variant/add", data, config)
    .then((res) => {
      if(res.data.status){
        Swal.fire({
          title: "Variant Add Successfully",
          type: "Success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
  
        });
        window.location.reload()
      }
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const UpdateVariant = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "variant/update", data, config)
    .then((res) => {
      if(res.data.status){
        Swal.fire({
          title: "Variant update Successfully",
          type: "Success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
  
        });
        window.location.reload()
      }
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const deleteVariant = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "variant/delete", data, config)
    .then((res) => {
      if(res.data.status){
        Swal.fire({
          title: "Variant Deleted Successfully",
          type: "Success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
  
        });
        window.location.reload()
      }
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const VariantAdd = (data) =>  (dispatch) => {
  dispatch(setLoading(true));

  axios
  .post(Constant.getAPI()+ 'variant/add')
}

export const updatePOST = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "post/update", data, config)
    .then((res) => {
      if(res.data.status){
        Swal.fire({
          title: "Post Updated Successfully",
          type: "Success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
  
        });
        window.location.reload()
        var data={
          bids:'', 
          price:'', 
          type:'Auction', 
          status:'', 
          startRange:'', 
          recordLimit:'',
        }
        // dispatch(getPost(data))
      }
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const MonthlyGiftList = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "gift/list", data, config)
    .then((res) => {
      console.log(res?.data?.data)
      
      dispatch({
        type: GET_MONTHLY_GIFTS,
        payload:res?.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err.response));
};

export const deleteGifts = (data) => (dispatch) => {
  console.log(data)
  
  Swal.fire({
   title: "Are you sure?",
   text: "You will not be able to recover this !",
   type: "warning",
   imageHeight: 100,
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "No, keep it",
 }) .then((Confirm)=>{
   if(Confirm.value){
   axios
     .post(Constant.getAPI() + "gift/delete", data, config)
     .then((res) => {
       Swal.fire({
         title: "Gift Deleted",
         text: "You will not be able to recover this !",
         type: "warning",
         imageHeight: 100,
         
       })
       dispatch(getPackagePriceFilter() );
      window.location.reload(); 

     })
     .catch((err) => console.log(err));}
 })};

export const WinnersList = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + `gift/leaderboard/${data.id}`, data, config)
    .then((res) => {
      console.log(res.data.data)
      
      dispatch({
        type: GET_MONTHLY_GIFT_WINNERS,
        payload:res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const GiftAdd = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "gift/add", data, config)
    .then((res) => {
      console.log(res.data.data)
      
      Swal.fire({
        title: "Added",
        text: "Gift Added Successfully !",
        imageHeight: 100,
        type: "success",
      })
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) =>{

      // console.log(err.response.data.error,'errr');
      
      Swal.fire({
        title: "Sorry!",
        text: `${err.response.data.error}`,
        imageHeight: 100,
        type: "error",
      })
    } );
};

export const updateGIFT = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI() + `gift/edit` , data, config)
  .then((res) => {
    if(res.data?.status){
      dispatch(MonthlyGiftList())
      Swal.fire({
        title: "Updated",
        text: "Gift Updated Successfully !",
        imageHeight: 100,
        type: "success",
      })
      window.location.href=('#/gifts')
    }
  })
  .catch((err) => console.log(err));
}

export const auctionWinnerList = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
  .post(Constant.getAPI() + `bidding/list` ,data , config)
  .then((res)=>{
    console.log("DATAT",res.data.biddings);

    dispatch({
      type: GET_AUCTION_WINNER_LIST,
      payload:res.data.biddings,
    })
    dispatch(setLoading(false))
  })
  .catch((err) => console.log(err));
}

export const AboutUsGet = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .get(Constant.getAPI() + `admin/get/aboutus`, data, config)
    .then((res) => {
      console.log(res.data.data)
      
      dispatch({
        type: GET_ABOUT_US,
        payload:res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
}

export const AboutUsAdd = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "admin/add/aboutus", data, config)
    .then((res) => {
      console.log(res.data.data)
      
      Swal.fire({
        title: "Added",
        text: "Added Successfully !",
        imageHeight: 100,
        type: "success",
      })
      console.log(res);
      
      dispatch(setLoading(false));
      dispatch(AboutUsGet());
    })
    .catch((err) =>{
      
      Swal.fire({
        title: "Error",
        text: "Something Went Wrong !",
        imageHeight: 100,
        type: "error",
      })
    } );
};

export const TERMSGet = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .get(Constant.getAPI() + `admin/get/tandc`, data, config)
    .then((res) => {
      console.log(res.data.data)
      
      dispatch({
        type: GET_TNC_DATA,
        payload:res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
}

export const TERMSAdd = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "admin/add/tandc", data, config)
    .then((res) => {
      console.log(res.data.data)
      
      Swal.fire({
        title: "Added",
        text: "Added Successfully !",
        imageHeight: 100,
        type: "success",
      })
      console.log(res);
      
      dispatch(setLoading(false));
      dispatch(TERMSGet());
    })
    .catch((err) =>{
      
      Swal.fire({
        title: "Error",
        text: "Something Went Wrong !",
        imageHeight: 100,
        type: "error",
      })
    } );
};


export const ContactUsGet = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + `/contact/get`, data, config)
    .then((res) => {
      console.log(res.data.data)
      
      dispatch({
        type: GET_CONTACT_US,
        payload:res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
}

export const ContactUsAdd = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "contact/add", data, config)
    .then((res) => {
      console.log(res.data.data)
      
      Swal.fire({
        title: "Added",
        text: "Added Successfully !",
        imageHeight: 100,
        type: "success",
      })
      console.log(res);
      
      dispatch(setLoading(false));
      dispatch(ContactUsGet());
    })
    .catch((err) =>{
      
      Swal.fire({
        title: "Error",
        text: "Something Went Wrong !",
        imageHeight: 100,
        type: "error",
      })
    } );
};
// // LIVE POST LIST
// export const getLivePost = (data) => (dispatch) => {
//   dispatch(setLoading(true));

//   axios
//     .post(Constant.getAPI() + "/post/get", data, config)
//     .then((res) => {
//       console.log(res.data.data)
//       var post=res.data.data;
//       let j = 0;
//       var postarr=[];
//       for(let i=0;i<post.length;i++){
//         if(post[i].postStatus === "upcoming")
//         {
//           postarr[j] = post[i];
//           j++;
//         }
//       }
//       dispatch({
//         type: GET_LIVE_POST,
//         payload: postarr,
//       });
//       console.log(res);
//       dispatch(setLoading(false));
//     })
//     .catch((err) => console.log(err));
// };
// //ONGOING POST
// export const getOnGoingPost = (data) => (dispatch) => {
//   dispatch(setLoading(true));

//   axios
//     .post(Constant.getAPI() + "/post/get", data, config)
//     .then((res) => {
//       console.log(res.data.data)
//       var post=res.data.data;
//       let j = 0;
//       var postarr=[];
//       for(let i=0;i<post.length;i++){
//         if(post[i].postStatus === "ongoing")
//         {
//           postarr[j] = post[i];
//           j++;
//         }
//       }
//       dispatch({
//         type: GET_ONGOING_POST,
//         payload: postarr,
//       });
//       console.log(res);
//       dispatch(setLoading(false));
//     })
//     .catch((err) => console.log(err));
// };
//DELETE POST
export const deletePost = (data) => (dispatch) => {
  console.log(data)
  
  Swal.fire({
   title: "Are you sure?",
   text: "You will not be able to recover this !",
   type: "warning",
  //  imageUrl: "./assets/images/caviar.png",
   imageHeight: 100,
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "No, keep it",
 }) .then((Confirm)=>{
   if(Confirm.value){
   axios
     .post(Constant.getAPI() + "post/delete", data, config)
     .then((res) => {
       Swal.fire({
         title: "Post Deleted",
        //  imageUrl: "./assets/images/caviar.png",
         imageHeight: 100,
         text: "You will not be able to recover this !",
         type: "warning",
      
       })
      window.location.reload()
    

     })
     .catch((err) => console.log(err));}
 })};

//LANGUAGE LIST
export const getLanguage = () => (dispatch) => {
  dispatch(setLoading(true));
  
    axios
    .post(Constant.getAPI() + "/language/get")
    .then((res) => {
      dispatch({
        type: GET_LANGUAGE_LIST,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};





//PACKAGE ADD
export const addPackagePriceFilter = (data) => (dispatch) => {
  console.log(data)
 axios
   .post(Constant.getAPI() +"pricefilter/add",data, config)
   .then((res) => {
     console.log(res)
     Swal.fire({
       title: "Package added",
       type: "success",
       imageHeight: 100,
       confirmButtonColor: "#3ab1f7",
       cancelButtonColor: "#d33",
       confirmButtonText: "Ok",
     });
     dispatch(getPackagePriceFilter());
     window.location.href = "#/price-filter" 
   })
   .catch((err) => console.log(err));
};
//PACKAGE LIST
export const getPackagePriceFilter = () => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .get(Constant.getAPI() + "pricefilter/listAll", config)
    .then((res) => {
      dispatch({
        type: GET_PACKAGE,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//DELETE PACKAGE
export const deletePackagePriceFilter = (data) => (dispatch) => {
  console.log(data)
  
  Swal.fire({
   title: "Are you sure?",
   text: "You will not be able to recover this !",
   type: "warning",
   imageHeight: 100,
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "No, keep it",
 }) .then((Confirm)=>{
   if(Confirm.value){
   axios
     .post(Constant.getAPI() + "pricefilter/delete", data, config)
     .then((res) => {
       Swal.fire({
         title: "Package Deleted",
         text: "You will not be able to recover this !",
         type: "warning",
         imageHeight: 100,
         
       })
       dispatch(getPackagePriceFilter() );
      window.location.href = "#/price-filter" 

     })
     .catch((err) => console.log(err));}
 })};

 //EDIT PACKAGE
 export const updatePackagePriceFilter = (data) => (dispatch) => {
  console.log(data)
  axios
    .post(Constant.getAPI() +"pricefilter/edit",data,config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Package Updated",
        imageHeight: 100,
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getPackagePriceFilter());
      window.location.href = "#/price-filter" 
    })
    .catch((err) => console.log(err));
};






//GET TIME SLOT
export const getTimeslot = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/timeslot/get" ,config)
    .then((res) => {
      dispatch({
        type: GET_TIME_SLOT,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//ADD TIMESLOT
export const addTimeslot = (data) => (dispatch) => {
  console.log(config)
  axios
    .post(Constant.getAPI() + `/timeslot/add`, data, config)
    .then((res) => {
      console.log(res)
      Swal.fire({
        title: "Timeslot added",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getTimeslot());
      window.location.href = "#/timeslot" 
    })
    .catch((err) => console.log(err));
};

//UPDATE TIMESLOT
export const updateTimeslot= (data) => (dispatch) => {
  console.log(data)
  axios

    .post(Constant.getAPI() + `/timeslot/edit`, data, config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Timeslot Updated",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getTimeslot());
      window.location.href = "#/timeslot" 
    })
    .catch((err) => console.log(err));
};

//DELETE TIMESLOT
export const deleteTimeslot = (data) => (dispatch) => {
  //console.log(data)
  
  Swal.fire({
   title: "Are you sure?",
   text: "You will not be able to recover this !",
   type: "warning",
  //  imageUrl: "./assets/images/caviar.png",
   imageHeight: 100,
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "No, keep it",
 }) .then((isConfirm)=>{
   if(isConfirm.value){
   axios
     .post(Constant.getAPI() + "/timeslot/delete", data, config)
     .then((res) => {
       Swal.fire({
         title: "Timeslot Deleted",
         text: "You will not be able to recover this !",
         type: "warning",
        //  imageUrl: "./assets/images/caviar.png",
         imageHeight: 100,
       })
       dispatch( getTimeslot() );
      window.location.href = "#/timeslot" 

     })
     .catch((err) => console.log(err));
    }
 })};

//GET DATE
export const getDateblock = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/dateblock/get" ,config)
    .then((res) => {
      dispatch({
        type: GET_DATE_BLOCK,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//ADD DATE BLOCK
export const addDateblock = (data) => (dispatch) => {
  console.log(config)
  axios
    .post(Constant.getAPI() + `/dateblock/add`, data, config)
    .then((res) => {
      console.log(res)
      Swal.fire({
        title: "Date Block added",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getDateblock());
      window.location.href = "#/dateblock" 
    })
    .catch((err) => console.log(err));
};

//UPDATE DATE BLOCK
export const updateDateblock= (data) => (dispatch) => {
  console.log(data)
  axios

    .post(Constant.getAPI() + `/dateblock/edit`, data, config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Date Block Updated",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getDateblock());
      window.location.href = "#/dateblock" 
    })
    .catch((err) => console.log(err));
};

//DELETE DATE BLOCK
export const deleteDateblock = (data) => (dispatch) => {
  //console.log(data)
  
  Swal.fire({
   title: "Are you sure?",
   text: "You will not be able to recover this !",
   type: "warning",
  //  imageUrl: "./assets/images/caviar.png",
   imageHeight: 100,
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "No, keep it",
 }) .then((isConfirm)=>{
   if(isConfirm.value){
   axios
     .post(Constant.getAPI() + "/dateblock/delete", data, config)
     .then((res) => {
       Swal.fire({
         title: "Date Block Deleted",
         text: "You will not be able to recover this !",
         type: "warning",
        //  imageUrl: "./assets/images/caviar.png",
         imageHeight: 100,
         // showCancelButton: true,
         // confirmButtonText: "Yes, delete it!",
         // cancelButtonText: "No, keep it",
       })
       dispatch( getDateblock() );
      window.location.href = "#/dateblock" 

     })
     .catch((err) => console.log(err));
    }
 })};

 // Get BidsList
export const getBids = (data) => (dispatch) => {
  dispatch(setLoading(true));
  console.log(data)
  axios
    .post(Constant.getAPI() + "/bidding/get",data)
    .then((res) => {
      console.log(res.data.biddings)
     
      dispatch({
        type: GET_BIDSLIST,
        payload:res.data.biddings,
      });
      //console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//GET PAYMENT_TYPE
export const getpayment_type = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/payment_type/get",data, config)
    .then((res) => {
      console.log(res.data.data)
      dispatch({
        type: GET_PAYMENT_TYPE,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
//ADD PAYMENT 
export const addPayment_type = (data) => (dispatch) => {
  console.log(config)
  axios
    .post(Constant.getAPI() + `/payment_type/add`, data, config)
    .then((res) => {
      console.log(res)
      Swal.fire({
        title: "Payment added",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getpayment_type());
      window.location.href = "#/payment_type"
    })
    .catch((err) => console.log(err));
};

// DELETE PAYMENT

export const deletePayment_type = (data) => (dispatch) => {
  console.log(data)
  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    // imageUrl: "./assets/images/caviar.png",
    imageHeight: 100,
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .post(Constant.getAPI() + "/payment_type/delete", data, config)
        .then((res) => {
          Swal.fire({
            title: "PaymentList Deleted",
            text: "You will not be able to recover this !",
            type: "warning",
            // imageUrl: "./assets/images/caviar.png",
            imageHeight: 100,
          })
          dispatch(getpayment_type());
          window.location.href = "#/payment_type"

        })
        .catch((err) => console.log(err));
    }
  })
};
// PAYMENT STATUS UPDATE
export const updatepayment_type = (data) => (dispatch) => {
  //console.log(data)
  axios.post(Constant.getAPI() + "/payment_type/status", data, config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Status changed",
        type: "Success",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",

      });
      window.location.reload();
    })
    .catch((err) => console.log(err));
};
// UPDATE PAYMENT
export const updatePayment_type = (data) => (dispatch) => {
  console.log(data)
  axios

    .post(Constant.getAPI() + `/payment_type/edit`, data, config)
    .then((res) => {
      console.log(res.data)
      Swal.fire({
        title: "Payment Updated",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getpayment_type());
      window.location.href = "#/payment_type"
    })
    .catch((err) => console.log(err));
};
//ORDERLIST

export const getOrdersList = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "order/getAll",data,config)
    .then((res) => {
      dispatch({
        type: GET_PURCHASE_LIST,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const getAddress = (data) => (dispatch) => {
  console.log(data)
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/address/get",data,config)
    .then((res) => {
      dispatch({
        type: GET_ADDRESS_LIST,
        payload: res.data.data,
      });
      console.log(res.data.data);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const upDateAddress = (data) => (dispatch) => {
  // dispatch(setLoading(false));

  axios
  .post(Constant.getAPI() + `/address/edit` , data , config)
  .then((res)=> {
    if(res.data.status){
      dispatch(getAddress())
      Swal.fire({
        title: "Update",
        // icon: "",
        // imageUrl: "./assets/images/caviar.png",
        // imageHeight: 100,
        text: "Address Update",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.reload() 
    }
  })
}

//ADD TERMS
export const addTerms = (data) => (dispatch) => {
  console.log(config)
  axios
    .post(Constant.getAPI() + `/terms/add`, data, config)
    .then((res) => {
      console.log(res)
      Swal.fire({
        title: "Terms added",
        icon: "",
        // imageUrl: "./assets/images/caviar.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getDateblock());
      window.location.href = "#/dateblock" 
    })
    .catch((err) => console.log(err));
};
// GET TERMS
export const getTerms = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/terms/get",data)
    .then((res) => {
      dispatch({
        type: GET_TERMS,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};


export const getAllCoupons = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "coupon/get	", data, config)
    .then((res) => {
      console.log(res.data.data)
      
      dispatch({
        type: GET_ALL_COUPONS,
        payload:res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};


export const AddCoupon = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "coupon/add", data, config)
    .then((res) => {
      console.log(res.data.data)
      
      Swal.fire({
        title: "Added",
        text: "Coupon Added Successfully !",
        imageHeight: 100,
        type: "success",
      })
      console.log(res);
      dispatch(setLoading(false));
      dispatch(getAllCoupons());
      window.location.href='#/coupons'
    })
    .catch((err) =>{
      
      Swal.fire({
        title: "Error",
        text: "Something Went Wrong !",
        imageHeight: 100,
        type: "error",
      })
    } );
};

export const UpdateCoupon = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "coupon/edit", data, config)
    .then((res) => {
      console.log(res.data.data)
      
      Swal.fire({
        title: "Added",
        text: "Coupon Updated Successfully !",
        imageHeight: 100,
        type: "success",
      })
      console.log(res);
      dispatch(setLoading(false));
      dispatch(getAllCoupons());
      window.location.href='#/coupons'
    })
    .catch((err) =>{
      
      Swal.fire({
        title: "Error",
        text: "Something Went Wrong !",
        imageHeight: 100,
        type: "error",
      })
    } );
};

export const deleteCoupon = (data) => (dispatch) => {
  console.log(data)
  
  Swal.fire({
   title: "Are you sure?",
   text: "You will not be able to recover this !",
   type: "warning",
   imageHeight: 100,
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "No, keep it",
 }) .then((Confirm)=>{
   if(Confirm.value){
   axios
     .post(Constant.getAPI() + "coupon/delete", data, config)
     .then((res) => {
       Swal.fire({
         title: "Coupon Deleted",
         text: "You will not be able to recover this !",
         type: "warning",
         imageHeight: 100,
         
       })
       dispatch(getPackagePriceFilter() );
      window.location.href = "#/coupons" 

     })
     .catch((err) => console.log(err));}
 })};


 // SEND NOTIFICATIION
 export const  sendNotification = (data) => (dispatch) => {
  console.log(data)
  axios

    .post(Constant.getAPI() + "/admin/send-notification", data, config)
    .then((res) => {
      console.log(res.data)
      if(res.data.status){
        Swal.fire({
          title: "Succes",
          // icon: "",
          // imageUrl: "./assets/images/caviar.png",
          // imageHeight: 100,
          text: "Notification Send Successfully!",
          type: "success",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        window.location.reload()
      }
    })
    .catch((err) => console.log(err));
};