import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../Loader";
import UploadImage from "../utils/UploadImage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCategory, addCategory, updateCategory } from "../../actions/homeAction";
import { Category } from "@material-ui/icons";

class CategoryAdd extends React.Component {
  state = {
    
  };
 
  componentWillReceiveProps(nextProps) {
    debugger
    this.setState({
      categoryList: nextProps.home.categoryList,
      
    });
    console.log(nextProps.home.categoryList)
  };

  componentDidUpdate(prevProps) {
    if (prevProps.category_id !== this.props.category_id) {
      this.setState({ category_id: this.props.category_id });
      this.getCategoryDetails();
    }
    if (prevProps.language_id !== this.props.language_id) {
      if (this.props.category_id !== undefined) {
        this.setState({ category_id: this.props.category_id });
        this.getCategoryDetails();
      }
       this.props.getCategory();
    }
  }
  
  uploadImage = (url,id) => {
    this.setState(
      {
        imageurl:url,
        MediumId:id,
        imagelabel:"FILE UPLOADED"
      })
    console.log(this.state.imageurl,this.state.MediumId)
  };


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value});
   
  };

  getCategoryDetails = () => {
    console.log("here")
    var category=this.state.categoryList
    console.log(category)

    if(category !== undefined){
     
    for(let i=0;i<category.length;i++)
    {
      if(category[i].id == this.props.category_id){
         this.fillInputs(category[i])
         
      }
    }}
  };

  fillInputs(category){
    console.log(category)
    if(category !== undefined && category.Medium !== null){
    this.setState({
      
      name:category.name,
      name_ar:category.name_ar,
      superId:category.SuperCategoryId,
      MediumId:category.MediumId,
      imageurl:category.Medium.url,
    })}
    
  };

  onSaveData = () => {
    var that = this;
    that.setState({ isSaving: true });
   
   
      if (that.props.category_id !== undefined) {
        that.updateCategoryData(that.state.MediaId);
      } else {
        that.addCategory(that.state.MediaId);
      }
    
  };
 
  updateCategoryData = () => {
    this.setState({ isSaving: true });
    var data={
      CategoryId:this.props.category_id,
      name:this.state.name, 
      SuperCategoryId:this.state.superId,
      languageCode:this.props.language_id,
      MediumId:this.state.MediumId

    }
    console.log(data)
    this.props.updateCategory(data);
  };

  addCategory = () => {
    this.setState({ isSaving: true });
    var data = {
      name:this.state.name, 
      SuperCategoryId:this.state.superId,
      languageCode:this.props.language_id,
      MediumId:this.state.MediumId
    };

    this.props.addCategory(data);

    return false;
  
  };

  componentDidMount() {
    
    this.loadScript(
      process.env.PUBLIC_URL + "/assets/pages/filer/jquery.fileuploads.init.js"
    );
    this.props.getCategory();
  }
  loadScript(src) {
    let script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    document.head.append(script);
  }
  render() {
    return (
      <div className="">
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="card-body">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Category Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Category Name"
                      onChange={this.handleChange}
                      value={this.state.name}
                    />
                  </div>
                </div>
              </div>
              {console.log(this.state.imageurl)}
              
              <div className="col-md-8">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Display Image</label>
                <UploadImage id={"customFile"} uploadGalleryImage={this.uploadImage}></UploadImage>
                <label class="custom-file-label" for="customFile">
                    {this.state.label}
                    
                  </label>
              </div>
            </div>
            <div className="col-md-8 ">
                <div id="id_image_section_label" className="pt-2">
                  { this.state.imageurl ?
                    this.state.imageurl !== undefined 
                     ? (
                      <img
                        src={`https://tenhil.auction/${this.state.imageurl}`}
                        alt=  ""
                        className="img-100"
                        onError={(e) => {
                          e.target.src = "";
                        }}
                      />
                    ) : (
                      ""
                    
                  ) : (
                    ""
                  )}
                </div>
              </div>

            </div>

          
            <div className="card-footer">
              <div className="row float-right p-3">
                {this.state.isSaving ? (
                  <button className="btn btn-grd-disabled mr-2" disabled>
                    Saving...!
                  </button>
                ) : (
                  
                  <button
                    onClick={this.onSaveData}
                    className="btn btn-grd-disabled mr-2"
                  >
                    <i className="icofont icofont-save"></i> Save
                  </button>
                )}
                <Link to={"/category"} className="btn btn-outline-dark">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

CategoryAdd.propsTypes = {
  getCategory: PropTypes.func.isRequired,
  updateCategory:PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getCategory, addCategory,updateCategory })(
  CategoryAdd
);
  