import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import PropTypes from "prop-types"
import Loader from "../../Loader";
import { connect } from "react-redux";
import Constant from "../../Constant";
import { getBanner, addBanner, updateBanner, } from "../../actions/homeAction";
import UploadImage from "../utils/UploadImage";
import SelectPost from "./SelectPost"
import { Label } from "@material-ui/icons";
import axios from 'axios';


class BannerAdd extends React.Component {
  state = {
    status: "AVAILABLE",
    SelectPostmenu:false,
    postLabel:"",
    PostId:"",
    detail:"",
    imageData: [],
    imageData2: [],
    imageArray:[],
    video:''
    
  };
  // handlePostAdd(){
  //   this.setState({
  //     SelectPostmenu:true

  //   })
  // }
  // handlePostId(id){
  //   this.setState({
  //     PostId:id,
  //     SelectPostmenu:false,
  //     postLabel:"Post Selected!"

  //   })
  // }
  componentWillMount() {
		this.props.getBanner();
	}
  componentWillReceiveProps(nextProps){
    const data = (nextProps.home.bannerList);

    if(this.props.banner_id){
      const match = data?.filter(item=>item?.id === this.props.banner_id)
      console.log("ITEM", match);
      this.setState({
        imageData2:match
      })
    }
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.banner_id !== this.props.banner_id) {
  //     this.setState({ banner_id: this.props.banner_id });
  //     this.getBannerDetails();
  //   }
  //   if (prevProps.language_id !== this.props.language_id) {
  //     if (this.props.banner_id !== undefined) {
  //       this.setState({ banner_id: this.props.banner_id });
  //       this.getBannerDetails();
  //     }
  //     this.props.getBanner();
  //   }

  //   if (this.props.home?.bannerList?.length !== prevProps.home?.bannerList?.length) {
  //     this.getBannerDetails();
  //   }
  // }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  // getBannerDetails = () => {
  //   if (this.props.home?.bannerList?.length) {
  //     const selectedBanner = this.props.home.bannerList.filter(item => item.id === this.props.banner_id);
  //     if (selectedBanner.length) {
  //       this.fillInputs(selectedBanner[0])
  //     }
  //     // for (let i = 0; i < banner.length; i++) {
  //     //   if (banner[i].id == this.props.banner_id) {
  //     //     this.fillInputs(banner[i])
  //     //   }
  //     // }
  //   }
  // };
  // fillInputs(banner) {
  //   console.log(banner)
  //   if (banner !== undefined && banner.Medium !== null) {
  //     this.setState({
  //       // title: banner.name,
  //       MediumIds: banner.MediumId,
  //       imageurl: banner.Medium.url,
  //     })
  //   }

  // };

  onSaveData = async (e) => {
    e.preventDefault()
    let media=[]
    let media2=""
    // var that = this;
    // if (that.state.accepted) {
    //   that.uploadMedia();
    // } else if (
    //   (that.state.MediumId === undefined ||
    //     that.state.MediumId === null ||
    //     that.state.MediumId === "") &&
    //   (that.state.imageurl === undefined ||
    //     that.state.imageurl === null ||
    //     that.state.imageurl === "") 
    // ) {
    //   Swal.fire("Error", "Select Banner Image First..! ", "warning");
    //   that.setState({ isSaving: false });
    //   return false;
    // } else {

    //   if (that.props.banner_id !== undefined) {
    //     if (that.state.isNewLanguage === true) {
    //       that.addBannerIOtherLanguage(that.state.MediumId);
    //     }

    //     else {
    //       that.updateBannerData(that.state.MediumId);
    //     }
    //   } else {
    //     that.addBanner(that.state.MediumId);
    //   }
    // }
    if(this.state.imageArray.length > 0){
      const formData = new FormData();
      this.state.imageArray.forEach((file,i)=> {
        formData.append("media" , file)
      });
      
      await axios
      .post(Constant.getAPI() + 'media/add',  formData, {
        headers: {
          Authorization: `${localStorage.getItem("caviar_ad_auth")}`,
        },
      })
      .then((res) => {
        if (res.data.data ) {
         var Data = res.data.data.map(data=>data.id)
            Data = new Set(Data)
            media = [...Data]
            if(this.props.banner_id){
              var Data2 = this.state.imageData2
                  Data2 = Data2.map(data2=>data2.id)
                  Data2 = new Set(Data2)
                  media2 = [...Data2]
            }
        } else {

        }
     })
    }

    console.log("DATA" , media);

   if(this.props.banner_id){
      var data ={
        MediumId:media?.length>0?media[0]:media2,
        bannerId:this.props.banner_id,
      }
      console.log("DD",data);
      // this.props.updateBanner(data)
   }else{
    var data = {
      MediumIds: media
    }
    // this.props.addBanner(data)
   }
  };
  // uploadMedia = (url, id) => {
  //   console.log(url)
  //   this.setState(
  //     {
  //       imageurl: url,
  //       MediumId: id,
  //       imagelabel: "FILE UPLOADED"
  //     })

  // };
  // updateBannerData = (mediumId) => {
  //   var that = this;
  //   // var data = new URLSearchParams();
  //   this.setState({ isSaving: true });
  //   var data = {
  //     bannerId: that.props.banner_id,
  //     title: that.state.title,
  //     mediumId: mediumId,
  //     status: that.state.status,
  //     lCode: that.props.language_id
  //   }
  //   console.log(data)

  //   this.props.updateBanner(data);

  // };
  // addBannerIOtherLanguage = (mediumId) => {

  // };
  // addBanner = (mediumId) => {
  //   var that = this;
  //   // var data = new URLSearchParams();
  //   this.setState({ isSaving: true });
  //   var data = {
  //     name: this.state.title,
  //     detail:this.state.detail,
  //     MediumId: mediumId,
  //     languageCode: that.props.language_id,
  //     PostId:this.state.PostId
  //   };
  //   this.props.addBanner(data);
  // };
  // handleImageUpload = (event) => {

  // };
  // componentWillMount() {
  //   this.loadScript(
  //     process.env.PUBLIC_URL + "/assets/pages/filer/jquery.fileuploads.init.js"
  //   );
  // }
  // loadScript(src) {
  //   let script = document.createElement("script");
  //   script.src = src;
  //   script.type = "text/javascript";
  //   document.head.append(script);
  // }

  ImagesToUpload = (e) => {
    let imageData = this.state.imageData;
    let imageArray = this.state.imageArray;

    console.log("IMAGE" , e.target.files[0].name );
    if(e.target.files[0]?.name.indexOf(".mp4") !== -1){
      this.setState({video:"video"})
    }

    for (let i = 0; i < e.target.files.length; i++) {
      imageData.push(URL.createObjectURL(e.target.files[i]));
      imageArray.push(e.target.files[i]);
    }
    this.setState({
      imageData,
      imageArray,
    });
  };
  deleteImage = (index) => {
    let imageData = this.state.imageData;
    let imageArray = this.state.imageArray;
        imageArray.splice(index, 1);
        imageData.splice(index, 1);
    this.setState({
      imageData,
      imageArray,
    });
  };
  deleteImage2 = (index) => {
    let imageData2 = this.state.imageData2;
    // let imageArray = this.state.imageArray;
        // imageArray.splice(index, 1);
        imageData2.splice(index, 1);
    this.setState({
      imageData2,
      // imageArray,
    });
  };

  render() {
    console.log("Imagedata" , this.state.imageData);
    console.log("video" , this.state.video);
    return (
      <div className="">
        {this.state.isLoading ? (
          <Loader />
        ) : (
            <div className="card-body">
              {/* <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Banner Title
                  </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        id="title"
                        placeholder="Banner Title"
                        onChange={this.handleChange}
                        value={this.state.title}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Banner Detail
                  </label>
                    <div className="col-sm-9">
                      <textarea
                        // type="textArea"
                        className="form-control"
                        name="detail"
                        id="detail"
                        placeholder="Banner Detail"
                        onChange={this.handleChange}
                        value={this.state.detail}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Status</label>
                    <div className="col-sm-9">
                      <select
                        name="status"
                        className="form-control"
                        value={this.state.status}
                        onChange={this.handleChange}
                      >
                        <option value="AVAILABLE" name="AVAILABLE">
                          AVAILABLE
                      </option>
                        <option value="NOT_AVAILABLE" name="NOT_AVAILABLE">
                          NOT AVAILABLE
                      </option>
                        <option value="BUSY" name="BUSY">
                          BUSY
                      </option>
                      </select>
                    </div>
                  </div>
                </div>
               
              </div> */}
              


                <div className="row">
                <div className="col-md-12 UPLOAD_Container">
                          <div className="UPLOADED_FILES">
                            {this.state.imageData.length>0?this.state.imageData.map((image,index)=>(
                              <div>
                                { this.state.video !== "video"? 
                                <div style={{background :`url(${image}) no-repeat center/cover`}}>
                                  <div className="REMOVE" onClick={()=>this.deleteImage(index)}>
                                    Remove
                                  </div>
                                </div>
                                :
                                <div>
                                  <video  height="167" onClick={()=>this.deleteImage(index)} src={image}  autoplay={true} muted loop >
                                  <div  onClick={()=>this.deleteImage(index)}>
                                    Remove
                                  </div>
                                  </video>
                                </div>}
                            </div>
                            )):(<p>No Images Yet !</p>)}

                            {this.state.imageData2.length>0?this.state.imageData2.map((image2,index2)=>(
                              <div>
                                <div style={{background :`url(${Constant.getAPI()}${image2?.Medium?.url}) no-repeat center/cover`}}>
                                  <div className="REMOVE" onClick={()=>this.deleteImage2(index2)}>
                                    Remove
                                  </div>
                                </div>
                            </div>
                            )):null}
                            
                          </div>
                          <div className="UPLOAD_BTN">
                            <label htmlFor="imageInput">
                              <img src="/assets/gallery.svg" alt="" />
                              <span>Select Images</span>
                            </label>
                            <input 
                              type="file" 
                              id='imageInput'  
                              accept="image/png, image/gif, image/jpeg ,video/*" 
                              style={{display:"none"}}
                              onChange={this.ImagesToUpload}/>
                          </div>
                        </div>
                {/* <div className="col-md-6">
                  <div className="row">
                    <div className="col-sm-3">Display Image</div>
                    <div className="col-sm-9">
                      <form
                        id="bannerImage"
                        name="bannerImage"
                        encType="multipart/form-data"
                        className="text-capitalize"
                      >
                        <div className="form-group">
                          <input
                          onChange={this.handleChange}
                          id="banner_image"
                          type="url"
                          className="form-control"
                          autoComplete="off"
                          name="MediumId"
                          defaultValue={this.state.MediumId}
                        />
                          <input
                          accept="image/*"
                          onChange={this.handleImageUpload}
                          id="banner_image"
                          type="file"
                          className="form-control"
                          autoComplete="off"
                          name="files"
                          data-toggle="tooltip"
                          title="Click To Upload Banner Image"
                        />
                          <UploadImage id={"customFile"} uploadGalleryImage={this.uploadMedia.bind(this)} image={this.state.imageurl}></UploadImage>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-md-6 mb-2">
                  <div id="banner_image_label" className="pt-2">
                    {this.state.imageurl ? (
                      this.state.imageurl !== null ||
                        this.state.imageurl !== undefined ||
                        this.state.imageurl !== {} ? (
                          // <img
                          //   src={this.state.imageurl}
                          //   alt=""
                          //   className="img-100"
                          //   onError={(e) => {
                          //     e.target.src = "";
                          //   }}
                          // />
                          <img
                    src={`https://tenhil.auction/${this.state.imageurl} ` }
                    alt=""
                    className="img-100"
                    onError={this.imgLoadError}
                  />
                        ) : (
                          ""
                        )
                    ) : (
                        ""
                      )}
                  </div>
                </div> */}

               
              </div>
              {/* <div className="row">
              <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Select Post
                  </label>
                    <div className="col-sm-9">
                     
                      <form
                       className="text-capitalize"
                       id="postId"
                       name="podtId"
 
                      >
                        <div className="form-group">
                       <button onClick={this.handlePostAdd.bind(this)}>{this.state.postLabel!==""?"Change Post":"Select Post"}</button>
                       <i style={{color:"red"}}>{this.state.postLabel}</i>
                       </div>

                      </form>
                      
                    </div>
                  </div>
                </div>
                </div> */}
              


              <div className="card-footer">
                <div className="row float-right p-3">
                   
                  {this.state.isSaving ? (
                     
                    
                    <button className="btn btn-grd-disabled mr-2" disabled>
                      Saving...!
                    </button>
                  ) : (

                      <button
                        onClick={this.onSaveData}
                        className="btn btn-grd-disabled mr-2"
                      >
                        <i className="icofont icofont-save"></i> Save
                      </button>
                    )}
                  <Link to={"/banner"} className="btn btn-outline-dark">
                    Cancel
                </Link>
                </div>
              </div>
            </div>
          )}
          {/* {console.log(this.state.SelectPostmenu)} */}
          {/* {this.state.SelectPostmenu?<SelectPost onSelectPostId={this.handlePostId.bind(this)}/>:""} */}
          
      </div>
    );
  }
}
BannerAdd.propsTypes = {
  addBanner: PropTypes.func.isRequired,
  getBanner: PropTypes.func.isRequired,
  updateBanner: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,

};

var mapStateToProps = (state) => ({
  home: state.home,
});
export default connect(mapStateToProps, { addBanner, getBanner, updateBanner })(BannerAdd);

