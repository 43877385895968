import React from "react";
import { Link } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPost,deletePost,updatePostActive } from "../../actions/homeAction";
import Moment from "react-moment";
import Toggle from "react-toggle";
import "react-toggle/style.css"; 
import {POSTS_DUMMY_DATA} from '../UserMaster/Customer/DummyData'
import Constant from "../../Constant";

class AllPostList extends React.Component {
  state = {
    postList:[]
  };

  componentWillMount() {
    var data={
      bids:'', 
      price:'', 
      type:'Fixed', 
      status:'', 
      startRange:'', 
      recordLimit:'',
    }
    this.props.getPost(data);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ postList: nextProps.home.postList });
   
  }



  deletePost = (id) => {
    var data={
      postId:id
    }
 
    this.props.deletePost(data)
  };
  

  imgLoadError = (event) => {
    event.target.src = "./assets/images/icon.png";
  };
  handleStatusChange= (id,status) => {

    console.log("id", status)
    var data={
      postId:id,
      active:status
     }
    //  console.log(data)
     this.props.updatePostActive(data)
     
  }
  render() {
    console.log(this.state.postList);
    const columns = [
      {
        name: "Media",
        label: "Product Image",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (Media  , tableMeta) => {
            // console.log("Media",Media);
            return (
              <div>
                {Media.length>0?Media.map((media,key)=>(
                  key <2?
                  <img className="img-40" src={`${Constant.getAPI()}${media.url}`} alt="" />
                  :null
                ))
                :
                
                  <img
                    src={`/assets/img/xiomi.jpg`}
                    alt=""
                    className="img-40"
                    onError={this.imgLoadError}
                  />
                }
              </div>
            );
            
          },
        },
      },
      {
        name: "title",
        label: "Product Title",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (title)=> {
            return(<>
              <b>{title}</b>
            </>)
          }
        },
      },
      {
        name: "startDate",
        label: "Start Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (startDate)=>{
            return(<>
              <span>
                  {new Date(startDate).getDate()}/
                  {new Date(startDate).getMonth()+1}/
                  {new Date(startDate).getFullYear()}
              </span>
            </>)
          }
        },
      },
      
      {
        name: "price",
        label: "Price",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (price)=> {
            return(<>
              <b>{price} KWD</b>
            </>)
          }
        },
      },
      // {
      //   name: "about",
      //   label: "About",
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (about)=> {
      //       return(<>
      //         <span>{about}</span>
      //       </>)
      //     }
      //   },
      // },
      
      {
        name: "status",
        label: "Display Status",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: {
          filter: true,
          sort: true,
          
        },
      },
      {
        name: "Variants",
        label: "Variants",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (Variants,tableMeta)=> {
            return(<>
              <button className="btn btn-primary"><b>{Variants.length}</b></button>
            </>)
          }
        },
      },
      {
        name: "active",
        label: "Activity Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (active, tableMeta)=> {
            // console.log("tableMeat"  ,  tableMeta.rowData[8]);
            return(<>
              <Toggle
                // id={tableMeta.rowData[8]}
                defaultChecked={active === true ? true : false}
                value={active}
                onChange={this.handleStatusChange.bind(
                    this,
                    tableMeta.rowData[8], !active, "isActive"
                )}
              />
            </>)
          }
        },
      },
      {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
              <Link
                  to={"/post/add/"+ id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                > 
                  <i class="f-20 icofont icofont-eye text-custom"></i>
                </Link>
                <span
                  onClick={this.deletePost.bind(this, id)}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                >
                  <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                </span>
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Upcoming Posts Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>All Products</h4>
                    </div>
                  </div>
                  
                  {/* <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link> */}
                  <Link
                    to="/post/add"
                    className="col-2 btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Products{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card ">
                   
                    <div className="card-block">
                   
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          className="table-responsive"
                          data={this.state.postList}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AllPostList.propsTypes = {
  getPost: PropTypes.func.isRequired,
  deletePost:PropTypes.func.isRequired,
  updatePostActive:PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getPost,deletePost,updatePostActive })(AllPostList);
