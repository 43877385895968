import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import $ from "jquery";
import Constant from "../../Constant";
import ReactQuill from "react-quill";
import Loader from "../../Loader";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllCoupons, AddCoupon, UpdateCoupon } from "../../actions/homeAction";

export class CouponAddEdit extends Component {
    state = {
        name: '',
        couponCode: '',
        description: '',
        discountType: '',
        discountValue: null,
        minPurchaseAmount: null,
        validFrom: null,
        validTo: null,
        maxUsers: null,
        perUserLimit: null,
        status:'true',
        display: 'true',
        languageCode:'en'
      };

      componentDidMount(){
       if(this.props.match.params.couponId){
        var data={
          couponId:this.props.match.params.couponId
         }
        this.props.getAllCoupons(data);
       }
      }

      componentWillReceiveProps(nextProps){
        var allCouponsList = nextProps.home.allCoupons
         this.getCoupon(allCouponsList[0])
      }

      getCoupon=(coupon)=>{

        var validFrom = new Date(coupon?.validFrom).toJSON().slice(0,10)
        var validTo = new Date(coupon?.validTo).toJSON().slice(0,10)
        console.log(validFrom,validTo);

        this.setState({
          name:coupon?.name,
          couponCode:coupon?.couponCode,
          description:coupon?.description,
          discountType:coupon?.discountType,
          discountValue:coupon?.discountValue,
          minPurchaseAmount:coupon?.minPurchaseAmount,
          validFrom: validFrom,
          validTo: validTo,
          maxUsers:coupon?.maxUsers,
          perUserLimit:coupon?.perUserLimit,
          status:coupon?.status==true?'true':'false',
          display:coupon?.display==true?'true':'false',
          languageCode:'en'
        })
      }
     
      onHandleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
      };


      onSubmitData = (e) =>{
        e.preventDefault()

        var validFrom = new Date(this.state.validFrom).getTime()  
        var validTo = new Date(this.state.validTo).getTime()  
        
       if(this.props.match.params.couponId){
        var data = {
          couponId:this.props.match.params.couponId,
          name: this.state.name,
          couponCode:this.state.couponCode,
          description: this.state.description,
          discountType: this.state.discountType,
          discountValue: this.state.discountValue,
          minPurchaseAmount: this.state.minPurchaseAmount,
          validFrom: validFrom,
          validTo: validTo,
          maxUsers: this.state.maxUsers,
          perUserLimit: this.state.perUserLimit,
          status: this.state.status=='true'?true:false,
          display: this.state.display=='true'?true:false,
          languageCode:'en'
        }

       this.props.UpdateCoupon(data)
       }else{
        var data = {
          name: this.state.name,
          couponCode:this.state.couponCode,
          description: this.state.description,
          discountType: this.state.discountType,
          discountValue: this.state.discountValue,
          minPurchaseAmount: this.state.minPurchaseAmount,
          validFrom: validFrom,
          validTo: validTo,
          maxUsers: this.state.maxUsers,
          perUserLimit: this.state.perUserLimit,
          status: this.state.status=='true'?true:false,
          display: this.state.display=='true'?true:false,
          languageCode:'en'
        }

       this.props.AddCoupon(data)
       }
      }
    
         
    render() {
      console.log('CouponSelected',this.state.CouponSelected);
        return (
          <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-header">
                <div className="row align-items-end">
                  <div className="col-lg-8">
                    <div className="page-header-title">
                      <div className="d-inline">
                        <h4>Couopon Add</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="page-header-breadcrumb">
                      <ul className="breadcrumb-title">
                        <li className="breadcrumb-item">
                          <Link to="/">
                            <i className="feather icon-home"></i>{" "}
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/post/add">Couopon Add</Link>
                        </li>
                        <li className="breadcrumb-item active">Couopon Master</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-block">
                        <form onSubmit={this.onSubmitData} className="row">
                          <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='name' className="col-sm-4 col-form-label FONT_WEIGHT2">Name</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        id="name"
                                        placeholder="Coupon Name"
                                        value={this.state.name}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='couponCode' className="col-sm-4 col-form-label FONT_WEIGHT2">Coupon Code</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="couponCode"
                                        id="couponCode"
                                        placeholder="Coupon Name"
                                        value={this.state.couponCode}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='description' className="col-sm-2 col-form-label FONT_WEIGHT2">Description</label>
                                <div className="col-sm-10">
                                    <textarea
                                        rows={3}
                                        className="form-control"
                                        name="description"
                                        id="description"
                                        placeholder="Description"
                                        value={this.state.description}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                                 <div className="form-group row">
                                     <label htmlFor='discountType' className="col-sm-4 col-form-label FONT_WEIGHT2">Discount Type</label>
                                     <div className="col-sm-8">
                                         <select
                                              id='discountType'
                                             name="discountType" 
                                             className="form-control" 
                                             onChange={this.onHandleChange}
                                             value={this.state.discountType}
                                         >    <option value=""> - Select Discount Type - </option>
                                             <option value='amount' name="text">AMOUNT(KWD)</option>
                                             <option value='percent' name="color">PERCENTAGE(%)</option>
                                         </select>
                                     </div>
                                 </div>
                             </div>
                          <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='discountValue' className="col-sm-4 col-form-label FONT_WEIGHT2">Discount Value</label>
                                <div className="col-sm-8">
                                    <input
                                        type="number  "
                                        className="form-control"
                                        name="discountValue"
                                        placeholder='Discount Value'
                                        id="discountValue"
                                        value={this.state.discountValue}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='minPurchaseAmount' className="col-sm-4 col-form-label FONT_WEIGHT2">Min Purchase Amount</label>
                                <div className="col-sm-8">
                                    <input
                                        placeholder='Minimum Purchase Amount'
                                        type="number"
                                        className="form-control"
                                        name="minPurchaseAmount"
                                        id="minPurchaseAmount"
                                        value={this.state.minPurchaseAmount}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='validFrom' className="col-sm-4 col-form-label FONT_WEIGHT2">Valid From</label>
                                <div className="col-sm-8">
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="validFrom"
                                        id="validFrom"
                                        value={this.state.validFrom}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='validTo' className="col-sm-4 col-form-label FONT_WEIGHT2">Valid To</label>
                                <div className="col-sm-8">
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="validTo"
                                        id="validTo"
                                        value={this.state.validTo}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='maxUsers' className="col-sm-4 col-form-label FONT_WEIGHT2">Max Users</label>
                                <div className="col-sm-8">
                                     <input
                                      placeholder='Maximum Users'
                                        type="number"
                                        className="form-control"
                                        name="maxUsers"
                                        id="maxUsers"
                                        value={this.state.maxUsers}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='perUserLimit' className="col-sm-4 col-form-label FONT_WEIGHT2">Per User Limit</label>
                                <div className="col-sm-8">
                                     <input
                                      placeholder='Maximum Users'
                                        type="number"
                                        className="form-control"
                                        name="perUserLimit"
                                        id="perUserLimit"
                                        value={this.state.perUserLimit}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                                 <div className="form-group row">
                                     <label htmlFor='status' className="col-sm-4 col-form-label FONT_WEIGHT2">Status</label>
                                     <div className="col-sm-8">
                                         <select
                                              id='status'
                                             name="status" 
                                             className="form-control" 
                                             onChange={this.onHandleChange}
                                             value={this.state.status}
                                         > 
                                             <option value='true'>Active</option>
                                             <option value='false'>Inactive</option>
                                         </select>
                                     </div>
                                 </div>
                             </div>
                             <div className="col-md-6">
                                 <div className="form-group row">
                                     <label htmlFor='display' className="col-sm-4 col-form-label FONT_WEIGHT2">Display</label>
                                     <div className="col-sm-8">
                                         <select
                                              id='display'
                                             name="display" 
                                             className="form-control" 
                                             onChange={this.onHandleChange}
                                             value={this.state.display}
                                         > 
                                             <option value='true'>YES</option>
                                             <option value='false'>NONE</option>
                                         </select>
                                     </div>
                                 </div>
                             </div>
                           <div className="col-md-12 DATA_SAVE">
                              <div>
                                    <button type='submit' className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i>{this.props.match.params.couponId?'Update':'Save'}</button>
                              
                                <Link to={"/coupons"} className="btn btn-outline-dark">
                                  Cancel
                                </Link>
                              </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{
          }
        </div>
        )
    }
}

CouponAddEdit.propsTypes = {
    getAllCoupons: PropTypes.func.isRequired,
    UpdateCoupon: PropTypes.func.isRequired,
    AddCoupon: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
  };
  
  var mapStateToProps = (state) => ({
    home: state.home,
  });
  
  export default connect(mapStateToProps, {getAllCoupons, AddCoupon, UpdateCoupon })(CouponAddEdit);