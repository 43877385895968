import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {getOrdersList , getUsers ,upDateAddress } from "../../actions/homeAction";
// import { data } from 'jquery';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

class ViewOrder extends Component {
    state = {
      label:"",
      avenue:"",
      block:"",
      street:"",
      houseNo:"",
      floorNo:"",
      flatNo:"",
      AreaId:'',
      open:false,
    };

      onHandleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
      };

      onOpenModal = () => this.setState({open:true});
      onCloseModal = () => this.setState({open:false});

      componentDidMount() {
        var data ={
          // startRange:1,
          // count:20,
        }
        this.props.getOrdersList(data);
        var data2 = {

        }
        this.props.getUsers(data)
      }

      componentWillReceiveProps(nextProps){
        var OrderID = this.props.match.params.order_id
        var orders = nextProps.home.orderList
        var User = nextProps.home.users
        if(orders!==undefined){
          var single = orders.filter(data=>data.id===OrderID)[0]
          this.getSingleOrder(single, User)
        }
      }

      getSingleOrder = (single, User) => {
        console.log('single',single);
        var ORDER = single
        var address = single?.addressDetails
        
        var singleUser = User.filter(data=>data.id== ORDER.UserId)
        console.log("singleUser",ORDER);
        this.setState({
          ...ORDER,
          ...singleUser
        })
        this.setState({ ...address})
      }

      onSubmitData1 = (e)=>{
        e.preventDefault()

        var data = {
          AddressId:this.state.AddressId,
          label:this.state.label,
          avenue:this.state.avenue,
          block:this.state.block,
          street:this.state.street,
          houseNo:this.state.houseNo,
          floorNo:this.state.floorNo,
          flatNo:this.state.flatNo,
        }
        this.props.upDateAddress(data)
        this.onCloseModal()
      }

      onSaveData = () => {
        Swal.fire("Modified !", "Changes has been done", "success");
      }

      handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
      };
    render() {
      console.log('State',this.state);
        return (
            <div className="main-body">
            <div className="page-wrapper">
              <div className="page-header">
                <div className="row align-items-end">
                  <div className="col-lg-8">
                    <div className="page-header-title">
                      <div className="d-inline">
                        <h4>Order View</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="page-header-breadcrumb">
                      <ul className="breadcrumb-title">
                        {/* <li className="breadcrumb-item">
                          <Link to="/">
                            {" "}
                            <i className="feather icon-home"></i>{" "}
                          </Link>
                        </li> */}
                        <li className="breadcrumb-item">
                          <button className="btn btn-outline-dark pt-1 pb-1" onClick={()=>this.onOpenModal()}>Edit Address</button>
                        </li>
                        <li className="breadcrumb-item active">
                            Order View
                        </li>
                      </ul>
                    </div>
                  </div>
                 {this.state.addressDetails!==undefined? <div className="orderVIEW">
                    <div className="card-body">
                        <div className="row">
                          <div className="col-md-12" style={{display:'grid', gridTemplateColumns:'1fr 1fr'}}>

                          <div className="DETAILS" style={{  display:'flex',flexDirection:'column' }}>
                                <h6>Order ID : {this.state.id.slice(30,36 )}</h6>
                                <label className="d-inline-flex"><span>Address Type :</span> {this.state.addressDetails.label}</label>
                                <label className="d-inline-flex"><span>Area:</span> {this.state.addressDetails.Area.name + `/` + this.state.addressDetails.Area.name_ar}</label>
                                <label className="d-inline-flex"><span>Block :</span> {this.state.addressDetails.block}</label>
                                <label className="d-inline-flex"><span >Street :</span> {this.state.addressDetails.street}</label>
                                <label className="d-inline-flex"><span>Avenue :</span> {this.state.addressDetails.avenue}</label>
                                <label className="d-inline-flex"><span>House No :</span> {this.state.addressDetails.houseNo}</label>
                                <label className="d-inline-flex"><span>Floor No :</span> {this.state.addressDetails.floorNo}</label>
                                <label className="d-inline-flex"><span>Flat No :</span> {this.state.addressDetails.flatNo}</label>
                                {/* <label className="d-inline-flex"><span>Direction :</span> {this.state.addressDetails.label}</label> */}
                                {/* <label className="d-inline-flex"><span>City :</span>{this.state.addressDetails.label}</label> */}
                                {/* <label className="d-inline-flex"><span>Country :</span>{this.state.addressDetails.label}</label> */}
                                {/* <label className="d-inline-flex"><span>Phone :</span>+965 {this.state.addressDetails.label}</label> */}
                                {/* <label className="d-inline-flex"><span>Alternate Phone :</span>+965 {this.state.addressDetails.label}</label> */}
                            </div>
                                                
                            <div className="DETAILS" style={{  display:'flex',flexDirection:'column' }}>
                                 <h6>Invoice ID : {this.state.invoice_number}</h6>
                                <label className="d-inline-flex"><span>User Name :</span>{this.state[0]?.firstName} {this.state[0]?.lastName}</label>
                                <label className="d-inline-flex"><span>Email :</span>{this.state[0]?.email}</label>
                                <label className="d-inline-flex"><span>Mobile :</span>{this.state[0]?.mobileNumber}</label>
                                {/* <label className="d-inline-flex"><span>User Type :</span>Celebrity</label> */}
                            </div>
                          </div>
                          <div className="col-md-12" style={{marginBottom:'2rem'}}>
                              <h5 style={{
                                  marginBottom:'1rem',
                                  fontWeight:'bold',
                                  textAlign:'right',
                                  fontSize:'1.5rem',
                                  color:"#000",
                              }}>Order Summary</h5>
                              
                              <div className="order_summary">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>#.</th>
                                      <th>Product</th>
                                      <th>Description</th>
                                      <th>Price</th>
                                      <th>Qty</th>
                                      <th>Subtotal</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    {this.state.items.length>0?this.state.items.map((data,index)=>(
                                      <tr>
                                        <td className='product_index'>{index+1}</td>
                                        <td className='product_title'>
                                          <div className='table_product'>
                                            {/* <img src="/assets/img/apple.jpg" alt="" style={{width:'10vh'}} /> */}
                                            <span>{data.title}</span>
                                          </div>
                                        </td>
                                        <td className="product_description">
                                           <p> {data.about.slice(0,30)} ...</p>
                                        </td>
                                        <td className='product_price'>
                                         {data.price} KWD
                                        </td>
                                        <td className='product_qty'>
                                          x{data.selectedQuantity}
                                        </td>
                                        <td className='product_subtotal'>
                                        {data.totalPrice} KWD
                                        </td>
                                      </tr>
                                    )):null}

                                    <tr>
                                      <th colSpan='5' style={{ textAlign:'right',height:'50px', paddingRight:'1rem',}}>SUB TOTAL</th>
                                      <th >{this.state.subTotal} KWD</th>
                                    </tr>
                                    <tr>
                                      <th colSpan='5' style={{ textAlign:'right',height:'50px', paddingRight:'1rem',}}>Delivery Charges</th>
                                      <th >{this.state.deliveryCharge} KWD</th>
                                    </tr>
                                    <tr>
                                      <th colSpan='5' style={{ textAlign:'right',height:'50px', paddingRight:'1rem',}}>GRAND TOTAL</th>
                                      <th >{this.state.subTotal+this.state.deliveryCharge} KWD</th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                          </div>
                         
                          <div className="col-md-6">
                            <div className="DETAILS" style={{ display:'flex', flexDirection:'column' }}>
                                    <h6>Transaction Details</h6>
                                    <label className="d-inline-flex"><span>Transaction ID :</span><span style={{color:'black'}}>ACRAF23DB3C4</span></label>
                                    <label className="d-inline-flex"><span>Total Amount :</span><span style={{color:'black'}}>{this.state.subTotal+this.state.deliveryCharge} KWD</span></label>
                                    <label className="d-inline-flex"><span >Payment Method :</span> {this.state.paymentType}</label>
                                    <label className="d-inline-flex"><span >Payment Status :</span> <span style={{color:'green'}}> {this.state.paymentStatus}</span></label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group">
                              <span className="input-group-addon" id="basic-addon4">ORDER STATUS</span>
                              <select name="status" className="form-control" value={this.state.status} onChange={this.handleChange}
                                style={{
                                  color:`${(this.state.status=='placed'?'green':null)||(this.state.status=='process'?'#ff9a3c':null)||(this.state.status=='cancel'?'red':null)}`,
                                  fontWeight:'bold',
                                  textTransform:'uppercase'
                                }}
                              >
                                <option value="placed" name="placed" style={{color:'grey'}} >Placed</option>
                                <option value="process" name="process" style={{color:'grey'}} >In Process</option>
                                <option value="cancel" name="cancel" style={{color:'grey'}} >Cancel Order</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row float-right p-3">
                            {
                              this.state.isSaving
                                ?
                                <button className="btn btn-grd-disabled mr-2" disabled>Saving...!</button>
                                :
                                <button onClick={this.onSaveData}  className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> Save</button>
                            }
                            <Link to={"/orders"} className="btn btn-outline-dark">
                              Cancel
                            </Link>
                          </div>
                    </div>
                    <div className="card">
                                <Modal open={this.state.open} showCloseIcon={false} closeOnOverlayClick={false} center>
                                  <form onSubmit={this.onSubmitData1}>
                                    <div className='AddressModal d-flex flex-column'>
                                      <div className='form-group inputF '>
                                        <label  htmlFor='label'>Address Type :</label>
                                        <input type='text' id='label' className="form-control" placeholder='Address' name='label' value={this?.state?.label}  onChange={this.onHandleChange} />
                                      </div>
                                      <div className='form-group inputF'>
                                        <label  htmlFor='AreaId'>Area :</label>
                                        <input type='text' id='AreaId' className="form-control" placeholder='Address'  name='AreaId' value={this?.state?.addressDetails?.Area.name + "/" + this?.state?.addressDetails?.Area.name_ar}  onChange={this.onHandleChange} />
                                      </div>
                                      <div className='form-group inputF'>
                                        <label  htmlFor='block'>Block :</label>
                                        <input type='text' id='block' className="form-control" placeholder='Address'  name='block' value={this?.state?.block}  onChange={this.onHandleChange} />
                                      </div>
                                      <div className='form-group inputF'>
                                        <label  htmlFor='street'>Street :</label>
                                        <input type='text' id='street' className="form-control" placeholder='street' name='street' value={this?.state?.street}  onChange={this.onHandleChange} />
                                      </div>
                                      <div className='form-group inputF'>
                                        <label  htmlFor='avenue'>Avenue :</label>
                                        <input type='text' id='avenue' className="form-control" placeholder='Address' name='avenue' value={this?.state?.avenue}  onChange={this.onHandleChange} />
                                      </div>
                                      <div className='form-group inputF'>
                                        <label  htmlFor='houseNo'>House No :</label>
                                        <input type='text' id='houseNo' className="form-control" placeholder='Address' name='houseNo' value={this?.houseNo}  onChange={this.onHandleChange} />
                                      </div>
                                      <div className='form-group inputF'>
                                        <label  htmlFor='floorNo' >Floor No :</label>
                                        <input type='text' id='floorNo' className="form-control" placeholder='Address' name='floorNo' value={this?.floorNo}  onChange={this.onHandleChange} />
                                      </div>
                                      <div className='form-group inputF'>
                                        <label  htmlFor='flatNo'>Flat No  :</label>
                                        <input type='text' id='flatNo' className="form-control" placeholder='flatNo' name='flatNo' value={this?.state?.flatNo}  onChange={this.onHandleChange}  />
                                      </div>
                                    
                                    <div className='d-flex justify-content-end'>
                                      <button onClick={this.onSubmitData1}  className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> Save</button>
                                      <button  onClick={this.onCloseModal}> Cancel</button>
                                    </div>
                                    </div>
                                  </form>
                                </Modal>
                            </div>
                  </div>:null}
                </div>
              </div>
            </div>
          </div>
        )
    }
}


ViewOrder.propsTypes = {
  getOrdersList: PropTypes.func.isRequired,
  upDateAddress: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, {getOrdersList , getUsers , upDateAddress })(ViewOrder);