import React from "react";
import { Link } from "react-router-dom";

class Header extends React.Component {
  Signout = () => {
    localStorage.clear();
    window.location.href = "#/";
    window.location.reload();
  };

  render() {
    return (
      <nav className="navbar header-navbar pcoded-header noprint">
        <div className="navbar-wrapper">
          <div className="navbar-logo text-center">
            <a
              className="mobile-menu"
              id="mobile-collapse"
              href="javascript:void(0)"
            >
              <i className="feather icon-menu"></i>
            </a>
            <Link to="/" className="d-inline-flex">
                <img
                  src="./assets/icon/tenfil_logo.png"
                  // className="img-radius"
                  alt="User-Profile-Image"
                  style={{height:'30px', marginLeft:'40px'}}
                />
              <h3 className="text-white ml-2">tenfil</h3>
            </Link>
            <a className="mobile-options">
              <i className="feather icon-more-horizontal"></i>
            </a>
          </div>
          <div className="navbar-container container-fluid">
            <ul className="nav-right">
              {/* {
                localStorage.getItem('tenhil_ad_role') === "shop"
                  ?
                  <Link to={"/shops/add/" + localStorage.getItem('tenhil_ad_uid')}>
                    <li className="user-profile header-notification">
                      <img src="./assets/images/user.png" className="img-radius" alt="User-Profile-Image" />
                      <span>{localStorage.getItem("tenhil_ad_name")}</span>
                    </li>
                  </Link>
                  : */}
              <li className="user-profile header-notification">
                <img
                  src="./assets/images/user.png"
                  className="img-radius"
                  alt="User-Profile-Image"
                />
                <span>{localStorage.getItem("tenhil_ad_name")}</span>
              </li>
              {/* } */}
              <li>
                <button
                  className="btn bg-transparent"
                  onClick={this.Signout}
                  dataToggle="tooltip"
                  title="Logout"
                >
                  <i className="feather icon-log-out"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
