import React, { Component } from 'react'
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import { auctionWinnerList } from "../../actions/homeAction";

export class AuctionWinner extends Component {
    state = {};
    
    componentWillMount() {
        var data={
            PostId:this.props.match.params.id
        }
        this.props.auctionWinnerList(data);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ auctionWinnerList: nextProps.home.AuctionWinnerList});
        console.log( nextProps.home.AuctionWinnerList)
      }
  render() {
    const columns = [
        // {
        //   name: "PostMedia",
        //   label: "Winner",
        //   options: {
        //     filter: true,
        //     sort: true,
        //     customBodyRender: (PostMedia  , tableMeta) => {
        //       return (
        //         <div>
        //           { 
        //             <img
        //               src={  
        //                 PostMedia !== undefined && PostMedia !== null && PostMedia !== ""
        //                   ?PostMedia.map( (PostMedia)=>
        //                      `https://tenhil.auction/${PostMedia.url} `)
        //                   : "./assets/images/tenhil.png" 
        //               }
        //               src='/assets/img/winner.jpg'
        //               alt=""
        //               className="img-40"
        //               onError={this.imgLoadError}
        //             />
        //           }
        //         </div>
        //       );
              
        //     },
        //   },
        // },
        {
          name: "isWinner",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRender:(isWinner)=>{
              return <div>
              {isWinner?<p style={{color:'#42b883',fontWeight:'bold'}}>Winner</p>:<p style={{color:'#dc2f2f'}}>Lose</p>}
                  {/* <p>{isWinner?'WIN':"Loser"}</p> */}
              </div>
            }
          },
        },
        {
          name: "User.firstName",
          label: "First Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "User.lastName",
          label: "Last Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "User.email",
          label: "E-mail",
          options: {
            filter: true,
            sort: true,
          }
        },

        {
          name: "User.mobileNumber",
          label: "Mobile Number",
          options: {
            filter: true,
            sort: true,
          }
        },

        {
          name: "User.gender",
          label: "Gender",
          options: {
            filter: true,
            sort: true,
          }
        },        
        {
          name: "User.bids",
          label: "Bids",
          options: {
            filter: true,
            sort: true,
          }
        },        
        {
          name: "Price",
          label: "Price",
          options: {
            filter: true,
            sort: true,
          }
        },        
      ];
      const options = {
        filterType: "dropdown",
        viewColumns: false,
        print: false,
        download: false,
        selectableRows: "none",
        textLabels: {
          body: {
            noMatch: this.state.isSaving
              ? "Loading data..!"
              : "Sorry, No Records Found",
            toolTip: "Sort",
            columnHeaderTooltip: (column) => `Sort for ${column.label}`,
          },
        },
      };
    return (
        <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Auction Winner Leaderboard</h4>
                    </div>
                  </div>
                  
                  <Link
                    to="/ongoing-posts"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link>
                  {/* <Link
                    to="/gifts/add"
                    className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Winner{" "}
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          title={"All Participant"}
                          className="table-responsive"
                          data={this.state.auctionWinnerList}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AuctionWinner.propsTypes = {
    auctionWinnerList: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
    home: state.home,
});
  

export default connect(mapStateToProps, {auctionWinnerList})(AuctionWinner)