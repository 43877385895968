import React from "react";
import AdminDashboard from "./AdminDashboard";
import ShopAdminDashboard from "./ShopAdminDashboard";

class Dashboard extends React.Component {
  componentWillMount() {}

  render() {
    return <AdminDashboard />;
  }
}

export default Dashboard;
