import React from "react";
import "react-toggle/style.css"; // for ES6 modules
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getUsers , sendNotification } from "../../../actions/homeAction";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
// import {USER_DUMMY_DATA} from './DummyData'

class PushNotification extends React.Component {
  state = {
    language_data: [],
    open:false,
    type:"all",
    title:"",
    message:"",
    userIds:[]
  };

  onOpenModal = () => this.setState({open:true});
  onCloseModal = () => this.setState({open:false});

  componentWillMount() {
    this.props.getUsers();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ users: nextProps.home.users });
    console.log('USERS LIST',nextProps.home.users)
  }

  notify = (event) =>{
    console.log("EB",event.target.value);
    this.setState({type: event.target.value})
  }

  handleChange = (e)=>{
    this.setState({ [e.target.name]: e.target.value });
  }

  setUserID =(e)=>{
    console.log( "ID" , e.target.value);
    var userID = e.target.value
    if(!this.state.userIds.includes(userID)){
      this.state.userIds.push(userID)
    }else{
      var filter = this.state.userIds.filter(data=>data!== userID)
        this.setState({userIds:filter})
    }
    // var userID = e.target.value
    // this.state.userIds.push(userID)
  }

  onSaveMes = (e)=> {
    e.preventDefault()
   if(this.state.type==="all"){
    var data ={
      title:this.state.title,
      message:this.state.message,
      userIds:[],
    }
    this.props.sendNotification(data)
    this.setState({
      title:"",
      message:'',
      open: false
    })

    console.log("SEND", data);
   }else{
    var data ={
      title:this.state.title,
      message:this.state.message,
      userIds:this.state.userIds,
    }
    console.log("SEND", data);
    this.props.sendNotification(data)
    this.setState({
      title:"",
      message:'',
      open: false
    })
   }
  }

  render() {
    console.log("SELECTED" , this.state.userIds);
    
    const columns = [
      // {
      //   name: "Medium",
      //   label: "User image",
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (PostMedia  , tableMeta) => {
      //       return (
      //         <div>
      //           {console.log(PostMedia)}
      //           { 
      //             <img
      //               // src={  
      //               //   PostMedia !== undefined && PostMedia !== null && PostMedia !== ""
      //               //     ?PostMedia.map( (PostMedia)=>
      //               //        `https://tenhil.auction/${PostMedia.url} `)
      //               //     : "./assets/images/tenhil.png" 
      //               // }
      //               src="/assets/img/cyber.jpg"
      //               alt=""
      //               className="img-40"
      //               onError={this.imgLoadError}
      //             />
      //           }
      //         </div>
      //       );
            
      //     },
      //   },
      // },
      {
        name: "firstName",
        label: "First Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "mobileNumber",
        label: "Mobile Number",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "email",
        label: "E-mail",
        options: {
          filter: true,
          sort: true,
        },
      },
     
    //   {
    //     name: "gender",
    //     label: "Gender",
    //     options: {
    //       filter: true,
    //       sort: true,
    //     },
    //   },

    //   {
    //     name: "registrationType",
    //     label: "User Type",
    //     options: {
    //       filter: true,
    //       sort: true,
    //     },
    //   },
    //   {
    //     name: "bids",
    //     label: "Bids",
    //     options: {
    //       filter: true,
    //       sort: true,
    //     },
    //   },
      {
        name: "id",
        label: "Select User",
        options: {
          filter: true,
          sort: true,
          display: this.state.type=="all"? false : true , 
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Select</span>
          },
          customBodyRender: (id) => {
            // console.log("DD", id);
            return (
              <div>
                    <input className='SelectBOX' type="checkbox" value={id} onChange={this.setUserID}/>
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Customers Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Push Notification</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-block">
                  <div className="Header row  d-flex justify-content-end ">
                    <div className='row mr-5'>
                    <select name="" id=""  onChange={this.notify}>
                        <option value="all">All Users</option>
                        <option value="selected">Selected Users</option>
                    </select>
                    <button className="ml-3 btn btn-dark" onClick={this.onOpenModal} >
                        MESSAGE
                    </button>
                    </div>
                </div>
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"User List"}
                        className="table-responsive"
                        data={this.state.users}
                        columns={columns}
                        options={options}
                      />
                    </div>
                    <Modal open={this.state.open} onClose={this.onCloseModal} closeOnOverlayClick={false} showCloseIcon={false} center>
                    <div className="messageModal col-sm-12 d-flex flex-column " style={{width:"25vw " }} >
                        <h4>Enter Message Here!</h4>
                        <input className="p-1"  type="text" placeholder='Title' name='title' value={this.state.title} onChange={this.handleChange}/>
                        <textarea rows={7} type="text" placeholder='Message' name='message'  className="p-1 mt-3"  value={this.state.message} onChange={this.handleChange}  />
                        <div className='btn d-flex justify-content-end mt-2'>
                            <button type="submit" className='send btn btn-success' onClick={this.onSaveMes} >Send</button>
                            <button  className='cancel btn btn-danger ml-3' onClick={this.onCloseModal}>Cancel</button>
                        </div>
                    </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PushNotification.propsTypes = {
  getUsers: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  sendNotification: PropTypes.func.isRequired
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getUsers , sendNotification })(PushNotification);
