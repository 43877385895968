import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import {addAttribute, updateAttribute} from '../../actions/homeAction'
import {getAttributes} from '../../actions/attributuesAction'

export class AddAttribute extends Component {
    state = {
        attributeName:'',
        attributeOptions:[
            {
                valueType:'',
                value:'',
                hexCode:'',
            },
        ],

    }

    componentDidMount() {
      if(this.props.match.params.attribute_id){
      this.props.getAttributes({languageCode:"en"})
    }}

    componentWillReceiveProps(nextProps){
      console.log('Props',nextProps.home.attributes_list);
      var attr = nextProps.home.attributes_list
      var att_id = this.props.match.params.attribute_id
      var single = attr.filter(data=>data.id===att_id)[0]
      this.getSingleAttibute(single)
    }
    
    getSingleAttibute = (single) => {
      console.log('single',single);
      var ATTRIBUTE = single

      var options = ATTRIBUTE.AttributeOptions.map(data=>{
        return {
          id:data.id,
          value:data.value,
          valueType:data.valueType,
          hexCode:data.hexCode,
        }
      })

      this.setState({
        attributeName:ATTRIBUTE.name,
        attributeOptions:options,
      })
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };


    optionHandleChange = (e,index) => {
       const updatedOption = this.state.attributeOptions.map((data,i)=> index==i?Object.assign(data,{[e.target.name]:e.target.value}):data)
       this.setState({attributeOptions:updatedOption})
    } 


    onAddOption = () => {
        var options = { valueType:'', value:'',hexCode:'', }
        this.setState({attributeOptions:[...this.state.attributeOptions,options]})
    }

    removeOptions = (index) => {
        const filteredOptions = [...this.state.attributeOptions]

          filteredOptions.splice(index,1)
          
        this.setState({attributeOptions:filteredOptions})
    }

    onSaveData = (e) => {
      e.preventDefault()
      if(this.props.match.params.attribute_id){
        var attData = {
          attributeId:this.props.match.params.attribute_id,
          languageCode:'en',
          attributeName:this.state.attributeName,
          attributeOptions:this.state.attributeOptions,
        }
       
        this.props.updateAttribute(attData)
      }else{
        var attData = {
          languageCode:'en',
          attributeName:this.state.attributeName,
          attributeOptions:this.state.attributeOptions,
        }
       
        this.props.addAttribute(attData)
      }
    } 

  render() {
    console.log('OPTIONS',this.state.attributeOptions);
    return <div className="pcoded-inner-content">
    <div className="main-body">
      <div className="page-wrapper">
        <div className="page-header">
          <div className="row align-items-end">
            <div className="col-lg-8">
              <div className="page-header-title">
                <div className="d-inline">
                  <h4>Attribute Add</h4>
                </div>
              </div>
             
            </div>
            <div className="col-lg-4">
              <div className="page-header-breadcrumb">
                <ul className="breadcrumb-title">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="feather icon-home"></i>{" "}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/attribute-add">Attribute Add</Link>
                  </li>
                  <li className="breadcrumb-item active">Attribute Master</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-block">
                  <form onSubmit={(e)=>this.onSaveData(e)} className="row">
                    <div className="col-md-12">
                        <div className="form-group row">
                            <label htmlFor='attributeName' className="col-sm-2 col-form-label FONT_SIZE">Attribute Name</label>
                            <div className="col-sm-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="attributeName"
                                    id="attributeName"
                                    placeholder="Attribute Name"
                                    onChange={this.handleChange}
                                    value={this.state.attributeName}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button onClick={this.onAddOption}
                        className="btn btn-sm btn-inverse waves-effect waves-light f-left d-inline-block md-trigger"
                        >
                        <i className="icofont icofont-plus m-r-5"></i>Add Option{" "}
                        </button>
                    </div>
                    <div className="col-md-12 row">
                        {this.state.attributeOptions.map((data,index)=>{
                           return  <div className="ATTRIBUTE_OPTIONS row" key={index}>
                           <div className="col-md-6">
                               <div className="form-group row">
                                   <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT"> Attribute Value</label>
                                   <div className="col-sm-8">
                                       <input
                                           type="text"
                                           className="form-control"
                                           name="value"
                                           id="value"
                                           placeholder="Attibute Value"
                                           onChange={e=> this.optionHandleChange(e,index)}
                                           value={data.value}
                                           required
                                       />
                                   </div>
                               </div>
                           </div>
                           <div className="col-md-6">
                               <div className="form-group row">
                                   <label htmlFor='valueType' className="col-sm-4 col-form-label FONT_WEIGHT">Value Type</label>
                                   <div className="col-sm-8">
                                       <select 
                                           name="valueType" 
                                           className="form-control" 
                                           onChange={e=> this.optionHandleChange(e,index)}
                                           value={data.valueType}
                                           
                                       >
                                           <option value="" name="select" >- Select Type -</option>
                                           <option value="text" name="text" >Text</option>
                                           <option value="color" name="color" >Color</option>
                                       </select>
                                   </div>
                               </div>
                           </div>
                       
                           <div className="col-md-6">
                               {data.valueType=='color'?(<div className="form-group row">
                                   <label htmlFor='hexCode'className="col-sm-4 col-form-label FONT_WEIGHT">Hex Code</label>
                                   <div className="col-sm-8">
                                       <input
                                           type="text"
                                           className="form-control"
                                           name="hexCode"
                                           id="hexCode"
                                           placeholder="Hex Code"
                                           onChange={e=> this.optionHandleChange(e,index)}
                                           value={data.hexCode}
                                           required
                                       />
                                   </div>
                               </div>):null}
                           </div>
                           <div className="col-md-6 ATT_REM_BTN">
                                <span onClick={()=> this.removeOptions(index)}>Remove</span>
                            </div>
                       </div>
                        })}
                        
                    </div>
                    <div className="col-md-12 DATA_SAVE">
                        <div>
                              <button type='submit' className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> Save</button>
                         
                          <Link to={"/"} className="btn btn-outline-dark">
                            Cancel
                          </Link>
                        </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
  }
}

AddAttribute.propsTypes = {
 
  addAttribute: PropTypes.func.isRequired,
  updateAttribute: PropTypes.func.isRequired,
  getAttributes: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  
};

var mapStateToProps = (state) => ({
  home: state.home,
});
export default connect(mapStateToProps, {

  addAttribute,
  getAttributes,
  updateAttribute,
  })(AddAttribute);