import React, { Component } from 'react'
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {AboutUsAdd, AboutUsGet } from "../../actions/homeAction";

export class AboutUsAddEdit extends Component {
    
    state = {
        aboutUs:null,
        aboutUs_ar:null,
    }

    componentDidMount(){
        this.props.AboutUsGet()
    }

    componentWillReceiveProps(nextProps){
        this.setState({ aboutUs: nextProps.home.aboutUs.aboutUs,
            aboutUs_ar:nextProps.home.aboutUs.aboutUs_ar
        });
        console.log( nextProps.home.aboutUs)

    }

    handleChangeEng = (value) => {
        this.setState({ aboutUs: value });
      };

    handleChangeAr = (value) => {
    this.setState({ aboutUs_ar: value });
    };


    onSubmit=()=>{
        var data = {
            aboutUs:this.state.aboutUs,
            aboutUs_ar:this.state.aboutUs_ar,
        }
        this.props.AboutUsAdd(data)
    }
    
  render() {
      console.log('state',this.state.aboutUs);
    return (
        <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>About Us</h4>
                    </div>
                  </div>
                  
                  <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link>

                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                    <div className="row BLOCKS">
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='aboutUs' className="col-sm-2 col-form-label FONT_WEIGHT2">About (En)</label>
                                <div className="col-sm-10">
                                    <ReactQuill
                                        value={this.state.aboutUs}
                                        onChange={this.handleChangeEng}
                                        className='quill'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label htmlFor='aboutUs' className="col-sm-2 col-form-label FONT_WEIGHT2">About (Ar)</label>
                                <div className="col-sm-10">
                                    <ReactQuill
                                        value={this.state.aboutUs_ar}
                                        onChange={this.handleChangeAr}
                                        className='quill'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 DATA_SAVE">
                            <div>
                                <button className="btn btn-grd-disabled mr-2"
                                    onClick={this.onSubmit}
                                >
                                    <i className="icofont icofont-save"></i> Save</button>
                                
                                <Link to={"/"} className="btn btn-outline-dark">
                                    Cancel
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


AboutUsAddEdit.propsTypes = {
    AboutUsAdd:PropTypes.func.isRequired,
    AboutUsGet:PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
  };
  
  var mapStateToProps = (state) => ({
      home: state.home,
  });
  
export default connect(mapStateToProps, {AboutUsAdd,AboutUsGet  })(AboutUsAddEdit);