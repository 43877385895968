import React from "react";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCategory,deleteCategory } from "../../actions/homeAction";

class SubCategoryList extends React.Component {
  state = {
    parent_id:""
  };

  componentDidMount() {
    var data={
      SuperCategoryId:this.props.match.params.child_id
    }
    this.props.getCategory(data);
    console.log(this.props.match.params.child_id)
    //console.log(this.props.match.params.category_id)

  }

  componentWillReceiveProps(nextProps) {

    this.setState({ 
      categoryList: nextProps.home.categoryList,
      // parent_id: this.props.match.params.category_id
    });
  }

  deleteCategory = (id) => {
    var data={
      CategoryId:id
    }
    this.props.deleteCategory(data)
  };
  
  imgLoadError = (event) => {
    event.target.src = "./assets/images/icon.png";
  };
  render() {
    const columns = [
      {
        name: "Medium",
        label: " Category Image",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (Medium, tableMeta) => {
            return (
              <div>
                {
                  <img
                    src={
                      Medium !== undefined && Medium !== null && Medium !== ""
                        ?`https://tenhil.auction/${Medium.url} `
                        : "./assets/images/tenhil.png"
                    }
                    alt=""
                    className="img-40"
                    onError={this.imgLoadError}
                  />
                }
              </div>
            );
          },
        },
      },
      {
        name: "name",
        label: "Name:English",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "name_ar",
        label: "Name:Arabic",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
                   {/* <Link
                  to={"/subcategory/"+ id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                > {console.log(id)}
                  <i class="f-20 icofont icofont-eye text-custom"></i>
                </Link> */}
                
                <Link
                  to={"/childCategory/" + id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                >{console.log(id)}
                  <i className="f-20 icofont icofont-ui-edit text-custom"></i>
                </Link>
                <span
                  onClick={this.deleteCategory.bind(this, id)}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                >
                  <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                </span>
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Sub Category Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Sub Category</h4>
                    </div>
                  </div>
                  {/* {localStorage.getItem('tenhil_adad_role') !== "shop"
                    ? */}
                  <Link
                    to={"/subCategory/add/"+this.props.match.params.child_id}
                    className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Sub Category{" "}
                  </Link>
                  {/* :
                  } */}
                  <Link
                    to="/category"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          title={"Sub Category List"}
                          className="table-responsive"
                          data={this.state.categoryList}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SubCategoryList.propsTypes = {
  deleteCategory:PropTypes.func.isRequired,
  getSubCategory: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { 
  getCategory, 
  deleteCategory
 })(SubCategoryList);
