import React from "react";
import { Link } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getBids } from "../../../actions/homeAction";
import Moment from "react-moment";

class BidsList extends React.Component {
  state = {};

  componentWillMount() {
     console.log(this.props.match.params.post_id)
     var data={
      PostId:this.props.match.params.post_id,
     
   }
    this.props.getBids(data);

  }

  componentWillReceiveProps(nextProps) {
    this.setState({ BidsList: nextProps.home.bidsList});
    console.log(nextProps.home.bidsList)
  }
 

  imgLoadError = (event) => {
    event.target.src = "./assets/images/icon.png";
  };
  render() {
    const columns = [
      {
        name: "User.firstName",
        label: "Firstname",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "User.lastName",
        label: "Lastname",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "User.email",
        label: "Email",
        options: {
          filter: true,
          sort: true,
        },
      },
      
      {
        name: "Price",
        label: "Bid Price",
        options: {
          filter: true,
          sort: true,
        },
      },
        {
        name: "updatedAt",
        label: "Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (date, tableMeta) => {        
                  return (
                    <div>
                    <Moment format="DD/MM/YYYY">{date}</Moment>
                    </div>
                  )
                }
        },
      },
      {
        name: "updatedAt",
        label: "Time",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (date, tableMeta) => {        
                  return (
                    <div>
                     <Moment format="HH:mm:ss">{date}</Moment>
                    </div>
                  )
                }
        },
      },
      {
        name: "isWinner",
        label: "--",
        options: {
          filter: true,
          sort: true,
          display:false,
        },
      },
    

      {
        name: "isWinner",
        label: "Bidder Status",
        options: {
          customBodyRender: (isWinner, tableMeta) => {
            return (
              <div>
                
                { isWinner?<div>
                  <Link> 
                  <i class="f-20 ">Winner</i>
                
                </Link>
                </div>
                :<i class="f-15 ">Refund</i>
          }
              </div>
            )
          }
       },
      },
    
      {
        name: "id",
        label: "Refund",
        options: {
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
                  <Link
                  
                  to={""+ id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                > {console.log(id)}
                  <i class="f-20 icofont icofont-retweet text-custom"></i>
                </Link>

              </div>
            )
          }
          
        },
      },
       {
        name: "id",
        label: "User Address",
        options: {
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
                  <Link                 
                  to={"/CustomerAddressList/"+ id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                > {console.log(id)}
                  <i class="f-20 icofont icofont-table text-custom"></i>
                </Link>
              </div>
            )
          }
          
        },
      },
    ];


    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Bids Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
      setRowProps:(row)=>{
        if(this.state.BidsList !== undefined){
          console.log(row)
          if(row[6]== true)
          {
            return {
             
              style: { backgroundColor: "lightgreen" },
            };
          }
        }
     }
      
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Bids List</h4>
                    </div>
                  </div>
                
                  <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="dt-responsive table-responsive">
                      <MUIDataTable
                          title={"Bids List"}
                          className="table-responsive"
                          data={this.state.BidsList}
                          columns={columns}
                          options={options}
                        />
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BidsList.propsTypes = {
  getBids: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, {  getBids })(BidsList);
