import React from "react";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types"
import Loader from "../../Loader";
import {
      addPayment_type,
      updatePayment_type,
      getpayment_type
} from "../../actions/homeAction"

class Payment_typeAdd extends React.Component {
  state = {
    
  };
  componentDidMount(){
   
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
        payment_type: nextProps.home. payment_type
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.payment_type_id !== this.props.payment_type_id) {
        this.setState({ payment_type_id: this.props.payment_type_id });
        this.getpayment_typeDetails();
      }
      if (prevProps.language_id !== this.props.language_id) {
        if (this.props.payment_type_id !== undefined) {
          this.setState({ payment_type_id: this.props.payment_type_id });
          this.props.getpayment_type();
        }
        this.getpayment_typeDetails();
      }
 }
  
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value });
  };

  getpayment_typeDetails = () => {
   var type=this.state. payment_type

   if(type !== undefined){
  for(let i=0;i<type.length;i++)
  {    console.log(this.props.payment_type_id)

    if(type[i].id ==  this.props.payment_type_id ){
        console.log("match")
       this.fillInputs(type[i])

       }
  } }
  };

  fillInputs(type){
      console.log("now")
   this.setState({
    name:type.name
   })
   console.log(type.name)
  }

  onSaveData = () => {
    var that = this;
    that.setState({ isSaving: true });
  if (that.props.payment_type_id !== undefined) {
      that.updatePayment_typeData();
     }
    else {
         that.addPayment_type();}
   };
 
  updatePayment_typeData = () => {
    this.setState({ isSaving: true });
    var data={
      name:this.state.name,
      PaymentTypeId:this.props.payment_type_id
    }
    
    this.props.updatePayment_type(data)
  };
 
  addPayment_type = () => {
    var data={
        name:this.state.name
    }
    this.props.addPayment_type(data)
    console.log(data)
  };
  
  componentDidMount() {
    this.loadScript(
      process.env.PUBLIC_URL + "/assets/pages/filer/jquery.fileuploads.init.js"
    );

  }
  loadScript(src) {
    let script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    document.head.append(script);
  };

  render() {
    return (
      <div className="">
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="card-body">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label"> Name</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="name"
                      onChange={this.handleChange}
                      value={this.state.name}
                    />
                  </div>
                </div>
              </div>
             </div>
           <div className="card-footer">
              <div className="row float-right p-3">
                {this.state.isSaving ? (
                  <button className="btn btn-grd-disabled mr-2" disabled>
                    Saving...!
                  </button>
                ) : (
                  <button
                    onClick={this.onSaveData}
                    className="btn btn-grd-disabled mr-2"
                  >
                    <i className="icofont icofont-save"></i> Save
                  </button>
                )}
                <Link to={"/payment_type"} className="btn btn-outline-dark">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
Payment_typeAdd.propsTypes = {
  addPayment_type: PropTypes.func.isRequired,
  getpayment_type: PropTypes.func.isRequired,   
  updatePayment_type:PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, {
    addPayment_type,
  getpayment_type,updatePayment_type
  })(Payment_typeAdd);
