import React from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {registerUser} from "../../actions/homeAction"
import UploadImage from "../utils/UploadImage";


class CelebrityAdd extends React.Component {
  state = {};
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addceleb = () => {
    var data
    data={
      payload:{
        email:this.state.Email,
        password:this.state.Password,
        userName:this.state.UserName,
        mobileNumber:this.state.Mobile,
        userType:"celebrity",
        deviceName:this.state.deviceName,
        registrationType:"",
        firstName:this.state.first_name,
        lastName:this.state.last_name,
        MediumId:this.state.image,

      }
    }
    
    this.props.registerUser(data)
  };
  componentWillMount() {
    this.loadScript(process.env.PUBLIC_URL + "/assets/pages/filer/jquery.fileuploads.init.js");
  }
  loadScript(src) {
    let script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    document.head.append(script);
  }
  

  uploadImage = (image) => {
    this.setState(
      {
        image:image,
        imagelabel:"FILE UPLOADED"
      })
    console.log(this.state.image)
  };
  
  render() {
    return (
      <div className="">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">First Name</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    id="first_name"
                    placeholder="First Name"
                    onChange={this.handleChange}
                    value={this.state.first_name}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Last Name</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="last_name"
                    id="last_name"
                    placeholder="Last Name"
                    onChange={this.handleChange}
                    value={this.state.last_name}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Email</label>
                <div className="col-sm-9">
                  <input
                    type="Email"
                    className="form-control"
                    name="Email"
                    id="Email"
                    placeholder="Email"
                    onChange={this.handleChange}
                    value={this.state.Email}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Password</label>
                <div className="col-sm-9">
                  <input
                    type="Password"
                    className="form-control"
                    name="Password"
                    id="Password"
                    placeholder="Password"
                    onChange={this.handleChange}
                    value={this.state.Password}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Mobile</label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="Mobile"
                    id="Mobile"
                    placeholder="Mobile"
                    onChange={this.handleChange}
                    value={this.state.Mobile}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">UserName</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="UserName"
                    id="UserName"
                    placeholder="UserName"
                    onChange={this.handleChange}
                    value={this.state.UserName}
                  />
                </div>
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="input-group">
                <span className="input-group-addon" id="basic-addon4">Device</span>
                <select name="status" className="form-control" value={this.state.deviceName} onChange={this.handleChange}>
                  <option value="Android" name="active">Android</option>
                  <option value="IOS" name="inactive">IOS</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-group">
                <span className="input-group-addon" id="basic-addon4">User Type</span>
                <select name="status" className="form-control" value={this.state.device} onChange={this.handleChange}>
                  <option value="Celebrity" name="active">Celebrity</option>
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Image</label>
                <UploadImage id={"customFile"} uploadGalleryImage={this.uploadImage}></UploadImage>
                <label class="custom-file-label" for="customFile">
                    {this.state.label}
                    {console.log(this.state.image)}
                  </label>
              </div>
            </div>
            
            <div className="col-md-8 ">
                <div id="id_image_section_label" className="pt-2">
                  { this.state.image ?
                    this.state.image !== undefined 
                     ? (
                      <img
                        src={`https://tenhil.auction/${this.state.image}`}
                        alt=  ""
                        className="img-100"
                        onError={(e) => {
                          e.target.src = "";
                        }}
                      />
                    ) : (
                      ""
                    
                  ) : (
                    ""
                  )}
                </div>
              </div>
            
            </div>
          

          <div className="row float-right p-3">
            {
              this.state.isSaving
                ?
                <button className="btn btn-grd-disabled mr-2" disabled>Saving...!</button>
                :
                <button onClick={this.addceleb}  className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> Save</button>
            }
            <Link to={"/celebrity"} className="btn btn-outline-dark">
              Cancel
        </Link>
          </div>
        </div>
      </div >

    );
  }
}


CelebrityAdd.propsTypes = {
  registerUser:PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect( mapStateToProps, {registerUser})(CelebrityAdd);
