import React from "react";
import { Link } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPost } from "../../actions/homeAction";
import Moment from "react-moment";

class SelectPost extends React.Component {
  state = {};

  handleId(id){
    this.props.onSelectPostId(id)
  }
  componentWillMount() {
    var data={
      
    }
    this.props.getPost(data);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ postList: nextProps.home.postList });
  }

 
  

  imgLoadError = (event) => {
    event.target.src = "./assets/images/icon.png";
  };
  render() {
    const columns = [
      {
        name: "PostMedia",
        label: "Post Image",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (PostMedia  , tableMeta) => {
            return (
              <div>
                {console.log(PostMedia)}
                { 
                  <img
                    src={  
                      PostMedia !== undefined && PostMedia !== null && PostMedia !== ""
                        ?PostMedia.map( (PostMedia)=>
                           `https://tenhil.auction/${PostMedia.url} `)
                        : "./assets/images/tenhil.png" 
                    }
                    
                    alt=""
                    className="img-40"
                    onError={this.imgLoadError}
                  />
                }
              </div>
            );
            
          },
        },
      },
      {
        name: "title",
        label: "Post Title",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "User.firstName",
        label: "User Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      
      {
        name: "startDate",
        label: "Start Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (startDate, tableMeta) => {
            return (
              <div>
                <Moment format="DD/MM/YYYY">{startDate}</Moment>
              </div>
            );
          },
        },
      },
      {
        name: "endDate",
        label: "End Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (startDate, tableMeta) => {
            return (
              <div>
                <Moment format="DD/MM/YYYY">{startDate}</Moment>
              </div>
            );
          },
        },
      },
      {
        name: "startingPrice",
        label: "Starting Price",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
              
              <Link
                  onClick={this.handleId.bind(this,id)}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                > 
                  <i class="f-20 icofont icofont-eye text-custom"></i>
                </Link>
                
                
              </div>
            );
          },
        },
      },
     

      
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Posts Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4> Select Post</h4>
                    </div>
                  </div>
                  {/* {localStorage.getItem('tenhil_adad_role') !== "shop"
                    ? */}
                  {/* <Link
                    to="/posts/add"
                    className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Post{" "}
                  </Link> */}
                  {/* :
                  } */}
                  {/* <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          title={"Post List"}
                          className="table-responsive"
                          data={this.state.postList}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SelectPost.propsTypes = {
  getPost: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getPost })(SelectPost);
