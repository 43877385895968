import React from "react";
import { Link } from "react-router-dom";
import Constant from "../../Constant";
import Swal from "sweetalert2";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import moment from "moment";
import{getSingleAttribute} from '../../actions/attributuesAction'
class AttributeValueList extends React.Component {
  state = {
     
  };
  deleteAttributeValue = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this !",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        var that = this;
        var data = {
          attributeValueId: id,
        };
        // this.setState({ isSaving: true });
        fetch(Constant.getAPI() + "/attributeValues/delete", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tenhil_ad_auth")}`,
          },
          body: JSON.stringify(data),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            if (json.status === true) {
              Swal.fire("Deleted!", "Attribute Value deleted.", "success");
              that.getAttributeValueList();
            } else {
              Swal.fire({
                title: "Something went wrong. Try again after some Time.!",
                icon: "error",
                text: "",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };
 
  componentDidMount() {
   if(!this.props.list && this.props.match.params && this.props.match.params.attribute_id){
     this.props.getSingleAttribute(false,{attributeId:this.props.match.params.attribute_id})
   }
  }
 
  render() {
    const columns = [
  
      {
        name: "value",
        label: "Attribute Value",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "valueType",
        label: "Attribute Value",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name:"hexCode",
        label:"HexCode"
      },
 

      // {
      //   name: "value_ar",
      //   label: "Attribute Value : Arabic",
      //   options: {
      //     filter: true,
      //     sort: true,
      //   },
      // },
      // {
      //   name: "id",
      //   label: "Action",
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (id, tableMeta) => {
      //       return (
      //         <div>
      //           <Link
      //             to={
      //               "/attribute-values/" +
      //               this.props.match.params.attribute_id +
      //               "/add/" +
      //               id
      //             }
      //             className="m-r-15 text-muted"
      //             data-toggle="tooltip"
      //             data-placement="top"
      //             title=""
      //             data-original-title="Edit"
      //           >
      //             <i className="f-20 icofont icofont-ui-edit text-custom"></i>
      //           </Link>
      //           <span
      //             onClick={this.deleteAttributeValue.bind(this, id)}
      //             className="m-r-15 text-muted"
      //             data-toggle="tooltip"
      //             data-placement="top"
      //             title=""
      //             data-original-title="Delete"
      //           >
      //             <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
      //           </span>
      //         </div>
      //       );
      //     },
      //   },
      // },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Attribute Value Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Attribute Value List</h4>
                    </div>
                  </div>
                  {/* <Link
                    to={
                      "/attribute-values/" +
                      this.props.match.params.attribute_id +
                      "/add"
                    }
                    className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Attribute
                    Value{" "}
                  </Link> */}
                </div>
                <div className="col-lg-4">
                  <div className="page-header-breadcrumb">
                    <ul className="breadcrumb-title">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="feather icon-home"></i>{" "}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to={"/attributes"}>Attributes </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Attribute Value List
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          className="table-responsive"
                          data={this.props.list}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          console.log(this.props.list)
        }
      </div>
    );
  }
}
const  mapStateToProps = (state) => {
  return {
    list: state.home.attribute_values_one
  }
}
export default connect(
  mapStateToProps,{
    getSingleAttribute 
  }
)(AttributeValueList)
