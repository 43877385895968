import React from "react";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllCoupons,deleteCoupon,updatePostStatus } from "../../actions/homeAction";
import Moment from "react-moment";
import Toggle from "react-toggle";
import "react-toggle/style.css"; 


class CouponsList extends React.Component {
  state = {
    postList:[]
  };

  componentWillMount() {
    var data={
     startRange:0,
     recordLimit:20,
     languageCode:'en'
    }
    this.props.getAllCoupons(data);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ allCouponsList: nextProps.home.allCoupons });
    console.log( nextProps.home.allCoupons)
  }

  deletePost = (id) => {
    var data={
      couponId:id
    }
    this.props.deleteCoupon(data)
  };
  

  imgLoadError = (event) => {
    event.target.src = "./assets/images/icon.png";
  };
  handleStatusChange= (status,id) => {
    console.log(status,id)
     var change
     if(status)
     {change=false}
     else
     {change=true}
    var data={
      PostId:id,
      status:change
     }
     //console.log(data)
     this.props.updatePostStatus(data)
     
  }
  render() {
    console.log(this.state.postList);
    const columns = [
      {
        name: "couponCode",
        label: "Coupon Code",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (couponCode)=> {
            return(<>
              <b>{couponCode}</b>
            </>)
          }
        },
      },
      {
        name: "name",
        label: "Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "validFrom",
        label: "Valid From",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (validFrom)=>{
            return(<>
              <span>
                  {new Date(validFrom).getDate()}/
                  {new Date(validFrom).getMonth()+1}/
                  {new Date(validFrom).getFullYear()}
              </span>
            </>)
          }
        },
      },
      {
        name: "validTo",
        label: "Valid To",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (validTo)=>{
            return(<>
              <span>
                  {new Date(validTo).getDate()}/
                  {new Date(validTo).getMonth()+1}/
                  {new Date(validTo).getFullYear()}
              </span>
            </>)
          }
        },
      },
      
      
      {
        name: "discountType",
        label: "Discount Type",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (discountType)=> {
            return(<>
              <b style={{textTransform:'capitalize'}}>{discountType}</b>
            </>)
          }
        },
      }, 
      {
        name: "discountValue",
        label: "Discount Value",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (status)=> {
            return(<>
              
              {status?<b style={{color:'green'}}>Active</b>:<b style={{color:'red'}}>Inactive</b>}
            </>)
          }
        },
      },
      
      {
        name: "display",
        label: "Display Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (display)=> {
            return(<>
              
              {display?<b style={{color:'blue'}}>YES</b>:<b style={{color:'red'}}>NONE</b>}
            </>)
          }
        },
      },
      
      {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
              <Link
                  to={"/coupons/add/"+ id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                > 
                  <i class="f-20 icofont icofont-eye text-custom"></i>
                </Link>
                <span
                  onClick={this.deletePost.bind(this, id)}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                >
                  <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                </span>
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Upcoming Posts Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>All Coupons</h4>
                    </div>
                  </div>
                  
                  {/* <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link> */}
                  <Link
                    to="/coupons/add"
                    className="col-2 btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Coupon{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card ">
                   
                    <div className="card-block">
                   
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          className="table-responsive"
                          data={this.state.allCouponsList}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CouponsList.propsTypes = {
  getAllCoupons: PropTypes.func.isRequired,
  deleteCoupon:PropTypes.func.isRequired,
  updatePostStatus:PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getAllCoupons,deleteCoupon,updatePostStatus })(CouponsList);
