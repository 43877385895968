import React from "react";
import Swal from "sweetalert2";
import Constant from "../Constant.js";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loginUser } from "../actions/homeAction";

class Login extends React.Component {
  state = {
    role: "admin",
  };

  onInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

  };

  onLogin = (event) => {
    event.preventDefault();
    var that = this;
    var data = new URLSearchParams();
    this.setState({ isSaving: true });

    var email = that.state.username;
    var password = that.state.password;

    // let formData = new FormData()
    // formData.append('email',that.state.username)
    // formData.append('password',that.state.password)

    var data = {
      email: email,
      password: password,
    }
    
    console.log('here')

    this.props.loginUser(data)
    return false

    // if (email && password) {
    //   localStorage.setItem("tenhil_ad", true);
    //   localStorage.setItem(
    //     "tenhil_ad_auth",
    //     "eyJhbGciOiJIUzI1NiJ9.YTUwYWI1M2EtZDZlNC00NjAxLWE0ZmQtODU4MGQzZjgwMTRh.iHbL208m6TqZHNQZZ-a7rk25TsuLhaEyMtIiopfUYhk"
    //   );
    //   localStorage.setItem("tenhil_ad_name", "Super Admin");
    //   // localStorage.setItem("tenhil_ad_name", json.result.userName)
    //   // localStorage.setItem("tenhil_ad_uid", json.result.id)
    //   // localStorage.setItem("tenhil_ad_uid", json.result.adminId);
    //   // localStorage.setItem("tenhil_ad_email", json.result.email)
    //   localStorage.setItem("tenhil_ad_email", "admin@admin.com");
    //   Swal.fire({
    //     title: "Login Successfully ",
    //     // position: 'top-end',
    //     icon: "success",
    //     text: "",
    //     // type: "success",
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Ok",
    //   });
    //   window.location.reload();
    // } else {
    //   Swal.fire({
    //     title: "Incorrect Credentials",
    //     // position: 'top-end',
    //     icon: "error",
    //     text: "",
    //     // type: "success",
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Ok",
    //   });
    // }

    // return false;

    // data = {
    //   payload: {
    //     email: that.state.username,
    //     password: that.state.password,
    //   },
    // };

    // // data.append("email", that.state.username);
    // // data.append("password", that.state.password);
    // fetch(Constant.getAPI() + "/admin/sign-in", {
    //   method: "post",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(function (json) {
    //     if (json.success === true) {
    //       localStorage.setItem("tenhil_ad", true);
    //       localStorage.setItem(
    //         "q8_horo_auth",
    //         "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFjNTQ4MTQzLTJlMzItNDQ1Yi04YTJkLTVhYzhjNTk2NTM5MCIsImlhdCI6MTYwMTMwNDA3NjE0MH0.0o-tbltXo6ODK63ZbGq63xul99Oy8VO_1Bt7WHgx6Fo"
    //       );
    //       localStorage.setItem("tenhil_ad_name", "Super Admin");
    //       // localStorage.setItem("tenhil_ad_name", json.result.userName)
    //       // localStorage.setItem("tenhil_ad_uid", json.result.id)
    //       localStorage.setItem("tenhil_ad_uid", json.result.adminId);
    //       // localStorage.setItem("tenhil_ad_email", json.result.email)
    //       localStorage.setItem("tenhil_ad_email", "admin@admin.com");
    //       Swal.fire({
    //         title: "Login Successfully ",
    //         // position: 'top-end',
    //         icon: "success",
    //         text: "",
    //         // type: "success",
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#d33",
    //         confirmButtonText: "Ok",
    //       });
    //       window.location.reload();
    //     } else {
    //       Swal.fire({
    //         title: "Incorrect Credentials",
    //         // position: 'top-end',
    //         icon: "error",
    //         text: "",
    //         // type: "success",
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#d33",
    //         confirmButtonText: "Ok",
    //       });
    //     }
    //   });
  };

  render() {
    return (
      <section className="login-block">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <form className="md-float-material form-material">
                <div className="text-center">
                  <h1 className="display-4 text-white"> Tenfil Admin</h1>
                </div>
                <div className="auth-box card">
                  <div className="card-block">
                    <div className="row m-b-20">
                      <div className="col-md-12">
                        <h3 className="text-center">Sign In</h3>
                      </div>
                    </div>
                    {/* <div className="form-group form-primary">
                      <select name="role" className="form-control" onChange={this.onInputChange} value={this.state.role}>
                        <option value="admin">Admin</option>
                        <option value="shop">Shop</option>
                      </select>
                    </div> */}
                    <div className="form-group form-primary">
                      <input
                        type="text"
                        name="username"
                        className="form-control"
                        required=""
                        placeholder="Username"
                        onChange={this.onInputChange}
                      />
                      <span className="form-bar"></span>
                    </div>
                    <div className="form-group form-primary">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        required=""
                        placeholder="Password"
                        onChange={this.onInputChange}
                      />
                      <span className="form-bar"></span>
                    </div>
                    <div className="row m-t-30">
                      <div className="col-md-12">
                        <button
                          type="button"
                          onClick={this.onLogin}
                          className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20"
                        >
                          Sign in
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Login.propsTypes = {
  loginUser: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { loginUser })(Login);
