import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';
import Swal from "sweetalert2";
import Constant from "../../Constant";
import {addPOST, getPost, updatePOST} from '../../actions/homeAction'
import {getAttributes } from '../../actions/attributuesAction'

export class BiddingADD extends Component {
  state = {
    title:'',
    title_ar:'',
    about:'',
    postBaseType:'normalBidding',
    bids:null,
    isHomePagePost:false,
    active:true,
    price:null,
    quantity:null,
    imageArray: [],
    imageData: [],
    postId:'',
    postList:[],
    Edit_POST:null,
    imageData2: [],
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.state.price !== prevState.price) {
      if(this.state.price<0){
        this.setState({price:0})
      }else{
        this.setState({price:this.state.price})
      }
    }

    if (this.state.quantity !== prevState.quantity) {
      if(this.state.quantity<0){
        this.setState({quantity:0})
      }else{
        this.setState({quantity:this.state.quantity})
      }
    }

    if (this.state.bids !== prevState.bids) {
      if(this.state.bids<0){
        this.setState({bids:0})
      }else{
        this.setState({bids:this.state.bids})
      }
    }

    if (this.state.startBiddingPrice !== prevState.startBiddingPrice) {
      if(this.state.startBiddingPrice<0){
        this.setState({startBiddingPrice:0})
      }else{
        this.setState({startBiddingPrice:this.state.startBiddingPrice})
      }
    }

    if (this.state.endBiddingPrice !== prevState.endBiddingPrice) {
      if(this.state.endBiddingPrice<0){
        this.setState({endBiddingPrice:0})
      }else{
        this.setState({endBiddingPrice:this.state.endBiddingPrice})
      }
    }

    if (this.state.timer !== prevState.timer) {
      if(this.state.timer<0){
        this.setState({timer:0})
      }else{
        this.setState({timer:this.state.timer})
      }
    }

    if (this.state.minBidsRequired !== prevState.minBidsRequired) {
      if(this.state.minBidsRequired<0){
        this.setState({minBidsRequired:0})
      }else{
        this.setState({minBidsRequired:this.state.minBidsRequired})
      }
    }

    if (this.state.maxBiddingBids !== prevState.maxBiddingBids) {
      if(this.state.maxBiddingBids<0){
        this.setState({maxBiddingBids:0})
      }else{
        this.setState({maxBiddingBids:this.state.maxBiddingBids})
      }
    }

    if (this.state.maxBiddingUser !== prevState.maxBiddingUser) {
      if(this.state.maxBiddingUser<0){
        this.setState({maxBiddingUser:0})
      }else{
        this.setState({maxBiddingUser:this.state.maxBiddingUser})
      }
    }

  }
  
  onNextStep = (media,media2) => {

    if(this.props.match.params.postId){
      
      let MEDIA = media.concat(media2)

      let MEDIA2 = []
      if(this.state.imageData2!==[] && this.state.imageData2!==undefined && this.state.imageData2!==null){
        let Data = this.state.imageData2
        var SavedMedia = Data.map(data2=>data2.id)
            SavedMedia = new Set(SavedMedia)
        MEDIA2 = [...SavedMedia]
      }
      

      var data = {
        type:"Auction",
        postId:this.props.match.params.postId,
        postBaseType:this.state.postBaseType,
        title:this.state.title,
        title_ar:this.state.title_ar,
        about:this.state.about,
        timer:this.state.timer,
        // bids:parseInt(this.state.bids),
        minBidsRequired:parseInt(this.state.minBidsRequired),
        // maxBiddingBids:parseInt(this.state.maxBiddingBids),
        maxBiddingUser:parseInt(this.state.maxBiddingUser),
        // price:parseInt(this.state.price),
        // startBiddingPrice:parseInt(this.state.startBiddingPrice),
        // endBiddingPrice:parseInt(this.state.endBiddingPrice),
        startDate:new Date(this.state.startDate).getTime(),
        // quantity:parseInt(this.state.quantity),
        media: media.length>0?MEDIA:MEDIA2,
        // isHomePagePost:this.state.isHomePagePost=='true'?true:false,
        active:this.state.active=='true'?true:this.state.active=='false'?false:true,
      };
  
      this.props.updatePOST(data)
    }else{
      var data = {
        type:"Auction",
        postBaseType:this.state.postBaseType,
        title:this.state.title,
        title_ar:this.state.title_ar,
        about:this.state.about,
        timer:this.state.timer,
        // bids:parseInt(this.state.bids),
        minBidsRequired:parseInt(this.state.minBidsRequired),
        // maxBiddingBids:parseInt(this.state.maxBiddingBids),
        maxBiddingUser:parseInt(this.state.maxBiddingUser),
        // price:parseInt(this.state.price),
        // startBiddingPrice:parseInt(this.state.startBiddingPrice),
        // endBiddingPrice:parseInt(this.state.endBiddingPrice),
        startDate:new Date(this.state.startDate).getTime(),
        // quantity:parseInt(this.state.quantity),
        media: media,
        // isHomePagePost:this.state.isHomePagePost=='true'?true:false,
        active:this.state.active=='true'?true:this.state.active=='false'?false:true,
        
      };
     
      this.props.addPOST(data)
    }
  };

  onHandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }
 
    ImagesToUpload = (e) => {
    let imageData = this.state.imageData;
    let imageArray = this.state.imageArray;

    for (let i = 0; i < e.target.files.length; i++) {
        imageData.push(URL.createObjectURL(e.target.files[i]));
        imageArray.push(e.target.files[i]);
    }
    this.setState({
        imageData,
        imageArray,
    });
    };

  deleteImage = (index) => {
    let imageData = this.state.imageData;
    let imageArray = this.state.imageArray;
        imageArray.splice(index, 1);
        imageData.splice(index, 1);
    this.setState({
      imageData,
      imageArray,
    });
  };

  deleteImage2 = (index) => {
    let imageData2 = this.state.imageData2;
    // let imageArray = this.state.imageArray;
        // imageArray.splice(index, 1);
        imageData2.splice(index, 1);
    this.setState({
      imageData2,
      // imageArray,
    });
  };

 

  onSubmitImages = async (e) => {
    e.preventDefault()
    let media=[]
    let media2=[]

    if(this.state.imageArray.length > 0){ 
      
    const formData = new FormData();
    this.state.imageArray.forEach((file, i) => {
      formData.append('media', file);
    });
    
    await axios
      .post(Constant.getAPI() + 'media/add',  formData, {
        headers: {
          Authorization: `${localStorage.getItem("caviar_ad_auth")}`,
        },
      })
      .then((res) => {
        if (res.data.data ) {
         var Data = res.data.data.map(data=>data.id)
            Data = new Set(Data)
            media = [...Data]
        if(this.props.match.params.postId){
          var Data2 = this.state.imageData2
              Data2 = Data2.map(data2=>data2.id)
              Data2 = new Set(Data2)
              media2 = [...Data2]
        }else{}

        } else {

        }
     })
    }
      this.onNextStep(media,media2)

    }

  componentDidMount(){
   
     this.setState({postId:this.props.match.params.postId})

     var data={
      bids:'', 
      price:'', 
      type:'Auction', 
      status:'', 
      startRange:'', 
      recordLimit:'',
    }
    this.props.getPost(data);
  }

 componentWillReceiveProps(nextProps){

   this.setState({ postList: nextProps.home.postList });
   var POST_LIST= nextProps.home.postList
   this.GET_SELECTD_POST(POST_LIST)
 }

 GET_SELECTD_POST = (POST_LIST) => {
   
   if(this.props.match.params.postId){
    var pID = this.props.match.params.postId
    var FilteredPost = POST_LIST.filter(post=>post.id == pID)
    var POST = FilteredPost[0]
    console.log('POST',POST);
    console.log('startDate',POST.startDate);

    var stamp = new Date(POST.startDate)
    var NewDate = stamp.toJSON()
      NewDate = NewDate.slice(0,16)
      console.log('NewDate',NewDate);

    this.setState({
      postBaseType:POST.postBaseType,
      active:POST.active,
      title:POST.title,
      title_ar:POST.title_ar,
      about:POST.about,
      // price:POST.price,
      timer:POST.timer,
      // startBiddingPrice:POST.startBiddingPrice,
      // endBiddingPrice:POST.endBiddingPrice,
      startDate:NewDate,
      // bids:POST.bids,
      minBidsRequired:POST.minBidsRequired,
      // maxBiddingBids:POST.maxBiddingBids,
      maxBiddingUser:POST.maxBiddingUser,
      // quantity:POST.quantity,
      // isHomePagePost:POST.isHomePagePost,
    })
    this.setState({imageData2:POST.Media})
    this.setState({Edit_POST:POST})
   }
    
 }

render() {
//   console.log('Date',this.state.startDate.getTime());
//   console.log('postList',this.state.postList);
//   console.log('imageData',this.state.imageData);
  console.log('Date',this.state.startDate);
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Bidding Post {this.state.postId?'Edit':'Add'}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="page-header-breadcrumb">
                    <ul className="breadcrumb-title">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="feather icon-home"></i>{" "}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/post/add">Bidding Post {this.state.postId?'Edit':'Add'}</Link>
                      </li>
                      <li className="breadcrumb-item active">Bidding Master</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <form onSubmit={this.onSubmitImages} className="row">
                        <div className="col-md-12 UPLOAD_Container">
                          <div className="UPLOADED_FILES">
                            {this.state.imageData.length>0?this.state.imageData.map((image,index)=>(
                              <div>
                                <div style={{background :`url(${Constant.getAPI()}${image}) no-repeat center/cover`}}>
                                  <div className="REMOVE" onClick={()=>this.deleteImage(index)}>
                                    Remove
                                  </div>
                                </div>
                            </div>
                            )):(<p>No Images Yet !</p>)}

                            {this.state.imageData2.length>0?this.state.imageData2.map((image2,index2)=>(
                              <div>
                                <div style={{background :`url(${Constant.getAPI()}${image2.url}) no-repeat center/cover`}}>
                                  <div className="REMOVE" onClick={()=>this.deleteImage2(index2)}>
                                    Remove
                                  </div>
                                </div>
                            </div>
                            )):null}
                            
                          </div>
                          <div className="UPLOAD_BTN">
                            <label htmlFor="imageInput">
                              <img src="/assets/gallery.svg" alt="" />
                              <span>Select Images</span>
                            </label>
                            <input 
                              type="file" 
                              id='imageInput' 
                              multiple 
                              accept="image/png, image/gif, image/jpeg" 
                              style={{display:"none"}}
                              onChange={this.ImagesToUpload}/>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Title</label>
                              <div className="col-sm-8">
                                  <input
                                      type="text"
                                      className="form-control"
                                      name="title"
                                      id="title"
                                      placeholder="Title"
                                      value={this.state.title}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Title Arabic</label>
                              <div className="col-sm-8">
                                  <input
                                      type="text"
                                      className="form-control"
                                      name="title_ar"
                                      id="title_ar"
                                      placeholder="Title Arabic"
                                      value={this.state.title_ar}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                               <div className="form-group row">
                                   <label htmlFor='postBaseType' className="col-sm-4 col-form-label FONT_WEIGHT2">Auction Status</label>
                                   <div className="col-sm-8">
                                       <select 
                                           name="postBaseType" 
                                           className="form-control" 
                                           onChange={this.onHandleChange}
                                           value={this.state.postBaseType}
                                       >
                                           {/* <option value='normalSelling'>Normal Selling</option> */}
                                           <option value="upcoming">Coming Soon</option>
                                           <option value="ongoing">Live</option>
                                           <option value="completed">Completed</option>
                                            {/* <option value='biddingWithOffer'>Bidding With Offer</option> */}
                                           {/* <option value='monthly'>Monthly</option> */}
                                           {/* <option value='weekly'>Weekly</option> */}
                                       </select>
                                   </div>
                               </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Price</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="price"
                                      id="price"
                                      placeholder="Price"
                                      value={this.state.price}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='startBiddingPrice' className="col-sm-4 col-form-label FONT_WEIGHT2">Start Bidding Price</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="startBiddingPrice"
                                      id="startBiddingPrice"
                                      placeholder="Start Bidding Price"
                                      value={this.state.startBiddingPrice}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='endBiddingPrice' className="col-sm-4 col-form-label FONT_WEIGHT2">End Bidding Price</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="endBiddingPrice"
                                      id="endBiddingPrice"
                                      placeholder="End Bidding Price"
                                      value={this.state.endBiddingPrice}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='startDate' className="col-sm-4 col-form-label FONT_WEIGHT2">Start Date</label>
                              <div className="col-sm-8">
                                  <input
                                      type="datetime-local"
                                      className="form-control"
                                      name="startDate"
                                      id="startDate"
                                      value={this.state.startDate}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Quantity</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="quantity"
                                      id="quantity"
                                      placeholder="Quantity"
                                      value={this.state.quantity}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div> */}
                        <div className="col-md-12">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-2 col-form-label FONT_WEIGHT2">Description</label>
                              <div className="col-sm-10">
                                  <textarea
                                      type="text"
                                      className="form-control"
                                      name="about"
                                      id="about"
                                      rows='3'
                                      placeholder="Post Description"
                                      value={this.state.about}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Timer</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="timer"
                                      id="timer"
                                      placeholder="Timer in Seconds"
                                      value={this.state.timer}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Bids</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="bids"
                                      id="bids"
                                      placeholder="Bids"
                                      value={this.state.bids}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='minBidsRequired' className="col-sm-4 col-form-label FONT_WEIGHT2">Minimum Bids</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="minBidsRequired"
                                      id="minBidsRequired"
                                      placeholder="Minimum Bids"
                                      value={this.state.minBidsRequired}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='maxBiddingBids' className="col-sm-4 col-form-label FONT_WEIGHT2">Maximum Bids</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="maxBiddingBids"
                                      id="maxBiddingBids"
                                      placeholder="Maximum Bids"
                                      value={this.state.maxBiddingBids}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='maxBiddingUser' className="col-sm-4 col-form-label FONT_WEIGHT2">Maximum Bidding Users</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="maxBiddingUser"
                                      id="maxBiddingUser"
                                      placeholder="Maximum Bidding Users"
                                      value={this.state.maxBiddingUser}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                               <div className="form-group row">
                                   <label htmlFor='valueType' className="col-sm-4 col-form-label FONT_WEIGHT2">Homepage Post</label>
                                   <div className="col-sm-8">
                                       <select 
                                           name="isHomePagePost" 
                                           className="form-control" 
                                           onChange={this.onHandleChange}
                                           value={this.state.isHomePagePost}
                                       >
                                           <option value={true}>Yes</option>
                                           <option value={false}>No</option>
                                       </select>
                                   </div>
                               </div>
                           </div> */}
                           <div className="col-md-6">
                               <div className="form-group row">
                                   <label htmlFor='valueType' className="col-sm-4 col-form-label FONT_WEIGHT2">Activity Status</label>
                                   <div className="col-sm-8">
                                       <select 
                                           name="active" 
                                           className="form-control" 
                                           onChange={this.onHandleChange}
                                           value={this.state.active}
                                       >
                                           <option value={true}>Active</option>
                                           <option value={false}>Not Active</option>
                                       </select>
                                   </div>
                               </div>
                           </div>
                        
                         <div className="col-md-12 DATA_SAVE">
                            <div>
                                  <button type='submit' className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> Save</button>
                            
                              <Link to={"/ongoing-posts"} className="btn btn-outline-dark">
                                Cancel
                              </Link>
                            </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
BiddingADD.propsTypes = {
 
  addPOST: PropTypes.func.isRequired,
  getPost: PropTypes.func.isRequired,
  updatePOST: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  
};

var mapStateToProps = (state) => ({
  home: state.home
});
export default connect(mapStateToProps, {

  addPOST,
  getPost,
  updatePOST,
  })(BiddingADD);
