import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addVariants, getPost, updatePOST } from '../../actions/homeAction'
import { getAttributes } from '../../actions/attributuesAction'

export class AddVariants extends Component {
    state = {
        variants: [
            {
                postId: this.props.match.params.postId,
                attributes: [{ attributeId: '', attributeOptionId: '' }],
                price: null,
                quantity: null,
            }
        ],
    };

    componentDidMount() {
        if (this.props.attributes_list == undefined ||
            this.props.attributes_list == null ||
            this.props.attributes_list == 0) {
            this.props.getAttributes()
        }
        var data = {
            bids: '',
            price: '',
            type: 'Fixed',
            status: '',
            startRange: '',
            recordLimit: '',
        }
        this.props.getPost(data);
    }

    componentWillReceiveProps(nextProps){
        var POST_LIST= nextProps.home.postList
        this.GET_SELECTD_POST(POST_LIST)
      }

      GET_SELECTD_POST = (POST_LIST) => {
   
        if(this.props.match.params.postId&&POST_LIST){
         var pID = this.props.match.params.postId
         var FilteredPost = POST_LIST?.filter(post=>post?.id == pID)
         var POST = FilteredPost[0]
         console.log('FilteredPost',POST)

         var Variants = POST?.Variants?.map(variants=>{
            return {
                postId:variants.PostId,
                variantId:variants.id,
                price:variants.price,
                quantity:variants.quantity,
                attributes:variants.VariantAttributes.map(attri=> {
                    return {
                        attributeId:attri.AttributeId,
                        attributeOptionId:attri.AttributeOptionId,
                        variantId:attri.VariantId,
                    }
                })
            }
         })

         
         this.setState({variants:Variants})
         console.log('Variants FilteredPost',Variants);

        }
      }
     

    VariantHandleChange = (e, index, i) => {
        e.preventDefault()
        const {name,value} = e.target
        let variant = this.state.variants
        if(i==undefined){
            variant[index][name]=value
            this.setState({variants:variant})
        }else{
            variant[index].attributes[i][name] = value
            this.setState({variants:variant})
        }

        console.log('VariantHandleChange',i);
    }

    onAddVariant = () => {
        let variants = this.state.variants
        var variant = { postId: this.props.match.params.postId, price: null, quantity: null, attributes: [{ attributeId: '', attributeOptionId: '', }], }
        variants.push(variant)
        this.setState({ variants })
    }

    removeVariant = (index) => {
        console.log('removeVariant',index);
        let variants = this.state.variants
        variants.splice(index, 1)
        this.setState({ variants })
    }

    onAddAttribute = (variantsIndex) => {
        let temp = this.state.variants
        let variants = temp[variantsIndex]
        variants.attributes.push({ attributeId: null, attributeOptionId: null })
        this.setState({ variants: temp })
    }


    removeAttri = (INDEX, index) => {
        let variants = this.state.variants
        variants[index].attributes.splice(INDEX, 1)
        
        this.setState({ variants })
    }

    onSubmitVariants=(e)=>{
        e.preventDefault()
        var data = {
            variants : this.state.variants
        }
        this.props.addVariants(data)
    }

    render() {

        console.log('variants',this.state.variants);

        return (
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-header">
                            <div className="row align-items-end">
                                <div className="col-lg-8">
                                    <div className="page-header-title">
                                        <div className="d-inline">
                                            <h4>Variant Add</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="page-header-breadcrumb">
                                        <ul className="breadcrumb-title">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="feather icon-home"></i>{" "}
                                                </Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <Link to="/post/add">Variant Add</Link>
                                            </li>
                                            <li className="breadcrumb-item active">Post Master</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-block">
                                            <form onSubmit={this.onSubmitVariants} className="row">

                                                <div className="col-md-12">
                                                    <button onClick={() => this.onAddVariant()}
                                                        className="btn btn-sm btn-inverse waves-effect waves-light f-left d-inline-block md-trigger"
                                                    >
                                                        <i className="icofont icofont-plus m-r-5"></i>Add Variant{" "}
                                                    </button>
                                                </div>
                                                <div className="col-md-12 row">
                                                    {
                                                        this.state.variants &&
                                                            this.state.variants.length > 0 ?
                                                            this.state.variants.map((variant, index) => {
                                                                return <div className="ATTRIBUTE_OPTIONS row" key={index}>

                                                                    <div className="col-md-6">
                                                                        <div className="form-group row">
                                                                            <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT">Variant Price</label>
                                                                            <div className="col-sm-8">
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="price"
                                                                                    id="price"
                                                                                    placeholder="Variant Price"
                                                                                      onChange={e=> this.VariantHandleChange(e,index)}
                                                                                    value={variant.price}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group row">
                                                                            <label htmlFor='hexCode' className="col-sm-4 col-form-label FONT_WEIGHT">Variant Quantity</label>
                                                                            <div className="col-sm-8">
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="quantity"
                                                                                    id="quantity"
                                                                                    placeholder="Variant Quantity"
                                                                                      onChange={e=> this.VariantHandleChange(e,index)}
                                                                                    value={variant.quantity}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12">
                                                                        <button onClick={() => this.onAddAttribute(index)}
                                                                            className="btn btn-sm btn-inverse waves-effect waves-light f-left d-inline-block md-trigger"
                                                                        >
                                                                            <i className="icofont icofont-plus m-r-5"></i>Add Attribute{" "}
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-md-12 row">
                                                                        {variant.attributes.map((attribute, i) => {
                                                                            return <div className="ATTRIBUTE_OPTIONS row" key={i}>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group row">
                                                                                        <label htmlFor='valueType' className="col-sm-4 col-form-label FONT_WEIGHT">Attribute Name</label>
                                                                                        <div className="col-sm-8">
                                                                                            <select
                                                                                                name="attributeId"
                                                                                                className="form-control"
                                                                                                onChange={e=> this.VariantHandleChange(e,index,i)}
                                                                                                value={attribute.attributeId}
                                                                                            >
                                                                                                <option value="" name="select" >- Name -</option>
                                                                                                {
                                                                                                    this.props.attributes_list.map((attribute, key) => {
                                                                                                        return <option value={attribute.id} id={key}>
                                                                                                            {attribute.name}
                                                                                                        </option>
                                                                                                    })
                                                                                                }
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group row">
                                                                                        <label htmlFor='valueType' className="col-sm-4 col-form-label FONT_WEIGHT">Attribute Option</label>
                                                                                        <div className="col-sm-8">
                                                                                            <select
                                                                                                name="attributeOptionId"
                                                                                                className="form-control"
                                                                                                onChange={e=> this.VariantHandleChange(e,index,i)}
                                                                                                value={attribute.attributeOptionId}
                                                                                            >
                                                                                                <option value="" name="select" >- Option -</option>
                                                                                                {this?.props?.attributes_list?.filter(attri=>attri?.id===this?.state?.variants[index]?.attributes[i]?.attributeId)[0]?.AttributeOptions?.map((attriOption,op_idx)=>(
                                                                                                    <option value={attriOption?.id}>{attriOption?.value}</option>
                                                                                                ))}

                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12 ATT_REM_BTN2">
                                                                                    <button onClick={() => this.removeAttri(i, index)}>x</button>
                                                                                </div>

                                                                            </div>
                                                                        })}

                                                                    </div>


                                                                    <div className="col-md-12 ATT_REM_BTN" style={{ marginTop: '1rem' }}>
                                                                        <button onClick={() => this.removeVariant(index)}>Remove Variant</button>
                                                                    </div>

                                                                </div>
                                                            })
                                                            : null
                                                    }

                                                </div>
                                                <div className="col-md-12 DATA_SAVE">
                                                    <div>
                                                        <button type='submit' className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> Save</button>

                                                        <Link to={"/variants"} className="btn btn-outline-dark">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AddVariants.propsTypes = {
    getPost: PropTypes.func.isRequired,
    addVariants: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
    home: state.home,
    attributes_list: state.home.attributes_list
});

export default connect(mapStateToProps, { getPost, addVariants, getAttributes })(AddVariants);