import React from "react";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types"
import Loader from "../../Loader";
import {
      addDateblock,
      updateDateblock,
      getDateblock,
      
} from "../../actions/homeAction"

class DateAdd extends React.Component {
  state = {
    
  };
  componentDidMount(){
      
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      dateblockList:nextProps.home.dateblockList,
      celebrity:nextProps.home.celebrity,
    });
    
    //console.log(nextProps.home.timeslotList)
  };

  componentDidUpdate(prevProps) {
    if (prevProps.dateblock_id !== this.props.dateblock_id) {
        this.setState({ dateblock_id: this.props.dateblock_id });
        this.getDateblockDetails();
      }
      if (prevProps.language_id !== this.props.language_id) {
        if (this.props.dateblock_id !== undefined) {
            this.setState({ dateblock_id: this.props.dateblock_id });
            this.props.getDateblock();

        }
        this.getDateblockDetails();
      }
 }
  
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  };

  getDateblockDetails = () => {
   var block=this.state.dateblockList

   if(block !== undefined){
  for(let i=0;i<block.length;i++)
  {    console.log(this.props.dateblock_id)

    if(block[i].id == this.props.dateblock_id){
        console.log("match")
       this.fillInputs(block[i])

       }
  } }
  };

  fillInputs(block){
      console.log("now")
   this.setState({
    date:block.date,
    
   })
  }

  onSaveData = () => {
    var that = this;
    that.setState({ isSaving: true });
  if (that.props.dateblock_id !== undefined) {

      that.updateDateblockData(); 
    }
    else {
         that.addDateblock();}
   };
 
  updateDateblockData = () => {
    this.setState({ isSaving: true });
    var data={
      date:this.state.date,
      DateBlockId:this.props.dateblock_id,
      CelebrityId:this.state.celebrityId,
    }
    
    this.props.updateDateblock(data)
  };
 
  addDateblock = () => {
    var data={
       date:this.state.date,
       CelebrityId:this.state.celebrityId
     }
    this.props.addDateblock(data)
    console.log(data)
  };
  
  componentDidMount() {
   
    this.loadScript(
      process.env.PUBLIC_URL + "/assets/pages/filer/jquery.fileuploads.init.js"
    );

  }
  loadScript(src) {
    let script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    document.head.append(script);
  };

  render() {
    return (
      <div className="">
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="card-body">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">DATE BLOCK</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="date"
                      id="date"
                      placeholder="date block"
                      onChange={this.handleChange}
                      value={this.state.date}
                    />
                  </div>
                </div>
              </div>
             </div>
             <div className="col-md-8">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  SELECT CELEBRITY
                </label>
                <div className="col-sm-9">
                  <select
                    name="celebrity"
                    className="form-control form-control-inverse"
                    onChange={this.handleChange}
                    value={this.state.celebrityId}
                  > {console.log(this.state.dateblockList)}
                    <option value="opt1">Select Celebrity</option>
                    {
                    this.state.dateblockList !== undefined 
                    ? this.state.dateblockList.map((celebrity) =>(
                        celebrity.Celebrity!== null && celebrity.Celebrity.firstName !== null ?
                          <option value={celebrity.id} key={celebrity.id}>
                            {celebrity.Celebrity.firstName}
                          </option> :""
                        ))
                      :""}
                  </select>
                </div>
              </div>
            </div>
           <div className="card-footer">
              <div className="row float-right p-3">
                {this.state.isSaving ? (
                  <button className="btn btn-grd-disabled mr-2" disabled>
                    Saving...!
                  </button>
                ) : (
                  <button
                    onClick={this.onSaveData}
                    className="btn btn-grd-disabled mr-2"
                  >
                    <i className="icofont icofont-save"></i> Save
                  </button>
                )}
                <Link to={"/dateblock"} className="btn btn-outline-dark">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
DateAdd.propsTypes = {
  addDateblockt:PropTypes.func.isRequired,
  
  getDateblock:PropTypes.func.isRequired,
  updateDateblock:PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, {
  addDateblock,
  updateDateblock,
  getDateblock,
 
  })(DateAdd);
