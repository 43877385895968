import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';
import Swal from "sweetalert2";
import Constant from "../../Constant";
import {addPackagePriceFilter, getPackagePriceFilter, updatePackagePriceFilter} from '../../actions/homeAction'

export class AddPriceFilter extends Component {

    state = {}
    
    componentDidMount(){
        if(this.props.filter_id !== null)
        {
          this.getFilteredData(this.props.filter_id);
        }
        this.props.getPackagePriceFilter()
        console.log('IDDDD',this.props.filter_id)
      }


      componentWillReceiveProps(nextProps) {
        this.setState({
            package: nextProps.home.package
        });
        console.log('DATA',nextProps.home.package)

        var DATA = nextProps.home.package
        this.getFilteredData(DATA)
      }
    
      componentDidUpdate(prevProps) {
        if (prevProps.filter_id !== this.props.filter_id) {
          this.setState({ filter_id: this.props.filter_id });
          this.getFilteredData();
        }
      }
      
    onHandleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }


      getFilteredData = (DATA) => {
      var filter = DATA

        console.log('filter',filter);
        
        if(filter !== undefined){
          console.log(filter[0]?.id)
          console.log(this.props.filter_id)
        for(let i=0;i<filter.length;i++)
        {
          if(filter[i].id == this.props.filter_id){
             this.fillInputs(filter[i])
             console.log(filter[i])
          }
        }}
      
      };
      
      fillInputs(filter){
        console.log('DATAF',filter)
        this.setState({
          priceFilterName:filter.priceFilterName,
          priority:filter.priority,
          startPrice:filter.startPrice,
          endPrice:filter.endPrice,
          bids:filter.bids,
        })
    
      };
    
      updateFilterData = () => {
        
        var data = {
            id: this.props.filter_id,
            priceFilterName: this.state.priceFilterName,
            priority: this.state.priority,
            startPrice: (this.state.startPrice),
            endPrice: this.state.endPrice,
            bids:this.state.bids, 
        };

        console.log(data)
        this.props.updatePackagePriceFilter(data)
      };
     
      addFilterData = () => {
         
          var data={
            priceFilterName: this.state.priceFilterName,
            priority: this.state.priority,
            startPrice: this.state.startPrice,
            endPrice: this.state.endPrice,
            bids:this.state.bids, 
          }

           this.props.addPackagePriceFilter(data)
      };
      

      onSubmitData = (e) => {
        e.preventDefault()
        var that = this;
        
        if (that.props.filter_id !== undefined) {
           that.updateFilterData();
      } else {
          that.addFilterData();
        }
      };


  render() {
      console.log('FILTER',this.state.package);
      console.log('IDDDdddD',this.props.filter_id)
    return (

      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
           
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <form onSubmit={this.onSubmitData} className="row">
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='priceFilterName' className="col-sm-4 col-form-label FONT_WEIGHT">Filter Name</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="priceFilterName"
                                        id="priceFilterName"
                                        placeholder="Filter Name"
                                        value={this.state.priceFilterName}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='priority' className="col-sm-4 col-form-label FONT_WEIGHT">Priority</label>
                                <div className="col-sm-8">
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="priority"
                                        id="priority"
                                        placeholder="Priority"
                                        value={this.state.priority}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='startPrice' className="col-sm-4 col-form-label FONT_WEIGHT">Start Price</label>
                                <div className="col-sm-8">
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="startPrice"
                                        id="startPrice"
                                        placeholder="Start Price"
                                        value={this.state.startPrice}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='endPrice' className="col-sm-4 col-form-label FONT_WEIGHT">End Price</label>
                                <div className="col-sm-8">
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="endPrice"
                                        id="endPrice"
                                        placeholder="End Price"
                                        value={this.state.endPrice}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='bids' className="col-sm-4 col-form-label FONT_WEIGHT">Bids</label>
                                <div className="col-sm-8">
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="bids"
                                        id="bids"
                                        placeholder="Bids"
                                        value={this.state.bids}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 DATA_SAVE">
                            <div>
                                  <button type='submit' className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> Save</button>
                            
                              <Link to={"/price-filter"} className="btn btn-outline-dark">
                                Cancel
                              </Link>
                            </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddPriceFilter.propsTypes = {
    addPackagePriceFilter: PropTypes.func.isRequired,
    getPackagePriceFilter: PropTypes.func.isRequired,
    updatePackagePriceFilter:PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
    
  };
  
  var mapStateToProps = (state) => ({
    home: state.home,
  });
  
  export default connect(mapStateToProps, {
    addPackagePriceFilter,
    getPackagePriceFilter,
    updatePackagePriceFilter,
    })(AddPriceFilter);
