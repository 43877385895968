import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Constant from "../../Constant";
import Swal from "sweetalert2";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getBanner, deleteBanner } from "../../actions/homeAction";

class BannerList extends React.Component {
  state = {
    banner_list: [],
  };

  componentWillMount() {
    this.props.getBanner();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ bannerList: nextProps.home.bannerList });
    //console.log(nextProps.home.bannerList)
  }

  deleteBanner = (id) => {
    var data={
      BannerId:id
    }
    this.props.deleteBanner(data)
   
  };
  getBannerList = () => {
    
  };
  imgLoadError = (event) => {
    event.target.src = "./assets/images/tenhil.png";
  };
  render() {
    const columns = [
      {
        name: "Medium",
        label: "Image",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (Medium, tableMeta) => {
            console.log("TABLE", tableMeta.rowData[0]?.type);
            return (
              <div>
              {Medium !== null ? 
              <div>
                {Medium?.url?.indexOf(".mp4") !== -1 ?
                  <video width="250" controls>
                    <source src={Medium !== undefined && Medium !== null && Medium !== {}
                      ? Constant.getAPI() + Medium?.url
                      : "./assets/images/icon.png"} type="video/mp4" />
                  </video> :
                  <img
                    src={
                      Medium.url !== undefined && Medium.url !== null && Medium.url !== {}
                        ? Constant.getAPI() + Medium.url
                        : "./assets/images/icon.png"
                    }
                    className="img-fluid img-100"
                    alt="tbl"
                  />
                }
              </div> :  <img
                    src={
                      Medium.url !== undefined && Medium.url !== null && Medium.url !== {}
                        ? Constant.getAPI() + Medium
                        : "./assets/images/icon.png"
                    }
                    className="img-fluid img-40"
                    alt="tbl"
                  />}
              </div>
            );
          },
        },
      },
      {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
                <Link
                  to={"/banner/add/" + id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                >
                  <i className="f-20 icofont icofont-ui-edit text-custom"></i>
                </Link>
                <span
                  onClick={this.deleteBanner.bind(this, id)}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                >
                  <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                </span>
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Banner Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Banner List</h4>
                    </div>
                  </div>
                  <Link
                    to="/banner/add"
                    className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Banner{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          title={"Banner List"}
                          className="table-responsive"
                          data={this.state.bannerList}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BannerList.propsTypes = {
  deleteBanner:PropTypes.func.isRequired,
  getBanner: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getBanner, deleteBanner })(BannerList);

