import React from "react";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getpayment_type, deletePayment_type, updatepayment_type } from "../../actions/homeAction"
import Toggle from "react-toggle";
import "react-toggle/style.css"; 
class Payment_typeList extends React.Component {
  state = {

  };

  componentWillMount() {
    console.log(this);
    this.props.getpayment_type();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ payment_type: nextProps.home.payment_type });
    console.log(this.setState({ payment_type: nextProps.home.payment_type }));
  }

  deletePayment_type = (id) => {
    var data = {
      PaymentTypeId: id
    }
    this.props.deletePayment_type(data)
  };
  // updatePayment_type = () => {
  //   this.setState({ isSaving: true });
  //   var data={
  //     id:id,
  //     status:status
  //   }
  //   this.props.updatePayment_type(data)
  // };

  handleStatusChange= (status,id) => {
    console.log(status,id)
     var change
     if(status)
     {change=false}
     else
     {change=true}
    var data={
      PaymentTypeId:id,
      status:change
     }
     //console.log(data)
     this.props.updatepayment_type(data)
     
  }
  render() {
    const columns = [

      {
        name: "name",
        label: "Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      // {
      //   name: "name_ar",
      //   label: "Name-ar",
      //   options: {
      //     filter: true,
      //     sort: true,
      //   },
      // },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (status, tableMeta) => {
            return (
              <Toggle
              id={"status" + tableMeta.rowData[2]}
              checked={status === true ? true : false}
              value={status}
              onChange={this.handleStatusChange.bind(this,status,tableMeta.rowData[2])}
            />
            )
          }
        },
      },

      {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
                <Link
                  to={"/payment_type/add/" + id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                >
                  <i className="f-20 icofont icofont-ui-edit text-custom"></i>
                </Link>
                <span
                  onClick={this.deletePayment_type.bind(this, id)}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                >
                  <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                </span>
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Payment Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    return (
      <div className="pcoded-inner-content" >
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Payment List</h4>
                    </div>
                  </div>
                  <Link
                    to="/payment_type/add"
                    className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Payment{" "}
                  </Link>
                  {/* :
                  } */}
                  <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link>

                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          title={"Payment List"}
                          className="table-responsive"
                          data={this.state.payment_type}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


Payment_typeList.propsTypes = {
  getpayment_type: PropTypes.func.isRequired,
  updatePayment_type: PropTypes.func.isRequired,
  deletePayment_type: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, {
  getpayment_type,
  deletePayment_type,
  updatepayment_type
})(Payment_typeList);

