export const SET_LOADING = "SET_LOADING";
export const LOGOUT = "LOGOUT";

export const GET_USERS = "GET_USERS";
export const GET_CELEBRITY = "GET_CELEBRITY";
export const GET_SCHEDULE = "GET_SCHEDULE";

export const GET_CATEGORY = "GET_CATEGORY";
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";
export const GET_BANNER = "GET_BANNER";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_GOVERNORATE = "GET_GOVERNORATE";
export const GET_AREA = "GET_AREA";
export const GET_TIME_SLOT="GET_TIME_SLOT"
export const GET_POST = "GET_POST";

export const GET_LANGUAGE_LIST ="GET_LANGUAGE_LIST";

export const GET_ALL_COUPONS ="GET_ALL_COUPONS";

export const GET_PACKAGE = "GET_PACKAGE";
export const GET_DATE_BLOCK = "GET_DATE_BLOCK";
export const GET_BIDSLIST = "GET_BIDSLIST";
export const GET_PAYMENT_TYPE = "GET_PAYMENT_TYPE";
export const GET_PURCHASE_LIST = "GET_PURCHASE_LIST";
export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST";
export const GET_TERMS="GET_TERMS";

export const GET_ATTRIBUTES="GET_ATTRIBUTES"
export const GET_ATTRIBUTES_ONE="GET_ATTRIBUTES_ONE"
export const GET_MONTHLY_GIFTS="GET_MONTHLY_GIFTS"
export const GET_MONTHLY_GIFT_WINNERS="GET_MONTHLY_GIFT_WINNERS"
export const GET_ABOUT_US="GET_ABOUT_US"
export const GET_TNC_DATA="GET_TNC_DATA"
export const GET_CONTACT_US="GET_CONTACT_US"

export const GET_AUCTION_WINNER_LIST = "GET_AUCTION_WINNER_LIST"

export const GET_DASHBOARD = "GET_DASHBOARD"




