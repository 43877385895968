import React from "react";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types"
import Loader from "../../Loader";
import {
      addTimeslot,
      updateTimeslot,
      getTimeslot
} from "../../actions/homeAction"

class TimeslotAdd extends React.Component {
  state = {
    
  };
  componentDidMount(){
   
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      timeslotList: nextProps.home.timeslotList
    });
    
    //console.log(nextProps.home.timeslotList)
  };

  componentDidUpdate(prevProps) {
    if (prevProps.timeslot_id !== this.props.timeslot_id) {
        this.setState({ timeslot_id: this.props.timeslot_id });
        this.getTimeslotDetails();
      }
      if (prevProps.language_id !== this.props.language_id) {
        if (this.props.timeslot_id !== undefined) {
          this.setState({ timeslot_id: this.props.timeslot_id });
          this.props.getTimeslot();
        }
        this.getTimeslotDetails();
      }
 }
  
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value });
  };

  getTimeslotDetails = () => {
   var slot=this.state.timeslotList

   if(slot !== undefined){
  for(let i=0;i<slot.length;i++)
  {    console.log(this.props.timeslot_id)

    if(slot[i].id ==  this.props.timeslot_id ){
        console.log("match")
       this.fillInputs(slot[i])

       }
  } }
  };

  fillInputs(slot){
      console.log("now")
   this.setState({
    slottime:slot.slotTime
   })
   console.log(slot.slotTime)
  }

  onSaveData = () => {
    var that = this;
    that.setState({ isSaving: true });
  if (that.props.timeslot_id !== undefined) {
      that.updateTimeslotData(); }
    else {
         that.addTimeslot();}
   };
 
  updateTimeslotData = () => {
    this.setState({ isSaving: true });
    var data={
      slotTime:this.state.slottime,
      TimeSlotId:this.props.timeslot_id
    }
    
    this.props.updateTimeslot(data)
  };
 
  addTimeslot = () => {
    var data={
     slotTime:this.state.slottime
    }
    this.props.addTimeslot(data)
    console.log(data)
  };
  
  componentDidMount() {
    this.loadScript(
      process.env.PUBLIC_URL + "/assets/pages/filer/jquery.fileuploads.init.js"
    );

  }
  loadScript(src) {
    let script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    document.head.append(script);
  };

  render() {
    return (
      <div className="">
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="card-body">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">SLOT TIME</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="slottime"
                      id="slottime"
                      placeholder="Slot Time"
                      onChange={this.handleChange}
                      value={this.state.slottime}
                    />
                  </div>
                </div>
              </div>
             </div>
           <div className="card-footer">
              <div className="row float-right p-3">
                {this.state.isSaving ? (
                  <button className="btn btn-grd-disabled mr-2" disabled>
                    Saving...!
                  </button>
                ) : (
                  <button
                    onClick={this.onSaveData}
                    className="btn btn-grd-disabled mr-2"
                  >
                    <i className="icofont icofont-save"></i> Save
                  </button>
                )}
                <Link to={"/brand"} className="btn btn-outline-dark">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
TimeslotAdd.propsTypes = {
  addTimeslot: PropTypes.func.isRequired,
  getTimeslot: PropTypes.func.isRequired,
  updateTimeslot:PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, {
  addTimeslot,
  updateTimeslot,
  getTimeslot
  })(TimeslotAdd);
