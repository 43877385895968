import React from "react";
import { Link } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPost,deletePost,updatePostStatus,updatePOST} from "../../actions/homeAction";
import Moment from "react-moment";
import Toggle from "react-toggle";
import "react-toggle/style.css"; 
import {POSTS_DUMMY_DATA} from '../UserMaster/Customer/DummyData'
import Constant from "../../Constant";

class BiddingList extends React.Component {
  state = {
    postList:[],
    changePost:"ongoing"
  };

  componentWillMount() {
    var data={
      bids:'', 
      price:'', 
      type:'Auction', 
      status:'', 
      startRange:'', 
      recordLimit:'',
    }
    this.props.getPost(data);
  }

  componentWillReceiveProps(nextProps) {
      this.setState({ postList: nextProps.home.postList });
    console.log( nextProps.home.postList)
  }

  deletePost = (id) => {
    var data={
       PostId:id
    }
    var status="Live"
    this.props.deletePost(data,status)
  };
  

  imgLoadError = (event) => {
    event.target.src = "./assets/images/icon.png";
  };
  handleStatusChange= (status,id) => {
    console.log(status,id)
     var change
     if(status)
     {change=false}
     else
     {change=true}
    var data={
      PostId:id,
      status:change
     }
     //console.log(data)
     this.props.updatePostStatus(data)
     
  }

  onDisplayStatusChange =(e,Id)=>{
    var dataRaw = {
      postId:Id,
      displayStatus:e.target.value
    }
    console.log(dataRaw);
    this.props.updatePOST(dataRaw)
  }

  onToggleChange = (e) => {
    // var DATA = this.state.postList
    // let UPCOMING = this.state.postList.filter((item)=>item.displayStatus==="upcoming")
    // let ONGOING = this.state.postList.filter((item)=>item.displayStatus==="ongoing")
    // let COMPLETED = this.state.postList.filter((item)=>item.displayStatus==="completed")
    // (e.target.value==="upcoming")
    // ?
    // this.setState({
    //   changePost: e.target.value,
    //   postList: this?.state?.postList?.filter((item)=>item.displayStatus==="upcoming")
    // })
    // : (
    //   (e.target?.value==="ongoing")
    //   ?
    //   this.setState({
    //     changePost: e.target.value,
    //     postList: this?.state?.postList?.filter((item)=>item.displayStatus==="ongoing")
    //   })
    //   :
    //   this.setState({
    //     changePost: e.target.value,
    //     postList: this?.state?.postList?.filter((item)=>item.displayStatus==="completed")
    //   })
    // )
    // if(e.target.value==="upcoming"){
    //   this.setState({
    //     postList: UPCOMING,
    //     // changePost: e.target.value,
    //   });
    // }else if (e.target?.value==="ongoing"){
    //   this.setState({
    //     postList: ONGOING,
    //     // changePost: e.target.value,
    //   });
    // }else if(e.target.value==="completed"){
    //   this.setState({
    //     postList: COMPLETED,
    //     // changePost: e.target.value,
    //   });
    // }
    // console.log("DTA",DATA);
    this.setState({
      changePost:e.target.value
    })
  }
  render() {
   
    console.log(this.state.changePost);
    const columns = [
      {
        name: "Media",
        label: "Post Image",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (Media  , tableMeta) => {
            // console.log("Media",Media);
            return (
              <div>
                {Media.length>0?Media.map((media,key)=>(
                  key <2?
                  <img className="img-40" src={`${Constant.getAPI()}${media.url}`} alt="" />
                  :null
                ))
                :
                
                  <img
                    src={`/assets/img/xiomi.jpg`}
                    alt=""
                    className="img-40"
                    onError={this.imgLoadError}
                  />
                }
              </div>
            );
            
          },
        },
      },
      {
        name: "title",
        label: "Post Title",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (title)=> {
            return(<>
              <b>{title}</b>
            </>)
          }
        },
      },
      {
        name: "startDate",
        label: "Start Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (startDate)=>{
            return(<>
              <span>
                  {new Date(startDate).getDate()}/
                  {new Date(startDate).getMonth()+1}/
                  {new Date(startDate).getFullYear()}
              </span>
            </>)
          }
        },
      },
      
      {
        name: "price",
        label: "Price",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (price)=> {
            return(<>
              <b>{price} KWD</b>
            </>)
          }
        },
      },
      // {
      //   name: "about",
      //   label: "About",
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (about)=> {
      //       return(<>
      //         <span>{about}</span>
      //       </>)
      //     }
      //   },
      // },
      
      {
        name: "displayStatus",
        label: "Display Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender:(displayStatus, tableMeta)=>{
            var postId = tableMeta.rowData[7]
            return <>
                <select name="" id="" value={displayStatus} onChange={(e)=>this.onDisplayStatusChange(e,postId)}>
                  <option value="upcoming">Upcoming</option>
                  <option value="ongoing">Ongoing</option>
                  <option value="completed">Completed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
            </>
          }
        },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: {
          filter: true,
          sort: true,
          
        },
      },
      {
        name: "active",
        label: "Activity Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (status)=> {
            return(<>
              
              {status?<b style={{color:'green'}}>Active</b>:<b style={{color:'red'}}>Inactive</b>}
            </>)
          }
        },
      },
      {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
              <Link
                  to={"/bidding-posts/add/"+ id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                > 
                  <i class="f-20 icofont icofont-eye text-custom"></i>
                </Link>
                {/* <span
                  onClick={this.deletePost.bind(this, id)}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                >
                  <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                </span> */}
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Upcoming Posts Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>All Bidding Posts</h4>
                    </div>
                  </div>
                  
                  {/* <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link> */}
                  <Link
                    to="/bidding-posts/add"
                    className="col-2 btn btn-sm btn-inverse music-effect music-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Bidding Post{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                            <select className="col-sm-3" onChange={(e)=>this.onToggleChange(e)}>
                             <option value="all">All Product</option>
                             <option value="upcoming">Upcoming</option>
                             <option value="ongoing">Ongoing</option>
                             <option value="completed">Completed</option>
                            </select>
                  <div className="card mt-2">
                   
                    <div className="card-block">
                      {/* <label>Sellect</label> */}
                      {this.state.changePost==="all"? <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          className="table-responsive"
                          data={this.state.postList}
                          columns={columns}
                          options={options}
                        />
                      </div>:
                      (this.state.changePost==="ongoing")?
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          className="table-responsive"
                          data={this.state.postList.filter((item)=>item.displayStatus==="ongoing")}
                          columns={columns}
                          options={options}
                        />
                      </div>
                      :(this.state.changePost==="completed")?
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          className="table-responsive"
                          data={this.state.postList.filter((item)=>item.displayStatus==="completed")}
                          columns={columns}
                          options={options}
                        />
                      </div>
                      : (this.state.changePost==="upcoming")?
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          className="table-responsive"
                          data={this.state.postList.filter((item)=>item.displayStatus==="upcoming")}
                          columns={columns}
                          options={options}
                        />
                      </div>
                      :[]
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BiddingList.propsTypes = {
  getPost: PropTypes.func.isRequired,
  deletePost:PropTypes.func.isRequired,
  updatePostStatus:PropTypes.func.isRequired,
  updatePOST:PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getPost,deletePost,updatePostStatus, updatePOST })(BiddingList);
