import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import $ from "jquery";
import MUIDataTable from "mui-datatables";
import { getUsers , updateUserDetails } from "../../../actions/homeAction";

class UserAdd extends React.Component {
  state = {
    bids:null,
    firstName:"",
    lastName:'',
    email:"",
    mobileNumber:'',
    registrationType:"",
    isVerified:true,
    password:"",
    gender:"male"
  };

  componentDidMount() {
    this.props.getUsers();
  }

  componentWillReceiveProps(nextProps) {
    var users = nextProps.home.users 
    var userID = this.props.user_id
    if(users!==undefined){
      var single = users.filter(data=>data.id===userID)[0]
      this.getSingleUSER(single)
    }
    
    console.log('USERS LIST',userID,users)
  }

  getSingleUSER = (single) => {
    console.log('single',single);
    var USER = single

    this.setState({
      ...USER,
    })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSaveData = (e) => {
    e.preventDefault()
   if(this.props.user_id){
    var data = {
      userId:this.props.user_id,
      bids: this.state.bids,
      firstName:this.state.firstName,
      lastName:this.state.lastName,
      email:this.state.email,
      mobileNumber:this.state.mobileNumber,
      registrationType:this.state.registrationType,
      isVerified:this.state.isVerified,
      password:this.state.password,
      gender:this.state.gender
    }
    this.props.updateUserDetails(data)
   }
  }
  
  render() {
    console.log('user_id',this.props.user_id);

    return (
      <div className="">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12" style={{ marginBottom:'2rem', display:'grid', gridTemplateColumns:'18vh auto'}}>

             <div className="userIMAGE" >
               {/* <img src="/assets/img/cyber.jpg" alt="" style={{height:'100%'}}/> */}
             </div>

             <div className="DETAILS">
               <label className="d-inline-flex"><span>First Name :</span> {this.state.firstName}</label>
               <label className="d-inline-flex"><span >Last Name :</span> {this.state.lastName}</label>
               <label className="d-inline-flex"><span>Email :</span> {this.state.email}</label>
               <label className="d-inline-flex"><span>Mobile :</span> +965 {this.state.mobileNumber}</label>
               <label className="d-inline-flex"><span>User Type :</span>{this.state.registrationType}</label>
               <label className="d-inline-flex"><span>Gender :</span>{this.state.gender}</label>
               <label className="d-inline-flex"><span>Bids Left :</span>{this.state.bids}</label>
             </div>

            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label" style={{fontWeight:'bold'}}>First Name</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    id="firstName"
                    placeholder="first Name"
                    onChange={this.handleChange}
                    value={this.state.firstName}
                  />
                </div>
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label" style={{fontWeight:'bold'}}>Last Name</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    id="lastName"
                    placeholder="last Name"
                    onChange={this.handleChange}
                    value={this.state.lastName}
                  />
                </div>
              </div>
            </div>
           
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label" style={{fontWeight:'bold'}}>Email</label>
                <div className="col-sm-8">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Email"
                    onChange={this.handleChange}
                    value={this.state.email}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label" style={{fontWeight:'bold'}}>Password</label>
                <div className="col-sm-8">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="Password"
                    placeholder="Password"
                    onChange={this.handleChange}
                    value={this.state.password}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label" style={{fontWeight:'bold'}}>Mobile Number</label>
                <div className="col-sm-8">
                  <input
                    type="number"
                    className="form-control"
                    name="mobileNumber"
                    id="mobileNumber"
                    placeholder="mobile Number"
                    onChange={this.handleChange}
                    value={this.state.mobileNumber}
                  />
                </div>
              </div>
            </div>
           
        
            <div className="col-md-6">
              <div className="form-group row">
                {/* <span className="input-group-addon" id="basic-addon4">User Type</span> */}
                <label className="col-sm-4 col-form-label" style={{fontWeight:'bold'}}>User Type</label>
               <div  className="col-md-8">
                <select name="registrationType" className="form-control " value={this.state.registrationType} onChange={this.handleChange}>
                    <option value="normal" name="Normal" style={{color:'grey'}} >Normal</option>
                    <option value="google" name="Google" style={{color:'grey'}} >Google</option>
                    <option value="facebook" name="Facebook" style={{color:'grey'}} >Facebook</option>
                    <option value="apple" name="Apple" style={{color:'grey'}} >Apple</option>
                  </select>
               </div>
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label" style={{fontWeight:'bold'}}>Gender</label>
               <div  className="col-md-8">
                <select name="gender" className="form-control " value={this.state.gender} onChange={this.handleChange}>
                    <option value="male" name="Male" style={{color:'grey'}} >Male</option>
                    <option value="female" name="Female" style={{color:'grey'}} >Female</option>
                  </select>
               </div>
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label" style={{fontWeight:'bold'}}>Total Bids</label>
                <div className="col-sm-8">
                  <input
                    type="number"
                    className="form-control"
                    name="bids"
                    id="bids"
                    placeholder="Total Bids"
                    onChange={this.handleChange}
                    value={this.state.bids}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="input-group">
                <span className="input-group-addon" id="basic-addon4">Change Status</span>
                <select name="status" className="form-control" value={this.state.status} onChange={this.handleChange}
                  style={{
                    color:`${this.state.status=='Active'?'green':'red'}`,
                    fontWeight:'bold',
                    textTransform:'uppercase'
                  }}
                >
                  <option value={true} name="Active" style={{color:'grey'}} >Active</option>
                  <option value={false} name="Blocked" style={{color:'grey'}} >Blocked</option>
                </select>
              </div>
            </div>
            
          </div>
          <div className="row float-right p-3">
            {
              this.state.isSaving
                ?
                <button className="btn btn-grd-disabled mr-2" disabled>Saving...!</button>
                :
                <button onClick={this.onSaveData}  className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> Save</button>
            }
            <Link to={"/users"} className="btn btn-outline-dark">
              Cancel
            </Link>
          </div>
        </div>
      </div >

    );
  }
}

UserAdd.propsTypes = {
  getUsers: PropTypes.func.isRequired, 
  updateUserDetails: PropTypes.func.isRequired, 
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getUsers , updateUserDetails })(UserAdd);

