import React from "react";
import {getDashBoard} from "../actions/homeAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";


class AdminDashboard extends React.Component {

  state ={
    dashboard:[]
  }

  componentDidMount() {
    var data ={
      // startRange:1,
      // count:20,
    }
    this.props.getDashBoard(data);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ dashboard: nextProps.home.dashboard });
    console.log('USERS LIST',nextProps.home.dashboard)
  }
  // getDashboardStats = () => {
  //   var that = this;
  //   var data = new URLSearchParams();
  //   this.setState({ isLoading: true });
  //   fetch(Constant.getAPI() + "/product/order/stats", {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": localStorage.getItem('q8_horo_auth')
  //     },
  //     body: data
  //   }).then(function (response) {
  //     return response.json();
  //   }).then(function (json) {
  //     if (json.success === true) {
  //       that.setState({
  //         orders: json.result.orders,
  //         sales: json.result.sales,
  //         products: json.result.products,
  //         users: json.result.users,
  //         shops: json.result.shops
  //       });
  //     } else {
  //       console.log(json);
  //     }
  //   });
  // }
  render() {
    console.log("DATA", this.state.dashboard);
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">

            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Dashboard</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-body">
              <h3 className="text-primary">Welcome to Tenfil Admin</h3>
              <div className="row">
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-green f-w-600">{this?.state?.dashboard?.totalSaleToday} $</h4>
                          <h6 className="text-muted m-b-0">Total Sales Today</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="icofont icofont-coins f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-green">
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-green f-w-600">{this?.state?.dashboard?.totalSale} $</h4>
                          <h6 className="text-muted m-b-0">Overal Sales </h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="icofont icofont-coins f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-yellow">
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-yellow f-w-600">{this?.state?.dashboard?.totalOrder}</h4>
                          <h6 className="text-muted m-b-0">Total Orders</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="icofont icofont-shopping-cart f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-yellow">
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-pink f-w-600">{this?.state?.dashboard?.totalOrderToday}</h4>
                          <h6 className="text-muted m-b-0">Total Orders Today</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="icofont icofont-shopping-cart f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-pink">
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-blue f-w-600">{this?.state?.dashboard?.pendingBookings}</h4>
                          <h6 className="text-muted m-b-0">Pending Bookings</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="feather icon-award f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-blue">
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-blue f-w-600">{this?.state?.dashboard?.totalRegisteredUsers}</h4>
                          <h6 className="text-muted m-b-0">Total Registered User</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="feather icon-users f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-orenge">
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-blue f-w-600">{this?.state?.dashboard?.totalDeliveriesCompletedToday}</h4>
                          <h6 className="text-muted m-b-0">Total Delivery Completed Today</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="feather icon-users f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-blue">
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div id="styleSelector">
          </div>
        </div>
      </div>
    );
  }
}

AdminDashboard.propsTypes = {
  getDashBoard: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, {getDashBoard}) (AdminDashboard);
