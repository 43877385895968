import React from 'react'
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import PropTypes from "prop-types";
import { getPost} from "../../actions/homeAction";
import MUIDataTable from 'mui-datatables';
import Constant from "../../Constant";

class CompeletedAuctions extends React.Component {
    state ={
        postList:[],
    };
     
    componentWillMount() {
        var data={
          bids:'', 
          price:'', 
          type:'Auction', 
          status:'', 
          startRange:'', 
          recordLimit:'',
        }
        this.props.getPost(data);
      }

    componentWillReceiveProps(nextProps) {
        this.setState({ postList: nextProps.home.postList});
        var data = nextProps.home.postList.filter((item , idx)=> item.displayStatus==="completed")
        console.log( "DATA",data)
    
    }
  render() {
    const columns = [
        {
          name: "Media",
          label: "Post Image",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (Media  , tableMeta) => {
              // console.log("Media",Media);
              return (
                <div>
                  {Media.length>0?Media.map((media,key)=>(
                    key <2?
                    <img className="img-40" src={`${Constant.getAPI()}/${media.url}`} alt="" />
                    :null
                  ))
                  :
                  
                    <img
                      src={`/assets/img/xiomi.jpg`}
                      alt=""
                      className="img-40"
                      onError={this.imgLoadError}
                    />
                  }
                </div>
              );
              
            },
          },
        },
        {
          name: "title",
          label: "Post Title",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (title)=> {
              return(<>
                <b>{title}</b>
              </>)
            }
          },
        },
        {
          name: "startDate",
          label: "Start Date",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (startDate)=>{
              return(<>
                <span>
                    {new Date(startDate).getDate()}/
                    {new Date(startDate).getMonth()+1}/
                    {new Date(startDate).getFullYear()}
                </span>
              </>)
            }
          },
        },
        
        {
          name: "price",
          label: "Price",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (price)=> {
              return(<>
                <b>{price} KWD</b>
              </>)
            }
          },
        },
        {
            name: "displayStatus",
            label: "Display Status ",
            options: {
              filter: true,
              sort: true,
            },
          },
        // {
        //   name: "about",
        //   label: "About",
        //   options: {
        //     filter: true,
        //     sort: true,
        //     customBodyRender: (about)=> {
        //       return(<>
        //         <span>{about}</span>
        //       </>)
        //     }
        //   },
        // },
        {
          name: "id",
          label: "leaderboard",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (id, tableMeta) => {
              return (
                <div>
                <Link
                    to={"/auction-winners/"+ id}
                    className="m-r-15 text-muted"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Edit"
                  > 
                    <i class="f-20 icofont icofont-eye text-custom"></i>
                  </Link>
                  {/* <span
                    onClick={this.deletePost.bind(this, id)}
                    className="m-r-15 text-muted"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Delete"
                  >
                    <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                  </span> */}
                </div>
              );
            },
          },
        },
      ];
      const options = {
        filterType: "dropdown",
        viewColumns: false,
        print: false,
        download: false,
        selectableRows: "none",
        textLabels: {
          body: {
            noMatch: this.state.isSaving
              ? "Loading data..!"
              : "Sorry, No Records Found",
            toolTip: "Sort",
            columnHeaderTooltip: (column) => `Sort for ${column.label}`,
          },
        },
      };
    return (
        
        <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Completed Auctions</h4>
                    </div>
                  </div>
                  
                  {/* <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card ">
                   
                    <div className="card-block">
                   
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          className="table-responsive"
                          data={this.state.postList.filter((item , idx)=> item.displayStatus==="completed")}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


CompeletedAuctions.propsTypes = {
    getPost: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
  };

var mapStateToProps = (state) => ({
    home: state.home,
  });

export default connect(mapStateToProps, { getPost })(CompeletedAuctions)