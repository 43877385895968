export const USER_DUMMY_DATA = [
  {
    firstName: "Adnan",
    lastName: "Khan",
    email: "khan.adnan061@gmail.com",
    mobileNumber: "+965-65902242",
    userType: "Celebrity",
    status:'Blocked'
  },
  {
    firstName: "Ali",
    lastName: "Mohammad",
    email: "mohammad.ali785@gmail.com",
    mobileNumber: "+965-54121242",
    userType: "Regular",
    status:'Active'
  },
  {
    firstName: "Aqib",
    lastName: "Ansari",
    email: "ansari.aqib885@gmail.com",
    mobileNumber: "+965-94144242",
    userType: "admin",
    status:'Active'
  },
  {
    firstName: "Raza",
    lastName: "Ahmad",
    email: "ahmadraza78@gmail.com",
    mobileNumber: "+965-64572242",
    userType: "Celebrity",
    status:'Blocked'
  },
  {
    firstName: "Salman",
    lastName: "Hussain",
    email: "salman.75@gmail.com",
    mobileNumber: "+965-94121242",
    userType: "Regular",
    status:'Blocked'
  },
  {
    firstName: "Bilal",
    lastName: "Mohammad",
    email: "aka.bilal05@gmail.com",
    mobileNumber: "+965-94144242",
    userType: "admin",
    status:'Active'
    
  },
];
export const ORDERLIST_DUMMY_DATA = [
  {
    order_id:'78411TRHDB001',
    user_name: "iPhone 12 Pro Max",
    create_date: "12/Dec/2021",
    amount: "250KD",
    payment_type: "PayPal",
    payment_status: "Failed",
    order_type:'Booking',
    status: "canceled",
    delevery_Status:'delivered',
    booking_Status:'booked'
  },
  {
    order_id:'78411TRHDB001',
    user_name: "MacBook Pro",
    create_date: "10/Sep/2021",
    amount: "300KD",
    payment_type: "Alipay",
    payment_status: "Success",
    order_type:'Delevery',
    status: "Placed",
    delevery_Status:'delivered',
    booking_Status:'booked'
  },
  {
    order_id:'78411TRHDB001',
    user_name: "Nvidia RTX 3060 12GB",
    create_date: "20/Oct/2021",
    amount: "120KD",
    payment_type: "Card",
    payment_status: "Success",
    order_type:'Booking',
    status: "Placed",
    delevery_Status:'progress',
    booking_Status:'progress'
  },
  {
    order_id:'78411TRHDB001',
    user_name: "Samsung Galaxy Note 10",
    create_date: "12/Dec/2021",
    amount: "180KD",
    payment_type: "Card",
    payment_status: "Pending",
    order_type:'Booking',
    status: "Placed",
    delevery_Status:'progress',
    booking_Status:'progress'
  },
  {
    order_id:'78411TRHDB001',
    user_name: "JBL MT2000 2.1",
    create_date: "13/Dec/2021",
    amount: "100KD",
    payment_type: "Mint",
    payment_status: "Failed",
    order_type:'Delevery',
    status: "canceled",
    delevery_Status:'progress',
    booking_Status:'progress'
  },
  {
    order_id:'78411TRHDB001',
    user_name: "iPhone 13 Mini",
    create_date: "02/Nov/2021",
    amount: "210KD",
    payment_type: "Onecard",
    payment_status: "Pending",
    order_type:'Delevery',
    status: "process",
    delevery_Status:'delivered',
    booking_Status:'booked'
    
  },
];
export const POSTS_DUMMY_DATA = [
  {
    post_title: "iPhone 12 Pro Max",
    user_name: "Adnan Sami",
    start_date: "12/Dec/2021",
    end_date: "15/Dec/2021",
    start_price: "250KD",
    status: "Clear",
    biddings: 10,
  },
  {
    post_title: "MacBook Pro",
    user_name: "Shama Ahmad",
    start_date: "10/Sep/2021",
    end_date: "18/Sep/2021",
    start_price: "300KD",
    status: "In Process",
    biddings: 15,
  },
  {
    post_title: "Nvidia RTX 3060 12GB",
    user_name: "Khalid Rafi",
    start_date: "20/Oct/2021",
    end_date: "28/Oct/2021",
    start_price: "120KD",
    status: "In Process",
    biddings: 7,
  },
  {
    post_title: "Samsung Galaxy Note 10",
    user_name: "Aqib Khan",
    start_date: "12/Dec/2021",
    end_date: "13/Dec/2021",
    start_price: "180KD",
    status: "Clear",
    biddings: 19,
  },
  {
    post_title: "JBL MT2000 2.1",
    user_name: "Amaan Saifi",
    start_date: "13/Dec/2021",
    end_date: "15/Dec/2021",
    start_price: "100KD",
    status: "In Process",
    biddings: 3,
  },
  {
    post_title: "iPhone 13 Mini",
    user_name: "Masruf Ali",
    start_date: "02/Nov/2021",
    end_date: "08/Nov/2021",
    start_price: "210KD",
    status: "Clear",
    biddings: 8,
  },
];
export const COUNTRY_DUMMY_DATA = [
  {
    country_name: "Dubai",
    country_code: "+971",
    currency: "KWD",
  },
  {
    country_name: "Kuwait",
    country_code: "+965",
    currency: "AED",
  },
  {
    country_name: "India",
    country_code: "+91",
    currency: "INR",
  },
  {
    country_name: "Abu Dhabi",
    country_code: "+971",
    currency: "AED",
  },
  {
    country_name: "United Kingdom",
    country_code: "+44",
    currency: "GBP",
  },
  {
    country_name: "France",
    country_code: "+33",
    currency: "FRF",
  },
];
export const GOVERNORATE_DUMMY_DATA = [
  {
    governorate_name: "Farwaniyah",
    country_name: "Kuwait",
  },
  {
    governorate_name: "Fujairah",
    country_name: "Dubai",
  },
  {
    governorate_name: "Arizona",
    country_name: "America",
  },
  {
    governorate_name: "Normandy ",
    country_name: "France",
  },
  {
    governorate_name: "London",
    country_name: "United Kingdom",
  },
  {
    governorate_name: "Chhattisgarh",
    country_name: "India",
  },
];
export const GIFT_DUMMY_DATA = [
  {
    gift_title: "iPhone 12 Pro Max",
    gift_price: "150KD",
    status: "Clear",
  },
  {
    gift_title: "MacBook Pro",
    gift_price: "200KD",
    status: "In Process",
  },
  {
    gift_title: "Nvidia RTX 3060 12GB",
    gift_price: "40KD",
    status: "In Process",
  },
  {
    gift_title: "Samsung Galaxy Note 10",
    gift_price: "170KD",
    status: "Clear",
  },
  {
    gift_title: "JBL MT2000 2.1",
    gift_price: "78KD",
    status: "In Process",
  },
  {
    gift_title: "iPhone 13 Mini",
    gift_price: "96KD",
    status: "Clear",
  },
];
export const GIFT_WINNER_DUMMY_DATA = [
    {
      gift_title: "iPhone 12 Pro Max",
      gift_price: "150KD",
      winner_name:'Adnan Khan',
      status: "Clear",
    },
    {
      gift_title: "MacBook Pro",
      gift_price: "200KD",
      winner_name:'Zubair Ali',
      status: "In Process",
    },
    {
      gift_title: "Nvidia RTX 3060 12GB",
      gift_price: "40KD",
      winner_name:'Saif Khan',
      status: "In Process",
    },
    {
      gift_title: "Samsung Galaxy Note 10",
      gift_price: "170KD",
      winner_name:'Amaan Shami',
      status: "Clear",
    },
    {
      gift_title: "JBL MT2000 2.1",
      gift_price: "78KD",
      winner_name:'Zain Anwar',
      status: "In Process",
    },
    {
      gift_title: "iPhone 13 Mini",
      gift_price: "96KD",
      winner_name:'Ashraf Khan',
      status: "Clear",
    },
  ];

export const USER_BIDS = [
  {
    bid_id:'DFG4541C',
    bid_date:'12/Dec/2021',
    bid_product:'IPhone 13 Mini',
    product_status:'Pending',
    bid_status:'Active',
  },
  {
    bid_id:'DFG4541C',
    bid_date:'12/Dec/2021',
    bid_product:'Xiaomi Note 8',
    product_status:'Approved',
    bid_status:'Close',
  },
  {
    bid_id:'DFG4541C',
    bid_date:'12/Dec/2021',
    bid_product:'Samsung Galaxy s21 Ultra',
    product_status:'Pending',
    bid_status:'Active',
  },
  {
    bid_id:'DFG4541C',
    bid_date:'12/Dec/2021',
    bid_product:'Radeon RX5600x',
    product_status:'Pending',
    bid_status:'Close',
  },
  {
    bid_id:'DFG4541C',
    bid_date:'12/Dec/2021',
    bid_product:'NVidia RTX 3080 Ti',
    product_status:'Approved',
    bid_status:'Active',
  },
]