import React from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPost } from "../../../actions/homeAction";
import Moment from "react-moment";

class CompletePostDetail extends React.Component {
  state = { postList: [] };
  componentWillMount() {
    var data = {
      PostId:this.props.match.params.post_id

    }
    this.props.getPost(data);
  }
  imgLoadError = (event) => {
    event.target.src = "./assets/images/icon.png";
  };
  componentWillReceiveProps(nextProps) {

    this.setState({
      postList: nextProps.home.postList
    })
  }
  renderTable() {
    return this.state?.postList?.map((item, index) => {
      const { id, title, startDate, AddressId, CategoryId, User, displayStatus, endDate,PostMedia, liveType,
        startingPrice, minPostIncrementPrice, negotiablePrice, postBaseType, postStatus, postType,details, views, wantedPrice } = item //destructuring
      return (
        <div class="table-view">
          <table class="table table-bordered">
          <tr>
            <th >Post Image</th><td>{
             <img style={{height:"100px",width:"100px" }}
             src={  
               PostMedia !== undefined && PostMedia !== null && PostMedia !== ""
                 ?PostMedia.map( (PostMedia)=>
                    `https://tenhil.auction/${PostMedia.url} `)
                 : "./assets/images/tenhil.png" 
             }
             
             alt=""
             className="img-40"
             onError={this.imgLoadError}
           />
           }</td>
           </tr>
          <tr><th >Post Id</th><td>{id}</td></tr>
          <tr key={id}>
            <th> Post Title</th><td>{title}</td>
          </tr>
          <tr>
            <th>StartDate</th><td> <Moment format="DD/MM/YYYY">{startDate}</Moment></td>
          </tr>
          <tr>
            <th> endDate</th> <td> <Moment format="DD/MM/YYYY">{endDate}</Moment></td>
          </tr>
          <tr>
            <th> User </th> <td>Email :{User.email}<br></br>Firstname :{User.firstName}<br></br> LastName :{User.lastName} <br></br>MobileNumber:{User.mobileNumber}</td>

          </tr>
          <tr>
            <th> Starting Price</th> <td> {startingPrice} </td>
          </tr>
          <tr>
            <th>    views</th> <td> {views} </td>
          </tr>
          <tr>
            <th>Wanted Price</th><td>{wantedPrice}</td>
          </tr>
          <tr><th>Details </th> <td> {details}</td></tr>
          {/* <tr>
            <th> AddressId </th> <td> {AddressId}  </td>
          </tr> */}
        <tr>
            <th> displayStatus</th> <td> {displayStatus} </td>
          </tr>
          <tr>
            <th>  liveType</th> <td> {liveType} </td>
          </tr>
          {/* <tr>
            <th>  minPostIncrementPrice</th> <td> {minPostIncrementPrice} </td>
          </tr> */}
          <tr>
            <th> Negotiable Price </th> <td> {negotiablePrice} </td>
          </tr>
          <tr>
            <th> Post Base Type</th> <td> {postBaseType} </td>
          </tr>
          <tr>
            <th>  Post Type</th> <td> {postType} </td>
          </tr>
          <tr>
            <th> post Status </th> <td> {postStatus} </td>
          </tr>
          </table>

        </div>
      )
    })

  }
  render() {
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Completed Post Details</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card-size">
                    <div className="card-block">
                      <div className="dt-responsive table-responsive">
                            {this.renderTable()}                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CompletePostDetail.propsTypes = {
  getPost: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,

};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getPost })(CompletePostDetail);
