import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import $ from "jquery";
import Constant from "../../Constant";
import ReactQuill from "react-quill";
import Loader from "../../Loader";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addPOST, getPost, GiftAdd ,updateGIFT,updatePOST, MonthlyGiftList, UpdateVariant, addVariants, deleteVariant} from "../../actions/homeAction";
import axios from 'axios';
import {getAttributes } from '../../actions/attributuesAction'


export class MonthlyGiftAdd extends Component {
    state = {
        PostId: "",
        startDate: "",
        endDate: "",
        duration: "",
        name: "",
        title:'',
        title_ar:'',
        about:'',
        about_ar:'',
        bids:null,
        isHomePagePost:false,
        price:null,
        quantity:null,
        type:'gift',
        variants:[],
        imageArray: [],
        imageData: [],
        attValues:[],
        attributesStore:[],
        maxNumberOfAttribute:0,
        postId:'',
        postList:[],
        Edit_POST:null,
        imageData2: [],
        monthlyGift:[]
      };

      componentDidMount(){

        // if(this.props.attributes_list == undefined || 
        //   this.props.attributes_list == null || 
        //    this.props.attributes_list == 0){
        //       this.props.getAttributes()
        //  }
          var data ={
            isAdmin:true
          }
          this.props.MonthlyGiftList(data)
         this.setState({postId:this.props.match.params.postId})


       
      }
 
      componentWillReceiveProps(nextProps){
        let arr=[]
        nextProps.attributes_list.forEach(attribute=>{
            arr.push(attribute.AttributeOptions)
        })
        // console.log("ddd",nextProps);
        var posts = nextProps.home.postList
        this.setState({ postList: posts});

        var Gifts = nextProps.home.monthlyGift
        var GiftID = this.props.match.params.giftId
        if(Gifts){
          var singleGift = Gifts?.filter(data=>data.id===GiftID)[0]
          this.getSIngleGift(singleGift)
        }
      }

      getSIngleGift = (single) => {
        console.log("getSIngleGift", single);

        var gift = single
        var starD = new Date(gift?.startDate)
        const lpad2 = (number) => String(number).padStart(2, "0")
        console.log("SINGLE", starD);
        this.setState({
          PostId: gift?.Post?.id,
          startDate: starD.getFullYear() + '-' + lpad2(starD.getMonth() + 1) + '-' + lpad2(starD.getDate()),
          endDate: "",
          duration: gift?.duration,
          name: gift?.name,
          title:gift?.Post?.title,
          title_ar:gift?.Post?.title_ar,
          about:gift?.Post?.about,
          about_ar:gift?.Post?.about_ar,
          price:gift?.Post?.price,
          quantity:gift?.Post?.quantity,
        })

        
        this.setState({imageData2: gift?.Post?.Media})
      }

      ImagesToUpload = (e) => {
        let imageData = this.state.imageData;
        let imageArray = this.state.imageArray;
    
        for (let i = 0; i < e.target.files.length; i++) {
          imageData.push(URL.createObjectURL(e.target.files[i]));
          imageArray.push(e.target.files[i]);
        }
        this.setState({
          imageData,
          imageArray,
        });
      };

      deleteImage = (index) => {
        let imageData = this.state.imageData;
        let imageArray = this.state.imageArray;
            imageArray.splice(index, 1);
            imageData.splice(index, 1);
        this.setState({
          imageData,
          imageArray,
        });
      };

      deleteImage2 = (index) => {
        let imageData2 = this.state.imageData2;
        // let imageArray = this.state.imageArray;
            // imageArray.splice(index, 1);
            imageData2.splice(index, 1);
        this.setState({
          imageData2,
          // imageArray,
        });
      };

      onSubmitImages = async (e) => {
        e.preventDefault()
        let media=[]
        let media2=[]
    
        if(this.state.imageArray.length > 0){ 
          
        const formData = new FormData();
        this.state.imageArray.forEach((file, i) => {
          formData.append('media', file);
        });
        
        await axios
          .post(Constant.getAPI() + 'media/add',  formData, {
            headers: {
              Authorization: `${localStorage.getItem("caviar_ad_auth")}`,
            },
          })
          .then((res) => {
            if (res?.data?.data ) {
             var Data = res?.data?.data.map(data=>data.id)
                Data = new Set(Data)
                media = [...Data]
                // console.log("IMG", Data);
            if(this?.state?.PostId){
              var Data2 = this.state.imageData2
                  Data2 = Data2.map(data2=>data2.id)
                  Data2 = new Set(Data2)
                  media2 = [...Data2]
            }else{}
    
            } else {
    
            }
         })
        }
        this.onSubmitData(media,media2)
    
      }

     
     
      onHandleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
      };

      // VariantHandleChange = (e, index, i) => {
      //   e.preventDefault()
      //   const {name,value} = e.target
      //   let variant = this.state.variants
      //   if(i==undefined){
      //       variant[index][name]=parseInt(value)
      //       this.setState({variants:variant})
      //   }else{
      //       variant[index].attributes[i][name] = value
      //       this.setState({variants:variant})
      //   }
      
      //   console.log('VariantHandleChange',i);
      // }

      // onAddVariant = () => {
      //   let variants = this.state.variants
      //   var variant = {price: null, quantity: null, attributes: [{ attributeId: '', attributeOptionId: '', }], }
      //   variants.push(variant)
      //   this.setState({ variants })
      // }
      
      // removeVariant = (index) => {
      //   console.log('removeVariant',index);
      //   let variants = this.state.variants
      //   variants.splice(index, 1)
      //   this.setState({ variants })
      // }
      
      // onAddAttribute = (variantsIndex) => {
      //   let temp = this.state.variants
      //   let variants = temp[variantsIndex]
      //   variants.attributes.push({ attributeId: null, attributeOptionId: null })
      //   this.setState({ variants: temp })
      // }
      
      
      // removeAttri = (INDEX, index) => {
      //   let variants = this.state.variants
      //   variants[index].attributes.splice(INDEX, 1)
        
      //   this.setState({ variants })
      // }
      
      // onVriantUpdate = (variant) => {
      //   this.props.UpdateVariant(variant)
      // }
      
      // onVariantAdd = (variant) => {
      //   var Data = {variants:[variant], postId:this.props.match.params.postId}
      //   this.props.addVariants(Data)
      // }
      
      

      onSubmitData =  (media,media2) =>{

          let MEDIA = media.concat(media2)
    
          let MEDIA2 = []
          if(this.state.imageData2!==[] && this.state.imageData2!==undefined && this.state.imageData2!==null){
            let Data = this.state.imageData2
            var SavedMedia = Data.map(data2=>data2.id)
                SavedMedia = new Set(SavedMedia)
            MEDIA2 = [...SavedMedia]
          }

        var StartDate = new Date(this.state.startDate)  
        var EndDate = new Date(this.state.endDate)  

        var Time = new Date(this.state.startDate).getTime();

        if(this.props.match.params.giftId){
          var data = {
            postId: this?.state?.PostId,
            type:"gift",
            title:this?.state?.title,
            timer:65000,
            title_ar:this?.state?.title_ar,
            postBaseType: this?.state?.duration,
            about:this?.state?.about,
            about_ar:this?.state?.about_ar,
            // bids:parseInt(this?.state?.bids),
            media: media.length>0?MEDIA:MEDIA2,
            price:parseInt(this?.state?.price),
            quantity:parseInt(this?.state?.quantity),
          }

          this.props.updatePOST(data)

          var Data2 ={
            giftId:this?.props?.match?.params?.giftId,
            name: this?.state?.name,
            duration:this?.state?.duration,
            startDate:Time
          }

          this.props.updateGIFT(Data2)


        }else{          
            var dataPost = {
              type:"gift",
              title:this.state.title,
              timer:65000,
              title_ar:this.state.title_ar,
              postBaseType: this.state.duration,
              about:this.state.about,
              about_ar:this.state.about_ar,
              // bids:parseInt(this.state.bids),
              price:parseInt(this.state.price),
              quantity:parseInt(this.state.quantity),
              media: media,
              isHomePagePost:false 
            }
    
            axios
            .post(Constant.getAPI() + `post/add` , dataPost , {
              headers: {
                Authorization: `${localStorage.getItem("caviar_ad_auth")}`,
              },
            }).then((res)=> {
              console.log("res", res.data);
              // if(res.data.status){
                this.setState({PostId: res?.data?.data?.id , 
                })
    
                var data = {
                  PostId: res?.data?.data?.id,
                  startDate: StartDate.getTime(),
                  endDate: EndDate.getTime(),
                  duration: this.state.duration,
                  name: this.state.name,
                }
        
                this.props.GiftAdd(data)
    
              window.location.href = '#/gifts'
              // }
            })
            
          
        }
      }

       
    
          
    render() {
      console.log('Post',this.state.imageData2);
        return (
          <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-header">
                <div className="row align-items-end">
                  <div className="col-lg-8">
                    <div className="page-header-title">
                      <div className="d-inline">
                        <h4>Gift Add</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="page-header-breadcrumb">
                      <ul className="breadcrumb-title">
                        <li className="breadcrumb-item">
                          <Link to="/">
                            <i className="feather icon-home"></i>{" "}
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/post/add">Gift Add</Link>
                        </li>
                        <li className="breadcrumb-item active">Gift Master</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-block">
                        <form onSubmit={this.onSubmitImages} className="row">
                          <div className="col-md-12 UPLOAD_Container">
                          <div className="UPLOADED_FILES">
                            {this.state.imageData.length>0?this.state.imageData.map((image,index)=>(
                              <div>
                                <div style={{background :`url(${image}) no-repeat center/cover`}}>
                                  <div className="REMOVE" onClick={()=>this.deleteImage(index)}>
                                    Remove
                                  </div>
                                </div>
                            </div>
                            )):(<p>No Images Yet !</p>)}

                            {this?.state?.imageData2?.length>0?this?.state?.imageData2.map((image2,index2)=>(
                              <div>
                                <div style={{background :`url(${Constant.getAPI()}${image2.url}) no-repeat center/cover`}}>
                                  <div className="REMOVE" onClick={()=>this.deleteImage2(index2)}>
                                    Remove
                                  </div>
                                </div>
                            </div>
                            )):null}
                            
                          </div>
                          <div className="UPLOAD_BTN">
                            <label htmlFor="imageInput">
                              <img src="/assets/gallery.svg" alt="" />
                              <span>Select Images</span>
                            </label>
                            <input 
                              type="file" 
                              id='imageInput' 
                              multiple 
                              accept="image/png, image/gif, image/jpeg" 
                              style={{display:"none"}}
                              onChange={this.ImagesToUpload}/>
                          </div>
                          </div>
                          <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Title</label>
                              <div className="col-sm-8">
                                  <input
                                      type="text"
                                      className="form-control"
                                      name="title"
                                      id="title"
                                      placeholder="Title"
                                      value={this.state.title}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Title Arabic</label>
                              <div className="col-sm-8">
                                  <input
                                      type="text"
                                      className="form-control"
                                      name="title_ar"
                                      id="title_ar"
                                      placeholder="Title Arabic"
                                      value={this.state.title_ar}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Price</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="price"
                                      id="price"
                                      placeholder="Price"
                                      value={this.state.price}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
            
                        <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Quantity</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="quantity"
                                      id="quantity"
                                      placeholder="Quantity"
                                      value={this.state.quantity}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Description (En)</label>
                              <div className="col-sm-8">
                                  <textarea
                                      type="text"
                                      className="form-control"
                                      name="about"
                                      id="about"
                                      rows='4'
                                      placeholder="Products Description"
                                      value={this.state.about}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='about_ar' className="col-sm-4 col-form-label FONT_WEIGHT2">Description (Ar)</label>
                              <div className="col-sm-8">
                                  <textarea
                                      type="text"
                                      className="form-control"
                                      name="about_ar"
                                      id="about_ar"
                                      rows='4'
                                      placeholder="Products Description"
                                      value={this.state.about_ar}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group row">
                              <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT2">Bids</label>
                              <div className="col-sm-8">
                                  <input
                                      type="number"
                                      className="form-control"
                                      name="bids"
                                      id="bids"
                                      placeholder="Bids"
                                      value={this.state.bids}
                                      onChange={this.onHandleChange}
                                  />
                              </div>
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                               <div className="form-group row">
                                   <label htmlFor='valueType' className="col-sm-4 col-form-label FONT_WEIGHT2">Homepage Product</label>
                                   <div className="col-sm-8">
                                       <select 
                                           name="isHomePagePost" 
                                           className="form-control" 
                                           onChange={this.onHandleChange}
                                           value={this.state.isHomePagePost}
                                       >
                                           <option value={true} name="text">Yes</option>
                                           <option value={false} name="color">No</option>
                                       </select>
                                   </div>
                               </div>
                        </div> */}
                        {/* <div className="col-md-12">
                            <span onClick={() => this.onAddVariant()}
                                className="btn btn-sm btn-inverse waves-effect waves-light f-left d-inline-block md-trigger"
                            >
                                <i className="icofont icofont-plus m-r-5"></i>Add Variant{" "}
                            </span>
                        </div> */}
                        {/* <div className="col-md-12 row">
                            {
                                this.state.variants &&
                                    this.state.variants.length > 0 ?
                                    this.state.variants.map((variant, index) => {
                                        return <div className="VARIANT_OPTIONS row" key={index}>

                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT">Variant Price</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="price"
                                                            id="price"
                                                            placeholder="Variant Price"
                                                              onChange={e=> this.VariantHandleChange(e,index)}
                                                            value={variant.price}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label htmlFor='hexCode' className="col-sm-4 col-form-label FONT_WEIGHT">Variant Quantity</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="quantity"
                                                            id="quantity"
                                                            placeholder="Variant Quantity"
                                                              onChange={e=> this.VariantHandleChange(e,index)}
                                                            value={variant.quantity}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 d-flex justify-content-end">
                                                <span onClick={() => this.onAddAttribute(index)}
                                                    className="btn btn-sm btn-inverse waves-effect waves-light f-left d-inline-block md-trigger"
                                                >
                                                    <i className="icofont icofont-plus m-r-5"></i>Add Attribute{" "}
                                                </span>
                                            </div>
                                            <div className="col-md-12 row">
                                                {variant.attributes.map((attribute, i) => {
                                                    return <div className="ATTRIBUTE_OPTIONS row" key={i}>
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label htmlFor='valueType' className="col-sm-4 col-form-label FONT_WEIGHT">Attribute Name</label>
                                                                <div className="col-sm-8">
                                                                    <select
                                                                        name="attributeId"
                                                                        className="form-control"
                                                                        onChange={e=> this.VariantHandleChange(e,index,i)}
                                                                        value={attribute.attributeId}
                                                                    >
                                                                        <option value="" name="select" >- Name -</option>
                                                                        {
                                                                            this.props.attributes_list.map((attribute, key) => {
                                                                                return <option value={attribute.id} id={key}>
                                                                                    {attribute.name}
                                                                                </option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label htmlFor='valueType' className="col-sm-4 col-form-label FONT_WEIGHT">Attribute Option</label>
                                                                <div className="col-sm-8">
                                                                    <select
                                                                        name="attributeOptionId"
                                                                        className="form-control"
                                                                        onChange={e=> this.VariantHandleChange(e,index,i)}
                                                                        value={attribute.attributeOptionId}
                                                                    >
                                                                        <option value="" name="select" >- Option -</option>
                                                                        {this?.props?.attributes_list?.filter(attri=>attri?.id===this?.state?.variants[index]?.attributes[i]?.attributeId)[0]?.AttributeOptions?.map((attriOption,op_idx)=>(
                                                                            <option value={attriOption?.id}>{attriOption?.value}</option>
                                                                        ))}

                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 ATT_REM_BTN2">
                                                            <button onClick={() => this.removeAttri(i, index)}>x</button>
                                                        </div>

                                                    </div>
                                                })}

                                            </div>


                                            <div className="col-md-12 ATT_REM_BTN" style={{ marginTop: '1rem' }}>
                                                {variant?.variantId===undefined && this.props.match.params.postId!==undefined &&<span onClick={() => this.removeVariant(index)} style={{ margin: '0 0.5rem' }}>Remove Variant</span> }
                                                
                                                {variant?.variantId!==undefined && <span onClick={() => this.onVriantDelete(variant)} style={{ margin: '0 0.5rem' ,background:'#dc2f2f' }}>Delete Variant</span>}

                                                {variant?.variantId!==undefined && <span onClick={() => this.onVriantUpdate(variant)} style={{ margin: '0 0.5rem' }}>Update Variant</span>}

                                                {variant?.variantId===undefined && this.props.match.params.postId!==undefined &&<span onClick={()=> this.onVariantAdd(variant)} style={{ margin: '0 0.5rem' }}>Add New Variant</span> }
                                            </div>

                                        </div>
                                    })
                                    : null
                            }

                        </div> */}
                        <div className='col-md-12 d-flex flex-wrap mt-5'>
                          <div className="col-md-6">
                            <div className="form-group row ">
                                <label htmlFor='name' className="col-sm-4 col-form-label FONT_WEIGHT2">Gift Name</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        id="name"
                                        placeholder="Gift Name"
                                        value={this.state.name}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                                 <div className="form-group row">
                                     <label htmlFor='duration' className="col-sm-4 col-form-label FONT_WEIGHT2">Duration</label>
                                     <div className="col-sm-8">
                                         <select 
                                             name="duration" 
                                             className="form-control" 
                                             onChange={this.onHandleChange}
                                             value={this.state.duration}
                                         >    <option value=""> - Select Duration - </option>
                                             <option value='weekly' name="text">Weekly</option>
                                             <option value='monthly' name="color">Monthly</option>
                                         </select>
                                     </div>
                                 </div>
                             </div>
                          <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='startDate' className="col-sm-4 col-form-label FONT_WEIGHT2">Start Date</label>
                                <div className="col-sm-8">
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="startDate"
                                        id="startDate"
                                        value={this.state.startDate}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='endDate' className="col-sm-4 col-form-label FONT_WEIGHT2">End Date</label>
                                <div className="col-sm-8">
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="endDate"
                                        id="endDate"
                                        value={this.state.endDate}
                                        onChange={this.onHandleChange}
                                    />
                                </div>
                            </div>
                          </div>
                          </div>
                           <div className="col-md-12 DATA_SAVE">
                              <div>
                                    <button type='submit' className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> Save</button>
                              
                                <Link to={"/gifts"} className="btn btn-outline-dark">
                                  Cancel
                                </Link>
                              </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{
          }
        </div>
        )
    }
}

MonthlyGiftAdd.propsTypes = {
    getPost: PropTypes.func.isRequired,
    MonthlyGiftList: PropTypes.func.isRequired,
    GiftAdd: PropTypes.func.isRequired,
    updateGIFT: PropTypes.func.isRequired,
    addPOST: PropTypes.func.isRequired,
    updatePOST: PropTypes.func.isRequired,
    addVariants: PropTypes.func.isRequired,
    deleteVariant: PropTypes.func.isRequired,
    UpdateVariant: PropTypes.func.isRequired,
    // getAttributes: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
  };
  
  var mapStateToProps = (state) => ({
    home: state.home,
    attributes_list:state.home.attributes_list
  });
  
  export default connect(mapStateToProps, {getPost, GiftAdd,updateGIFT ,MonthlyGiftList, addPOST, getAttributes, updatePOST,addVariants,deleteVariant,UpdateVariant })(MonthlyGiftAdd);