import React from "react";
import $ from "jquery";
import "react-toggle/style.css"; // for ES6 modules
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCelebrity } from "../../actions/homeAction";
import {Link} from "react-router-dom"

class CelebrityList extends React.Component {
  state = {
    language_data: [],
  };
  componentWillMount() {
    this.props.getCelebrity();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ celebrity: nextProps.home.celebrity });
    console.log(nextProps.home.celebrity)
  }

  render() {
    const columns = [
      {
        name: "MediumId",
        label: "Celebrity image",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (PostMedia  , tableMeta) => {
            return (
              <div>
                {console.log(PostMedia)}
                { 
                  <img
                    src={  
                      PostMedia !== undefined && PostMedia !== null && PostMedia !== ""
                        ?PostMedia.map( (PostMedia)=>
                           `https://tenhil.auction/${PostMedia.url} `)
                        : "./assets/images/tenhil.png" 
                    }
                    
                    alt=""
                    className="img-40"
                    onError={this.imgLoadError}
                  />
                }
              </div>
            );
            
          },
        },
      },
      {
        name: "firstName",
        label: "FirstName",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "lastName",
        label: "LastName",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "mobileNumber",
        label: "Mobile Number",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "userType",
        label: "User Type",
        options: {
          filter: true,
          sort: true,
        },
      },
      // {
      //   name: " ",
      //   label: "Schedule",
      //   options: {
      //     filter: true,
      //     sort: true,
      //   },
      // },
      {
        name: " ",
        label: "Bids",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: " ",
        label: "Schedule",
        options: {
          filter: true,
          sort: true,
          customBodyRender: () => {
            return (
              <div>
                
                <Link
                  to={"/calender"}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                >
                  <i className="f-20 icofont icofont-ui-calendar text-custom"></i>
                </Link>
               
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Customers Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Celebrity List</h4>
                    </div>
                  </div>
                  <Link
                    to="/celebrity/add"
                    className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Celebrity{" "}
                  </Link>
                  {/* :
                  } */}
                  <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link>
                 </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"Celebrity List"}
                        className="table-responsive"
                        data={this.state.celebrity}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CelebrityList.propsTypes = {
  getCelebrity: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getCelebrity })(CelebrityList);
