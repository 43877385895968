import React from "react";
import { Link } from "react-router-dom";
import Constant from "../../Constant";
import Swal from "sweetalert2";
import MUIDataTable from "mui-datatables";
import axios from 'axios'
import {getAttributes ,getSingleAttribute} from '../../actions/attributuesAction'
import { connect } from "react-redux";

class AttributeList extends React.Component {
  state = {
    attribute_list: [],
  };
  deleteAttribute = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this !",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        var that = this;
        var data = {
          attributeId:id
        }
        fetch(Constant.getAPI() + "/attributes/delete", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tenhil_ad_auth")}`,
          },
          body:JSON.stringify(data)
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            if (json.status === true) {
              Swal.fire("Deleted!", "Tyre Category deleted.", "success");
              that.getAttributeList();
            } else {
              Swal.fire({
                title: "Something went wrong. Try again after some Time.!",
                icon: "error",
                text: "",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };


  componentDidMount() {
    if(this.props.attributes_list == undefined || 
        this.props.attributes_list == null || 
         this.props.attributes_list == 0){
    this.props.getAttributes({languageCode:"en"})
  }}
  render() {
    const columns = [
      {
        name: "name",
        label: "Attribute Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      // {
      //   name: "name_ar",
      //   label: "Attribute Name : Arabic",
      //   options: {
      //     filter: true,
      //     sort: true,
      //   },
      // },
      // {
      //   name: "attributeType",
      //   label: "Attribute Type",
      //   options: {
      //     filter: true,
      //     sort: true,
      //   },
      // },
      {
        name: "AttributeOptions",
        label: "Attribute Value",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (AttributeOptions, tableMeta) => {
            return (
              <div>
                {AttributeOptions !== null &&
                AttributeOptions !== [] &&
                AttributeOptions !== undefined &&
                AttributeOptions.length > 0 ? (
                  <ol>
                    {AttributeOptions.map((Options) => (
                      <li key={Options.id}>{Options.value}</li>
                    ))}
                  </ol>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },
      },
      {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
                <Link
                  to={"/attribute-add/" + id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                >
                  <i className="f-20 icofont icofont-ui-edit text-custom"></i>
                </Link>
             
              </div>
            );
          },
        },
      },
      
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Attribute Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Attribute List</h4>
                    </div>
                  </div>
                </div>
                <Link
                    to="/attribute-add"
                    className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Attribute{" "}
                  </Link>
                  <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          title={"Attribute List"}
                          className="table-responsive"
                          data={this.props.attributes_list}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
   attributes_list:state.home.attributes_list
  };
};

export default connect (mapStateToProps,{
  getAttributes,
  getSingleAttribute
})(AttributeList)
