import {
  GET_AREA,
  GET_BANNER,
  GET_CATEGORY,
  GET_CELEBRITY,
  GET_COUNTRY,
  GET_GOVERNORATE,
  GET_USERS,
  GET_POST,
  GET_LANGUAGE_LIST,
  GET_TIME_SLOT,
  GET_SCHEDULE,
  GET_SUB_CATEGORY,
  GET_PACKAGE,
  GET_DATE_BLOCK,
  GET_BIDSLIST,
  GET_PAYMENT_TYPE,
  GET_PURCHASE_LIST,
  GET_ADDRESS_LIST,
  GET_TERMS,
  GET_ATTRIBUTES,
  GET_ATTRIBUTES_ONE,
  GET_MONTHLY_GIFTS,
  GET_MONTHLY_GIFT_WINNERS,
  GET_ABOUT_US,
  GET_TNC_DATA,
  GET_CONTACT_US,
  GET_ALL_COUPONS,
  GET_AUCTION_WINNER_LIST,
  GET_DASHBOARD
} from "../actions/types";

var initialState = {
  attributes_list:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case GET_CELEBRITY:
      return {
        ...state,
        celebrity: action.payload,
      };
    case GET_MONTHLY_GIFTS:
      return {
        ...state,
        monthlyGift: action.payload,
      };
    case GET_ALL_COUPONS:
      return {
        ...state,
        allCoupons: action.payload,
      };
    case GET_ABOUT_US:
      return {
        ...state,
        aboutUs: action.payload,
      };
    case GET_TNC_DATA:
      return {
        ...state,
        termsAndConditions: action.payload,
      };
    case GET_CONTACT_US:
      return {
        ...state,
        contactUs: action.payload,
      };
    case GET_MONTHLY_GIFT_WINNERS:
      return {
        ...state,
        monthlyGiftWinners: action.payload,
      };
    case GET_SCHEDULE:
      return {
        ...state,
        schedule:action.payload,
      };
    case GET_CATEGORY:
      return {
        ...state,
        categoryList: action.payload,
      };
      case GET_SUB_CATEGORY:
        return {
          ...state,
          subCategoryList: action.payload,
        };
    case GET_BANNER:
      return {
        ...state,
        bannerList: action.payload,
      };
    case GET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case GET_GOVERNORATE:
      return {
        ...state,
        governorate: action.payload,
      };
    case GET_AREA:
      return {
        ...state,
        area: action.payload,
      };
    case GET_POST:
      return {
        ...state,
        postList: action.payload,
      };
      case GET_TIME_SLOT:
        return {
          ...state,
          timeslotList: action.payload,
        };
      
      case GET_LANGUAGE_LIST:
      return {
        ...state,
        languageList: action.payload,
      };
      case GET_PACKAGE:
      return {
        ...state,
        package: action.payload,
      };
      case GET_DATE_BLOCK:
      return {
        ...state,
        dateblockList:action.payload,
      };
      case GET_BIDSLIST:
        return {
          ...state,
          bidsList:action.payload,
        };
        case GET_PAYMENT_TYPE:
          return {
            ...state,
            payment_type:action.payload,
          };
          case GET_PURCHASE_LIST:
          return {
            ...state,
            orderList:action.payload,
          };
          case GET_TERMS:
            return {
              ...state,
              terms:action.payload,
            };
          case GET_ADDRESS_LIST:
          return {
            ...state,
            addressList:action.payload,
          };
          case GET_ATTRIBUTES:
          return{
            ...state,
            attributes_list:action.payload
          }
          case GET_ATTRIBUTES_ONE:
            return{
              ...state,
              attribute_values_one:action.payload
          }
          case GET_AUCTION_WINNER_LIST:
            return{
              ...state,
              AuctionWinnerList:action.payload
            }
            case GET_DASHBOARD:
            return {
              ...state,
              dashboard:action.payload,
            };
    default:
      return state;
  }
}
